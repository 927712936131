import React, { useState, useEffect } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Table, Form, Button, Card, Container, Row, Col, Modal } from 'react-bootstrap';
import JSZip from 'jszip';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import $ from 'jquery';
import DataTable from 'datatables.net-dt';
import {getFromAPI,postToAPI,putToAPI,deleteFromAPI,showAlert} from '../Utils/utils.js'; // Import your utils
import { useNavigate, Navigate } from "react-router-dom";

// Functional component for ProjectStatusList
function ProjectStatusList() {
  // Define colors for project status
  const Color = [
    { value: 'Primary', label: 'Primary' },
    { value: 'Success', label: 'Success' },
    { value: 'Info', label: 'Info' },
    { value: 'Warning', label: 'Warning' },
    { value: 'Danger', label: 'Danger' },
    { value: 'Dark', label: 'Dark' },
    { value: 'Secondary', label: 'Secondary' },
    { value: 'Light', label: 'Light' },
    { value: 'Purple', label: 'Purple' },
    { value: 'Turquoise', label: 'Turquoise' },
    { value: 'Magenta', label: 'Magenta' },
    { value: 'Temp', label: 'Temp' },
  ];

  const staticData = [
    { 
      project_status_name: "Bug Fixing Phase ",project_status_description: "60%",select_color: "Danger",
    },
    { 
      project_status_name: "Invoice Generated",project_status_description: "85%",select_color: "Purple",
    },
    { 
      project_status_name: "Waiting for Payment",project_status_description: "90%",select_color: "Dark",
    },
    { 
      project_status_name: "Project Completed",project_status_description: "100%",select_color: "Success",
    },
    { 
      project_status_name: "Delivered To Customer",project_status_description: "80%",select_color: "Teal",
    },
    { 
      project_status_name: "Designing Phase",project_status_description: "10%",select_color: "Light",
    },
    { 
      project_status_name: "Development Phase",project_status_description: "45%",select_color: "Warning",
    },
    { 
      project_status_name: "In UAT Phase",project_status_description: "70%",select_color: "Orange",
    },
    { 
      project_status_name: "On Hold",project_status_description: "-",select_color: "Red",
    },
    { 
      project_status_name: "Project Not Assigned to Member",project_status_description: "15%",select_color: "Secondary",
    },
    { 
      project_status_name: "Testing Phase",project_status_description: "50%",select_color: "Cyan",
    },
    { 
      project_status_name: "UI Development Phase",project_status_description: "30%",select_color: "Primary",
    },
    { 
      project_status_name: "Waiting for Customer Response",project_status_description: "75%",select_color: "Pink",
    }
  ];

  // Initialize state variables
  const initialData = [];
  const [ProjectStatusListData, SetProjectStatusListData] = useState(initialData);
  const navigate = useNavigate();

  // Fetch data from API on component mount
  useEffect(() => {

    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    if (User_Id === null) {
      navigate('/');
    }

    async function fetchData() {
      try {
        const response = await getFromAPI('projectstatus/');
        SetProjectStatusListData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  // Input data field
  const [inputData, setInputData] = useState({
    project_status_name: '',
    project_status_description: '',
    select_color: '',
  });

  const [EditedData] = useState({
    ProjectStatusID: '',
  });

  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Project Status | PMRS App'
    document.title = 'Project Status | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);

  // Initialize DataTable buttons with JSZip
  DataTable.Buttons.jszip(JSZip);
   // Set up DataTable when ProjectStatusListData changes
   useEffect(() => {
    if (ProjectStatusListData.length > 0 && !$.fn.dataTable.isDataTable('.maindatatable')) {
      $('.maindatatable').DataTable({
        dom: 'flrtip',
        language: {
          search: '',
          searchPlaceholder: 'Search Project Status...',
          paginate: {
            previous: '«',
            next: '»',
          },
        },
      });
    }
  }, [ProjectStatusListData]);
  
  // Modal state and functions
  const [showModalThree, setShowModalThree] = useState(false);
  // Modal state and functions
  const handleCloseModalThree = () => {
    setShowModalThree(false);
    // Reset EditedData.ProjectStatusID when closing the modal
    EditedData.ProjectStatusID = ''; 
  };

  const handleShowModalThree = () => setShowModalThree(true);
  const handleClose = () => setShowModalThree(false);

  // Function to handle adding a new project status
  const onAdding = async (e) => {
    try {
        // Prepare data for API request
        const data = {
          project_status_name: inputData.project_status_name,
          project_status_description: inputData.project_status_description,
          select_color: inputData.select_color,
        };

        // Check for null values in input data
        if (Object.values(data).some(value => value === null || value === '')) {
          showAlert('Please fill in all fields.'); // Show alert for null values
          return;
        }
        // Make POST request to API
        const response = await postToAPI('projectstatus/', data);

        if (response.status) {
          showAlert('Successfully added the project status.','success');
          SetProjectStatusListData(response.data);
        } else {
          showAlert('Some Error Occurred...');
        }
      } catch (error) {
        console.error('Error adding project status:', error);
      }
      // Reset edited data ID
    EditedData.ProjectStatusID = '';
  };

  // Function to update modal content with project status data
  function updateModalContent(data) {
    document.getElementById('project_status_name').value = data['project_status_name'] || '';
    document.getElementById('project_status_description').value = data['project_status_description'] || '';
    document.getElementById('select_color').value = data['select_color'] || '';   
  }

   // Event handler for input changes
  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  // Function to handle deleting a project status
  const handleDelete = async (ITEM_ID) => {
    try {
      // Show confirmation dialog and wait for user's choice
      showAlert("Are you sure you want to delete this entry?", 'confirm', async (confirmDelete) => {
        // If user confirms, proceed with deletion
        if (confirmDelete) {
          const response = await deleteFromAPI(`projectstatus/${ITEM_ID}/`);
          if (response.message === 'Item deleted successfully') {
            showAlert('Entry deleted successfully.', 'success');
            SetProjectStatusListData(response.data);
          } else {
            // Handle other cases if needed
          }
        }
      });
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };
  // Function to handle editing a project status
  const OnEditProject = async (itemId) => {
    try {
      const response = await getFromAPI(`projectstatus/${itemId}/`);
      const data = JSON.parse(response.data);
      // Fetch project status data by ID
      if (response.status) {
        EditedData.ProjectStatusID = itemId;
        updateModalContent(data[0].fields);
        // Set the inputData state with the fetched data
        setInputData({
          project_status_name: data[0].fields.project_status_name,
          project_status_description: data[0].fields.project_status_description,
          select_color: data[0].fields.select_color
        });
        // Open the modal
        handleShowModalThree();
      } else {
        alert('Some Error Occurred...');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  // Function to handle both add and edit actions
  const handleBothActions = () => {
    // Check for null values in input data
    if (Object.values(inputData).some(value => value === null || value === '')) {
      showAlert('Please fill in all fields.');  // Show alert for null values
      return;
    }
    // Check if editing or adding
    if (EditedData.ProjectStatusID === '') {
      onAdding();                               // If adding, call add function
    } else {
      updateDataInDatabase(inputData);           // If editing, call update function
      showAlert('Successfully added the project status.','success');
    }
    handleClose();                                  // Close modal
  };
  // Function to update data in the database
  function updateDataInDatabase(itemDetails) {
    const updateUrl = `projectstatus/${EditedData.ProjectStatusID}/`;

    const requestBody = {
      project_status_name: itemDetails.project_status_name || null,
      project_status_description: itemDetails.project_status_description || null,
      select_color: itemDetails.select_color || null,
    };
    // Make PUT request to API
    putToAPI(updateUrl, requestBody)
      .then((response) => {
        if (response.message === 'Item updated successfully') {
          SetProjectStatusListData(response.data);
        } else {
          console.error('Unexpected response:', response.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    // Reset edited data ID
    EditedData.ProjectStatusID = '';
  };
  // Event handler for color selection
  const handleSelection = (e) => {
    const selectedColor = e.target.value;
    setInputData({ ...inputData, select_color: selectedColor }); 
  };
  // Function to handle edit actions and show modal
  const HandleEditActions = (id) => {
    OnEditProject(id);
    handleShowModalThree();
  };

  return (
    <div className="page-wrapper">
      <div className="page-content">
        <PageTitle breadcrumbLast="Project Status" />
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <div className="d-lg-flex justify-content-between align-items-center mb-3">
                    <Card.Title className='mb-lg-0'>Projects Status</Card.Title>
                    {/* For now, project status data needs to be static as the calculation of project progress is done based on it. so temporarily commenting edit,delete and add button until the task module is supported. */}
                    {/* <div className="d-md-flex">
                      <Button variant="soft-info mb-1 mb-md-0" className="me-2" onClick={handleShowModalThree}>
                        <i className="bi bi-plus-circle me-2"></i>Add Project Status
                      </Button>
                    </div> */}
                  </div>
                  {/* End of card title */}
                  <Table
                    responsive
                    bordered
                    className="mb-0 table-nowrap w-100 maindatatable">
                    <thead className="table-light">
                      <tr>
                        <th>Status Name</th>
                        <th>Description</th>
                        <th>Color</th>
                        {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                      {staticData.map((data, i) => (
                        <tr key={i}>
                          
                          <td>{data.project_status_name}</td>
                          <td>{data.project_status_description}</td>
                          <td>{data.select_color}</td>
                          {/* <td>
                            <div className="d-flex">
                              <Button
                                variant="soft-primary table-btn me-2" 
                                onClick={() => HandleEditActions(data.id)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </Button>
                              <Button
                               variant="soft-danger table-btn"
                               onClick={() => handleDelete(data.id)}                              
                              >
                                <i className="bi bi-trash3"></i>
                              </Button>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>  
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
      <Modal centered show={showModalThree} onHide={handleCloseModalThree} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title> Add Project Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Form>
                  <Col md={12}>
                    <Form.Group
                      className="custom-fr-group">
                      <Form.Label>Project Status Name </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter project status name..."
                        required
                        name="project_status_name"
                        id="project_status_name"
                        onChange={handleChange} 
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      className="custom-fr-group">
                      <Form.Label>Project Status Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Status description..."
                        as="textarea"
                        rows={4}
                        name="project_status_description"
                        id="project_status_description" 
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group
                      className="custom-form-input">
                      <Form.Label>Select Color</Form.Label>
                      <Form.Select name="select_color" id="select_color" onChange={handleSelection}>
                        <option val=''>Select Color</option>
                        <option>Primary</option>
                        <option>Success</option>
                        <option>Info</option>
                        <option>Warning</option>
                        <option>Danger</option>
                        <option>Dark</option>
                        <option>Secondary</option>
                        <option>Light</option>
                        <option>Purple</option>
                        <option>Turquoise</option>
                        <option>Magenta</option>
                        <option>Temp</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="primary" onClick={handleBothActions}>
            Ok
          </Button>
          <Button variant="secondary" onClick={handleCloseModalThree}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default ProjectStatusList;   // Export the ProjectStatusList component