import React, { useState, useEffect } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Card, Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useDateFormat } from "../../context/DateFormatContext.js";
import 'react-datepicker/dist/react-datepicker.css';
//Data-table
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";

import LeaveApprovalData from './LeaveApprovalData.json'

export default function LeaveApproval() {

    DataTable.Buttons.jszip(JSZip);
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable(".maindatatable")) {
            $(".maindatatable").DataTable({
                dom: "flrtip",
                language: {
                    search: "",
                    searchPlaceholder: "Search Leave approval...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
        }
    }, []);

    const [showModal, setShowModal] = useState(false);
    const { selectedFormat } = useDateFormat();

    const [totalLeave, setTotalLeave] = useState(0);
    const [pendingLeave, setPendingLeave] = useState(0);
    const [availableLeave, setAvailableLeave] = useState(0);

    // Function to calculate leave statistics
    const calculateLeaveStatistics = () => {
        // Perform calculations based on your business logic
        // For example, you might fetch data from an API or calculate from local data
        const totalLeaveCount = LeaveApprovalData.length;
        const pendingLeaveCount = LeaveApprovalData.filter(leave => leave.leave_status === 'Pending').length;
        const availableLeaveCount = totalLeaveCount - pendingLeaveCount;

        // Update state with calculated values
        setTotalLeave(totalLeaveCount);
        setPendingLeave(pendingLeaveCount);
        setAvailableLeave(availableLeaveCount);
    };

    const handleShowModal = () => setShowModal(true);
    const [EditedleaveID, setEditedleaveID] = useState('');
    const [Employee, setEmployee] = useState([]);

    const [suggestedEmails, setSuggestedEmails] = useState([]); // State for suggested email IDs

    const [inputData, setInputData] = useState({
        leave_employee_name: '',
        leave_type: '',
        leave_start_date: '',
        leave_end_date: '',
        leave_status: '',
        Approving_Authority: '',
        levae_checkbox: false,
    });

    // Importing JSON data
    const employeesData = LeaveApprovalData;

    // Mapping over the data to create options for the dropdown list and suggestions for the team email input field
    const employeeOptions = employeesData.map(employee => ({
        value: employee.employeeName,
        label: employee.employeeName,
    }));

    const emailSuggestions = employeesData.map(employee => employee.email);

    // Function to format the date based on the selected format
    const formatDate = (dateString) => {
        // If the dateString is empty or null, return an empty string
        if (!dateString) return '';

        // Create a new Date object with the parts in 'MM-DD-YYYY' format
        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.error(`Invalid date: ${dateString}`);
            return ''; // Return an empty string if the date is invalid
        }

        // Get the date components
        const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
        const year = date.getFullYear();

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

        // Custom formats based on the selected format
        switch (selectedFormat) {
            case 'YYYY-MM-DD':
                // Use custom format for 'YYYY-MM-DD'
                return year + "-" + month + "-" + day;
            case 'DD-MM-YYYY':
                // Use custom format for 'DD-MM-YYYY'
                return day + "-" + month + "-" + year;
            case 'MM-DD-YYYY':
                // Use custom format for 'MM-DD-YYYY'
                return month + "-" + day + "-" + year;
            // Add more cases for additional formats as needed
            default:
                // Default to the standard format
                return date.toLocaleDateString('en-GB', options);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setInputData({
            leave_employee_name: '',
            leave_type: '',
            leave_start_date: '',
            leave_end_date: '',
            leave_Approving_Authority: '',
        })
        setShowModal(false);
        setEditedleaveID(''); // Reset editedTeamID
    };

    // Handle both Add and Update actions
    const handleBothActions = () => {
        handleCloseModal();
    };

    // For dropdown list  // Event handler for dropdown list
    const handleSelection = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }

    // Function to handle change in team email input field
    const handleTeamEmailChange = (e) => {
        const { value } = e.target;
        setInputData({ ...inputData, teamEmail: value });

        // Filtering suggestion based on the input value
        const filteredEmails = suggestedEmails.filter(email => email.includes(value));
        setSuggestedEmails(filteredEmails);
    };

    // Function to set team email ID when suggestion clicked
    const setTeamEmail = (email) => {
        setInputData({ ...inputData, teamEmail: email });
        setSuggestedEmails([]); // Clear suggestions
    };

    //function for the handling change date format    
    const handleChangeDate = (date, fieldName) => {
        setInputData((prevData) => ({
            ...prevData,
            [fieldName]: date, // Update the state with the selected date
        }));
    };

    useEffect(() => {
        calculateLeaveStatistics();
    }, []);

    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Leave Approval' />

                    <Container>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Label>Employee Name</Form.Label>
                                                        {/* <Form.Control type="text" placeholder="Enter the Employee Name..." name="leave_employee_name" id="leave_employee_name"></Form.Control> */}
                                                        <Form.Control as="select" onChange={handleSelection} name="leave_employee_name" disabled readOnly>
                                                            <option>Enter Employee Name</option>
                                                            {employeeOptions.map(option => (
                                                                <option key={option.value} value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-3" controlId="formGroupEmail" disabled readOnly>
                                                        <Form.Label>Team Email ID</Form.Label>
                                                        {/* <Form.Control type="email" placeholder="Enter the email..." onChange={handleTeamEmailChange} /> */}
                                                        {/* Suggestions for team email input field */}
                                                        <Form.Control type="email" placeholder="Enter the email..." onChange={handleTeamEmailChange} list="emailsList" disabled readOnly />
                                                        <datalist id="emailsList">
                                                            {emailSuggestions.map((email, index) => (
                                                                <option key={index} value={email} />
                                                            ))}
                                                        </datalist>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="custom-fr-group" disabled readOnly>
                                                        <Form.Label>Leave Type</Form.Label>
                                                        {/* <Form.Control type="leaveType" placeholder="Enter the Leave Type..."/> */}
                                                        <Form.Control as="select" name="leave_type" onChange={handleSelection} disabled readOnly>
                                                            <option>Enter Leave Type</option>
                                                            {LeaveApprovalData.map(item => (
                                                                <option key={item.id} value={item.leaveType}>{item.leaveType}</option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Label>From</Form.Label>
                                                        <DatePicker
                                                            selected={inputData.leave_start_date ? new Date(inputData.leave_start_date) : null}
                                                            onChange={(date) => handleChangeDate(date, "leave_start_date")}
                                                            placeholderText="Select start date"
                                                            dateFormat={formatDate(inputData.leave_start_date)}
                                                            disabled readOnly
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="custom-fr-group">
                                                        <Form.Label>To</Form.Label>
                                                        <DatePicker
                                                            selected={inputData.leave_end_date ? new Date(inputData.leave_end_date) : null}
                                                            onChange={(date) => handleChangeDate(date, "leave_end_date")}
                                                            placeholderText="Select end date"
                                                            dateFormat={formatDate(inputData.leave_end_date)}
                                                            disabled readOnly
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3" controlId="reasonForm.Controlreason ">
                                                        <Form.Label>Reason For Leave</Form.Label>
                                                        <Form.Control as="textarea" rows={2} disabled readOnly />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-3" controlId="reasonForm.Controlreason ">
                                                        <Form.Label>Comment</Form.Label>
                                                        <Form.Control as="textarea" rows={2} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <Button variant="primary" onClick={handleBothActions} >
                                                        Approved
                                                    </Button>
                                                    <Button variant="secondary" onClick={handleBothActions}>
                                                        Rejected
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}> {/* Side panel */}
                                <Card>
                                    <Card.Body>
                                        <h5>Leave Statistics</h5>
                                        <ul className="mb-0">
                                            <li>Total Leave: {totalLeave}</li>
                                            <li>Pending Leave: {pendingLeave}</li>
                                            <li>Available Leave: {availableLeave}</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}