import React, { useState, useEffect } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Container, Form, Card, Row, Col, Button, Table, Modal } from 'react-bootstrap';

//Data-table
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";

import LeaveListTableData from "./LeaveData.json"


export default function Leave() {

    // This function runs when the component mounts
    useEffect (() =>{
        // It sets the document title to 'Leave | PMRS App'
        document.title = 'Leave | PMRS App';
        // This return statement defines a cleanup function
        return ()=> {
            // This function runs when the component unmounts
            document.title = 'PMRS App';
            // It resets the document title back to 'PMRS App'
        };
      },[]);

    const [leaveData] = useState([]);
    const [leaveListData] = useState(LeaveListTableData);

    const handleShowModal = () => setShowModal(true);

    const [inputData, setInputData] = useState({
        employee_name: '',
        leave_type: '',
        start_date: '',
        end_date: '',
        days_hours_taken: '',
        leave_status: '',
    });

    DataTable.Buttons.jszip(JSZip);
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable(".maindatatable")) {
            $(".maindatatable").DataTable({
                dom: "flrtip",
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
        }
    }, []);

    const handleChange = (e) => {
        setInputData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    // State for showing or hiding modal
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Handle both Add and Update actions
    const handleBothActions = () => {
        if (
            inputData.employee_name === '' ||
            inputData.project_select === '' ||
            inputData.start_date === '' ||
            inputData.end_date === '' ||
            inputData.leave_type === '' ||
            inputData.days_hours_taken === '' ||
            inputData.leave_status === ''
        )
            handleCloseModal();
    };

    // For dropdown list  // Event handler for dropdown list
    const handleSelection = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }

    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Leave' />

                    <Container >
                        <Row>
                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100 text-bg-success bg-opacity-50'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-center'>
                                            <Card.Title>Annual Leave</Card.Title>
                                        </div>
                                        <div className='ms-3'>
                                            <h3 className='mb-0 me-4 fs-4 d-flex justify-content-center'>5/31</h3>
                                            <p className='mb-0 me-4 d-flex justify-content-center'>Available</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            
                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100  text-bg-info bg-opacity-50'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-center'>
                                            <Card.Title>Casual Leave</Card.Title>
                                        </div>
                                        <div className='ms-3'>
                                            <h3 className='mb-0 me-4 fs-4 d-flex justify-content-center'>3/12</h3>
                                            <p className='mb-0 me-4 d-flex justify-content-center'>Available</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100  text-bg-warning bg-opacity-50'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-center'>
                                            <Card.Title>Paid Leave</Card.Title>
                                        </div>
                                        <div className='ms-3'>
                                            <h3 className='mb-0 me-4 fs-4 d-flex justify-content-center'>0/12</h3>
                                            <p className='mb-0 me-4 d-flex justify-content-center'>Available</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className='mb-4 ' md={6} xl={3}>
                                <Card className='mb-0 h-100  text-bg-danger bg-opacity-50'>
                                    <Card.Body>
                                        <div className='d-flex justify-content-center'>
                                            <Card.Title>Sick Leave</Card.Title>
                                        </div>
                                        <div className='ms-3 '>
                                            <h3 className='mb-0 me-3 fs-4 d-flex justify-content-center'>2/5</h3>
                                            <p className='mb-0 me-3 d-flex justify-content-center'>Available</p>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center mb-3">
                                            <Card.Title>Leave Table</Card.Title>
                                            <div>
                                                <Button variant="soft-success ms-lg-3" className="btn-icon" onClick={handleShowModal}>
                                                    <i className="bi bi-plus"></i>
                                                </Button>
                                            </div>
                                        </div>
                                        <Table
                                            responsive
                                            bordered
                                            className='mb-0 table-nowrap w-100 maindatatable'
                                        >
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>Employee Name</th>
                                                    <th>Leave type</th>
                                                    <th>Leave Period </th>
                                                    <th>Days/Hours Taken</th>
                                                    <th>Leave Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {leaveListData.map((data, i) => (
                                                    <tr key={i}>
                                                        <td onclick>{data.employee}</td>
                                                        <td>{data.leave_type}</td>
                                                        <td>{data.leave_period}</td>
                                                        <td>{data.days_hours_taken}</td>
                                                        <td>{data.leave_status}</td>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <Button variant='soft-primary table-btn me-2'>
                                                                    <i className='bi bi-pencil-square'></i>
                                                                </Button>
                                                                <Button variant='soft-danger table-btn'>
                                                                    <i className='bi bi-trash3'></i>
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>

            {/* modal */}
            <Modal centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-lg">
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title>Add Leave</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col>
                                <Form>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Employee Select</Form.Label>
                                                <Form.Select name="employee_name" id="employee_name" onChange={handleChange}>
                                                    <option >Select Employee Name...</option>
                                                    {leaveData.map((item) => (
                                                        <option key={item.employeename}>{item.employeename}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className={`mb-3 ${!inputData.leave_type ? 'has-error' : ''}`}>
                                                <Form.Label>Leave Type<span className="text-danger">*</span></Form.Label>
                                                <Form.Select defaultValue="Select" name="leave_type" id="leave_type" onChange={handleSelection}>
                                                    <option>Select Type</option>
                                                    <option>Earned Leave/Annual Leave</option>
                                                    <option>Casual Leave</option>
                                                    <option>Sick Leave</option>
                                                    <option>Maternity Leave</option>
                                                    <option>Paternity Leave</option>
                                                    <option>Half-day Leave</option>
                                                    <option>Unpaid Leave</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Start Date</Form.Label>
                                                <Form.Control name="start_date" id="start_date" onChange={handleChange} type="date" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group>
                                                <Form.Label>End Date</Form.Label>
                                                <Form.Control type="date" name="end_date" id="end_date" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Days/Hours Taken </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Enter days number..."
                                                    onChange={handleChange}
                                                    required
                                                    name="days_hours_taken"
                                                    id="days_hours_taken"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className={`mb-3 ${!inputData.employee_status ? 'has-error' : ''}`}>
                                                <Form.Label>Leave Status<span className="text-danger">*</span></Form.Label>
                                                <Form.Select defaultValue="Select" name="leave_status" id="leave_status" onChange={handleSelection}>
                                                    <option>Select</option>
                                                    <option>Approved</option>
                                                    <option>Rejected</option>
                                                    <option>Pending</option>
                                                    <option>Cancelled</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleBothActions}>
                        Ok
                    </Button>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
