import './App.css';
import Routes from "./components/routes/Index"
import MenuState from './context/MenuState'
import { DateFormatProvider } from '../src/context/DateFormatContext'; // Import DateFormatProvider from your context file

function App() {
  return (
    <DateFormatProvider> {/* Wrap the DateFormatProvider around your MenuState component */}
      <MenuState>
        <Routes />
      </MenuState>
    </DateFormatProvider>
  );
}

export default App;
