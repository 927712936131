import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Accordion } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { getFromAPI } from "../Utils/utils";
// img
import Logo from "../../images/logo.png";
import LogoSm from "../../images/logo-sm.png";

export default function LeftSideBar() {
    // State to track whether the "Employee" menu is clicked
    const [employeeMenuClicked, setEmployeeMenuClicked] = useState(false);
    const [projectMenuClicked, setProjectMenuClicked] = useState(false);
    const [settingsMenuClicked, setSettingsMenuClicked] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);


    useEffect(() => {
        async function fetchData() {
            try {
                // Fetch employee data
                const employeeData = await getFromAPI("employee/");
                // Check if the logged-in user is admin
                const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
                const loggedInUser = employeeData.find(
                    (employee) => employee.employee_id === loggedInUserId
                );
               if (loggedInUser && loggedInUser.position.toLowerCase() === "admin") {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, []);
    // Function to handle clicking on the "Employee" menu item
    const handleEmployeeMenuClick = () => {
        setEmployeeMenuClicked(true);
        setProjectMenuClicked(false); // Reset projectMenuClicked to false
        setSettingsMenuClicked(false); // Reset settingsMenuClicked to false
    };

    // Function to handle clicking on any menu item
    const handleProjectMenuClick = () => {
        setProjectMenuClicked(true);
        setEmployeeMenuClicked(false); // Reset employeeMenuClicked to false
        setSettingsMenuClicked(false); // Reset settingsMenuClicked to false
    };

    // Function to handle clicking on the "Settings" menu item
    const handleSettingsMenuClick = () => {
        setSettingsMenuClicked(true);
        setEmployeeMenuClicked(false); // Reset employeeMenuClicked to false
        setProjectMenuClicked(false); // Reset projectMenuClicked to false
    };

    // Function to handle clicking on any menu item
    const handleMenuItemClick = () => {
        setEmployeeMenuClicked(false); // Reset employeeMenuClicked to false
        setProjectMenuClicked(false); // Reset projectMenuClicked to false
        setSettingsMenuClicked(false); // Reset settingsMenuClicked to false
    };

    const navigate = useNavigate();

    const handleMilestoneClick = (event) => {
        event.preventDefault();
        navigate('/milestone'); // Replace with your desired URL
    };

    const handleTasksClick = (event) => {
        event.preventDefault();
        navigate('/task'); // Replace with your desired URL
    };


    return (
        <>
            {/* leftbar-sm */}
            <div className="leftbar">
                {/* Logo */}
                <div className="leftbar-logo">
                    <Link to="/dashboard">
                        <img src={Logo} alt="" className="logo-lg" height={38} />
                        <img src={LogoSm} alt="" className="logo-sm" height={38} />
                    </Link>
                </div>

                {/* Nav menu list */}
                <SimpleBar className="leftbar-menu-sroll">
                    <div className="menu-list">
                        <h6 className="text-muted left-menu-title text-uppercase fs-13 mb-1">
                        </h6>
                        <Navbar>
                            <Container className="p-0">
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="nav-pills flex-column custom-leftbar w-100">
                                        <Accordion>
                                            <NavLink
                                                to="/dashboard"
                                                className="nav-link"
                                                onClick={handleMenuItemClick}
                                            >
                                                <i className="bi bi-house left-nav-icon"></i>
                                                <span className="menu-link-text">Dashboard</span>
                                            </NavLink>
                                            {/* <NavLink
                                                to="/calendar"
                                                className="nav-link"
                                                onClick={handleMenuItemClick}
                                            >
                                                <i className="bi bi-calendar4-event left-nav-icon"></i>
                                                <span className="menu-link-text">Calendar</span>
                                            </NavLink> */}
                                            <Accordion.Item eventKey="0" className="nav-accordion" >
                                                <Accordion.Header>
                                                    <i className="bi bi-files-alt left-nav-icon"></i>
                                                    <span className="menu-link-text">Projects</span>
                                                </Accordion.Header>
                                                <Accordion.Body >
                                                    {isAdmin && ( // Render "Admin Projects" only if isAdmin is true
                                                        <NavLink to="/admin_project" className="nav-link">
                                                            <i className="bi bi-person-check left-nav-icon"></i>
                                                            <span className="menu-link-text">Admin Projects</span>
                                                        </NavLink>
                                                    )}
                                                    
                                                    {!isAdmin && (
                                                    <NavLink to="/projects" className="nav-link">
                                                        <i className="bi bi-folder2 left-nav-icon"></i>
                                                        <span className="menu-link-text">Projects</span>
                                                    </NavLink>)}
                                                    
                                                    <NavLink to="/milestone" className="nav-link">
                                                        <i className="bi bi-flag left-nav-icon"></i>
                                                        <span className="menu-link-text">Milestone</span>
                                                    </NavLink>
                                                    {/* temporry cpmmented doen Task model */}
                                                    {/* <NavLink to="/task" className="nav-link">
                                                        <i className="bi bi-list-task left-nav-icon"></i>
                                                        <span className="menu-link-text">Task</span>
                                                    </NavLink> */}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/* <Accordion.Item eventKey="0">
                                                <Accordion.Body>
                                                    <NavLink to="/task" className="nav-link">
                                                        <i className="bi bi-flag left-nav-icon"></i>
                                                        <span className="menu-link-text">Task</span>
                                                    </NavLink>
                                                </Accordion.Body>
                                            </Accordion.Item> */}

                                            <NavLink
                                                to="/locker"
                                                className="nav-link"
                                                onClick={handleMenuItemClick}
                                            >
                                                <i className="bi bi-lock left-nav-icon"></i>
                                                <span className="menu-link-text">Locker</span>
                                            </NavLink>
                                            <NavLink
                                                to="/performance"
                                                className="nav-link"
                                                onClick={handleMenuItemClick}
                                            >
                                                <i className="bi bi-box-arrow-up-right left-nav-icon"></i>
                                                <span className="menu-link-text">Performance</span>
                                            </NavLink>
                                              
                                            <Accordion.Item eventKey="1" className="nav-accordion">
                                                {isAdmin &&(
                                                <Accordion.Header>
                                                    <i className="bi bi-people left-nav-icon"></i>
                                                    <span className="menu-link-text text-muted">Employee</span>
                                                </Accordion.Header> )}
                                                <Accordion.Body>
                                                {isAdmin && (
                                                    <NavLink to="/employee" className="nav-link">
                                                        <i className="bi bi-person-circle left-nav-icon"></i>
                                                        <span className="menu-link-text">Employee</span>
                                                    </NavLink>)}
                                                    { isAdmin && (
                                                    <NavLink to="/role" className="nav-link">
                                                        <i className="bi bi-person-lines-fill left-nav-icon"></i>
                                                        <span className="menu-link-text">Role</span>
                                                    </NavLink> )}
                                                    { isAdmin && (
                                                    <NavLink to="/department" className="nav-link">
                                                        <i className="bi bi-diagram-3 left-nav-icon"></i>
                                                        <span className="menu-link-text">Department</span>
                                                    </NavLink> )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/* <Accordion.Item eventKey="0">
                                                <Accordion.Body>
                                                    <NavLink to="/department" className="nav-link">
                                                        <i className="bi bi-diagram-3 left-nav-icon"></i>
                                                        <span className="menu-link-text">Department</span>
                                                    </NavLink>
                                                </Accordion.Body>
                                            </Accordion.Item> */}

                                            {/* <NavLink
                                                to="/leave"
                                                className="nav-link"
                                                onClick={handleMenuItemClick}
                                            >
                                                <i className="bi bi-calendar2-check left-nav-icon"></i>
                                                <span className="menu-link-text">Leave</span>
                                            </NavLink>

                                            <NavLink
                                                to="/leaveApproval"
                                                className="nav-link"
                                                onClick={handleMenuItemClick}
                                            >
                                                <i className="bi bi-calendar2-check-fill left-nav-icon"></i>
                                                <span className="menu-link-text">Leave Approval</span>
                                            </NavLink> */}

                                            <Accordion.Item eventKey="2" className="nav-accordion">
                                                <Accordion.Header>
                                                    <i className="bi bi-gear left-nav-icon"></i>
                                                    <span className="menu-link-text text-muted">Settings</span>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {isAdmin && (
                                                    <NavLink to="/projectStatus" className="nav-link">
                                                        <i className="bi bi-clipboard2-check left-nav-icon"></i>
                                                        <span className="menu-link-text">Project Status</span>
                                                    </NavLink> )}
                                                    {isAdmin && (
                                                    <NavLink to="/milestoneStatus" className="nav-link">
                                                        <i className="bi bi-flag left-nav-icon"></i>
                                                        <span className="menu-link-text">Milestone Status</span>
                                                    </NavLink> )}
                                                    {isAdmin && (
                                                    <NavLink to="/team" className="nav-link">
                                                        <i className="bi bi-people left-nav-icon"></i>
                                                        <span className="menu-link-text">Team Member</span>
                                                    </NavLink> )}
                                                    <NavLink to="/employee_profile" className="nav-link">
                                                        <i className="bi bi-person-circle left-nav-icon"></i>
                                                        <span className="menu-link-text">Employee Profile</span>
                                                    </NavLink>
                                                    <NavLink to="/termscondition" className="nav-link">
                                                        <i className="bi bi-shield-check left-nav-icon"></i>
                                                        <span className="menu-link-text">Terms & Conditions</span>
                                                    </NavLink>
                                                    {isAdmin && (
                                                    <NavLink to="/setting" className="nav-link">
                                                        <i className="bi bi-gear-wide-connected left-nav-icon"></i>
                                                        <span className="menu-link-text">General Setting</span>
                                                    </NavLink> )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {/* <Accordion.Item eventKey="0">
                                                <Accordion.Body>
                                                    <NavLink to="/milestoneStatus" className="nav-link">
                                                        <i className="bi bi-flag left-nav-icon"></i>
                                                        <span className="menu-link-text">Milestone Status</span>
                                                    </NavLink>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Body>
                                                    <NavLink to="/team" className="nav-link">
                                                        <i className="bi bi-people left-nav-icon"></i>
                                                        <span className="menu-link-text">Team Member</span>
                                                    </NavLink>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Body>
                                                    <NavLink to="/" className="nav-link">
                                                        <i className="bi bi-shield-check left-nav-icon"></i>
                                                        <span className="menu-link-text">Terms & Conditions</span>
                                                    </NavLink>
                                                </Accordion.Body>
                                            </Accordion.Item> */}
                                        </Accordion>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </div>
                </SimpleBar>
            </div>
        </>
    );
}