import React, { useEffect, useState, useCallback, useRef} from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import {
  Table,
  Button,
  Container,
  Card,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import user from "../../images/user1.png";
import Select from "react-select";
import axios from "axios";
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert, sendNotification} from '../Utils/utils.js'; // Import utility functions

import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from 'react-datepicker';
import { useDateFormat } from "../../context/DateFormatContext.js";
import { useNavigate, Navigate } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';

export default function EmployeeList() {

  const [latestEmployeeId, setLatestEmployeeId] = useState(1);
  const navigate = useNavigate();

  // Define fetchLatestEmployeeId outside of useEffect

  useEffect(() => {
    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    const isPositionAdmin = localStorage.getItem('Position');
    if (User_Id === null || isPositionAdmin !== 'Admin') {
      navigate('/');
    }
    fetchLatestEmployeeId();
  }, []);
  
  const fetchLatestEmployeeId = async () => {
    try {
      const data = await getFromAPI('employee/');
      const employeeIds = data.map(employee => employee.employee_id);
      const maxEmployeeId = Math.max(...employeeIds);
      const nextEmployeeId = maxEmployeeId + 1;
      setLatestEmployeeId(nextEmployeeId);
    } catch (error) {
      console.error('Error fetching latest employee ID:', error);
    }
  };
        
    // This function runs when the component mounts
    useEffect (() =>{
      // It sets the document title to 'Employee List | PMRS App'
      document.title = 'Employee List | PMRS App';
      // This return statement defines a cleanup function
      return ()=> {
        // This function runs when the component unmounts
        document.title = 'PMRS App';
        // It resets the document title back to 'PMRS App'
      };
    },[]);

    // State for image and file upload
    const [selectedImage, setSelectedImage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedPDF, setSelectedPDF] = useState(null);
    const tableRef = useRef(null); // Ref for DataTable instance

    // Function to handle photo upload
    const handlePhotoUpload = async (e) => {
      try {
          const file = e.target.files[0];
          if (!file) {
              toast.error('No file selected.');
              return;
          }
          if (!file.type.includes('image')) {
              toast.error('Invalid file type. Please upload an image file.');
              return;
          }

          const base64Image = await readFileAsDataURL(file);
          setSelectedImage(base64Image);
          // Set InputData for image
          setInputData(prevData => ({   
            ...prevData,
            profile_picture: base64Image
          }));

          localStorage.setItem('selectedImage', base64Image);

      } catch (error) {
          toast.error('Error handling photo upload:', error);
      }
    };

    // Function to handle file upload
    const handleFileUpload = async (e) => {
      try {
          const file = e.target.files[0];
          if (!file) {
              toast.error('No file selected.');
              return;
          }
          if (!file.type.includes('pdf')) {
              toast.error('Invalid file type. Please upload a PDF file.');
              return;
          }

          // Do whatever you need to do with the PDF file here
          // For example, you can read it as a data URL or upload it to a server
          
          const base64PDF = await readFileAsDataURL(file);

          // Do something with the base64 encoded PDF data
          // For example, save it in state or local storage
          
          setSelectedPDF(base64PDF);
          setInputData(prevData => ({
              ...prevData,
              files: base64PDF
          }));

          localStorage.setItem('selectedPDF', base64PDF);
      } catch (error) {
          toast.error('Error handling file upload:', error);
      }
    };

    const readFileAsDataURL = (file) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
      });
    };

  // Map for Getting and Setting DropDown value   // State for handling dropdown values
  const [data, setData] = useState([]);
  const [getCountry] = useState();
  const [getState, setState] = useState([]);
  const [selectedState] = useState();
  const [cities, setCities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRole] = useState([]);
  const [editedTeamID, setEditedTeamID] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { selectedFormat } = useDateFormat();

  // Fetch data from an API to populate dropdown options
  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // Code to Add Country values from JS
  const country = [...new Set(data.map((item) => item.country))];
  country.sort();
  const handleCountry = (e) => {
    let states = data.filter((state) => state.country === e.target.value);
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
    handleSelection(e);
  };
  // Function to handle state selection
  const handleState = (e) => {
    let cities = data.filter((city) => city.subcountry === e.target.value);
    cities = [...new Set(cities.map((item) => item.name))];
    setCities(cities);
    handleSelection(e);
  };

  // Datatable
  // Fetching All Employee Data
  const initialData = [];
  const [employeeListData, SetEmployeeListData] = useState(initialData);

  // Fetch all Department Data
  useEffect(() => {
    async function fetchData() {
      try {                               
        const departmentData = await getFromAPI('department/');
        setDepartments(departmentData);

        const roleData = await getFromAPI('role/');
        setRole(roleData);
     
        const employeeData = await getFromAPI('employee/');
        SetEmployeeListData(employeeData);
      } catch (error) {
        toast.error('Error fetching department data:', error);
      }
    }
    fetchData();
  }, []);

 const handleCloseModal = () =>{
  setInputData({
    profile_picture:"",
    first_name: "",
    last_name: "",
    employee_id: "",
    position: "",
    email: "",
    password: "",
    contact_no: "",
    alternate_contact_no: "",
    department: "",
    role: "",
    join_date: "",
    dob: "",
    gender: "",
    marital_status: "",
    employee_status: "",
    employee_type: "",
    reporting_to: "",
    // reporting_to_management: "",
    experience: "",
    country: "",
    state: "",
    city: "",
    hobbies: "",
})
    setShowModal(false);
    setEditedTeamID(''); // Reset editedTeamID)
    setErrors('');
    }  

    const handleShowModal = () => {
      setShowModal(true);

    };
  // Map for inputing Data from Modal   // State to input data from Modal
  const [inputData, setInputData] = useState({
    profile_picture:"",
    first_name: "",
    last_name: "",
    employee_id: "",
    position: "",
    email: "",
    password: "",
    contact_no: "",
    alternate_contact_no: "",
    department: "",
    role: "",
    join_date: "",
    dob: "",
    gender: "",
    marital_status: "",
    employee_status: "",
    employee_type: "",
    reporting_to: "",
    // reporting_to_management: "",
    experience: "",
    country: "",
    state: "",
    city: "",
    hobbies: "",
    files: "",
  });

  // State to store edited data
  useEffect(() => {
    // Initialize or update DataTable when milestoneData changes
    if (tableRef.current) {
      // If DataTable has already been initialized, destroy it first
      tableRef.current.clear().destroy();
    }
  
    // Initialize DataTable
    const dataTableInstance = $('.maindatatable').DataTable({
      dom: 'flrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search...',
        paginate: {
          previous: '«',
          next: '»',
        },
      },
      data: employeeListData, // Add milestoneData directly to the DataTable
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            const profilePicture = row.profile_picture ? row.profile_picture : user; // Provide the URL of the default image
            return `
              <div class="text-dark">
              <img className="auth-img rounded-circle" src=${profilePicture} alt="default" style="width: 30px; height: 30px; object-fit: cover; border-radius: 50%;" />
              <span className="ms-2">
                 ${row.first_name} ${row.last_name}
                </span>
              </div>
            `;
          }
        },
        { data: 'position' },
        { data: 'department' },
        { 
          data: null,
          render: function (data, type, row) {
            return formatDate(row.join_date);
          }
        },
        { data: 'contact_no' },
        { data: 'email' },
        {
          data: null,
          render: function (data, type, row) {
            return `
              <div class="d-flex">
                <button class="btn btn-primary table-btn me-2" data-id='${JSON.stringify(row)}' data-action="edit"><i class="bi bi-pencil-square"></i></button>
                <button class="btn btn-danger table-btn" data-id='${JSON.stringify(row)}' data-action="delete"><i class="bi bi-trash3"></i></button>
              </div>
            `;
          }
        }
      ]
    });
  
    // Store the DataTable instance in the ref
    tableRef.current = dataTableInstance;
    // Attach event listeners to handle edit and delete actions
    $(document.body).on('click', '.btn.table-btn', function() {
      const action = $(this).data('action');
      const rowData = $(this).data('id');
      if (action === 'edit') {
        HandleEditActions(rowData);
      } else if (action === 'delete') {
        handleDelete(rowData.employee_id);
      }
    });
  }, [employeeListData]); // Only re-run the effect if employeeListData changes
   
  // Function to add values to the database
  const handleAddEmployee = async () => {
    try {      
      const nextEmployeeId = latestEmployeeId;
      const formatteddob = convertToYYYYMMDD(inputData.dob);
      const formattedjoindate = convertToYYYYMMDD(inputData.join_date);  
      const data = await postToAPI(`employee/`, {
        profile_picture: selectedImage,
        employee_id: nextEmployeeId,
        first_name: inputData.first_name,
        last_name: inputData.last_name,
        position: inputData.position,
        contact_no: inputData.contact_no,
        alternate_contact_no: inputData.alternate_contact_no,
        email: inputData.email,
        password: inputData.password,
        department: inputData.department,
        role: inputData.role,
        reporting_to:inputData.reporting_to,
        // reporting_to_management: inputData.reporting_to_management,
        join_date: formattedjoindate,
        employee_status: inputData.employee_status,
        employee_type: inputData.employee_type,
        experience: inputData.experience,
        city: inputData.city,
        state: inputData.state,
        country: inputData.country,
        dob: formatteddob,
        gender: inputData.gender,
        marital_status: inputData.marital_status,
        hobbies: inputData.hobbies,
        files: inputData.files,
      });
      if (data.status) {
        SetEmployeeListData(data.data);
        CreatePerformanceDatabaseForEmployee(nextEmployeeId);
        const creation_time = new Date().toISOString();
        const type = "employee";
        const employeeId =`${nextEmployeeId}`;
        const message = `Welcome ! ${inputData.first_name}`;
        await sendNotification('notification/', type, message, creation_time, employeeId, false);
        handleCloseModal();
      } else {
        toast.error("Some Error Occurred...", "error");
      }
      setEditedTeamID('');
      await fetchLatestEmployeeId();
    } catch (error) {
      toast.error('Error adding employee:', "error");
    }
  };

  // Function to create a performance database for an employee
  async function CreatePerformanceDatabaseForEmployee(employee_ID) {
    try {
      const response = await postToAPI("performance/", {
        employee_id: employee_ID,
        target_no_release: 0,
        target_no_bug_Fixed: 0,
        target_no_todo_done: 0,
        target_no_line_added: 0,
        target_code_quality: 0,
        added_no_release: 0,
        added_no_bug_Fixed: 0,
        added_no_todo_done: 0,
        added_no_line_added: 0,
        added_no_code_quality: 0,
        added_responsibility: 0,
        added_behaviour: 0,
        performance: 0,
      });

      if (response.status) {
          
      } else {
        toast.error("Some Error Occurred...", "error");
      }
  } catch (error) {
      toast.error("Error adding performance data:", "error");
  }
  }

  // Function for deleting the employee from the database 
  async function deletePerformanceDatabaseForEmployee(employeeid) {
    const apiUrl = `performance/employee_id/${employeeid}/`;
    try {
      const data = await deleteFromAPI(apiUrl);
      if (data.message !== "Item deleted successfully") {
        //toast.success("Performance deleted", 'success');
      }
    } catch (error) {
        toast.error('Error deleting Employee', 'error');
    }
  }
  
 // Function for update data in database
 
 const updateDataInDatabase = useCallback(async(itemDetails) =>{
     const updatedUrl = `employee/${editedTeamID}/`;
     const formatteddob = convertToYYYYMMDD(inputData.dob);
      const formattedjoindate = convertToYYYYMMDD(inputData.join_date);

     const requestBody = { 
      profile_picture: itemDetails.profile_picture || null,
      employee_id: itemDetails.employee_id || null,
      first_name: itemDetails.first_name || null,
      last_name: itemDetails.last_name || null,
      position: itemDetails.position || null,
      contact_no: itemDetails.contact_no || null,
      alternate_contact_no: itemDetails.alternate_contact_no || null,
      email: itemDetails.email || null,
      password: itemDetails.password || null,
      department: itemDetails.department || null,
      role: itemDetails.role || null,
      reporting_to: itemDetails.reporting_to || null,
      // reporting_to_management: itemDetails.reporting_to_management || null,
      join_date: formattedjoindate || null,
      employee_status: itemDetails.employee_status || null,
      employee_type: itemDetails.employee_type || null,
      experience: itemDetails.experience || null,
      ...(itemDetails.city && { city: itemDetails.city }),
      ...(itemDetails.state && { state: itemDetails.state }),
      country: itemDetails.country || null,
      dob: formatteddob || null,
      gender: itemDetails.gender || null,
      marital_status: itemDetails.marital_status || null,
      hobbies: itemDetails.hobbies || null,
      files: itemDetails.files || null,
     };
     try
     {
      const data = await putToAPI(updatedUrl , requestBody);
        if (data.message === "Item updated successfully") {
          SetEmployeeListData(data.data)
        } else {
        toast.error('Unexpected response:', data.message);
      }
    } catch (error) {
      // Handle network or other errors
      toast.error('Error updating Project', 'error');
    }
    setEditedTeamID('')
  },[employeeListData, editedTeamID, inputData]);
  
  const UpdatedModalContent = (Modaldata) => {
    setInputData((prevData) => ({
      ...prevData,
      profile_picture: Modaldata.profile_picture,
      first_name: Modaldata.first_name,
      last_name: Modaldata.last_name,
      employee_id: Modaldata.employee_id,
      position: Modaldata.position,
      email: Modaldata.email,
      password: Modaldata.password,
      contact_no: Modaldata.contact_no,
      alternate_contact_no: Modaldata.alternate_contact_no,
      department: Modaldata.department,
      role: Modaldata.role,
      join_date: Modaldata.join_date,
      dob: Modaldata.dob,
      gender: Modaldata.gender,
      marital_status: Modaldata.marital_status,
      employee_status: Modaldata.employee_status,
      employee_type: Modaldata.employee_type,
      reporting_to: Modaldata.reporting_to,
      // reporting_to_management: data.reporting_to_management,
      experience: Modaldata.experience,
      country: Modaldata.country,
      state: Modaldata.state,
      city: Modaldata.city,
      hobbies: Modaldata.hobbies,
    }));
    let states = data.filter((state) => state.country === Modaldata.country);
    let SelectedStates = [...new Set(states.map((item) => item.subcountry))];
    let SelectedCites = states.filter((state) => state.subcountry === Modaldata.state);
    let Cities = [...new Set(SelectedCites.map((item) => item.name))];
    SelectedStates.sort();
    Cities.sort();
    setState(SelectedStates);
    setCities(Cities);
  };

  // Function to format the date based on the selected format
  const formatDate = (dateString) => {
    // If the dateString is empty or null, return an empty string
    if (!dateString) return '';

    // Create a new Date object with the parts in 'MM-DD-YYYY' format
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateString}`);
      return ''; // Return an empty string if the date is invalid
    }

    // Get the date components
    const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
    const year = date.getFullYear();

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

    // Custom formats based on the selected format
    switch (selectedFormat) {
      case 'YYYY-MM-DD':
        // Use custom format for 'YYYY-MM-DD'
        return year + "-" + month + "-" + day;
      case 'DD-MM-YYYY':
        // Use custom format for 'DD-MM-YYYY'
        return day + "-" + month + "-" + year;
      case 'MM-DD-YYYY':
        // Use custom format for 'MM-DD-YYYY'
        return month + "-" + day + "-" + year;
      // Add more cases for additional formats as needed
      default:
        // Default to the standard format
        return date.toLocaleDateString('en-GB', options);
    }
  };

      // Function to convert any date format to yyyy-mm-dd format
      const convertToYYYYMMDD = (dateString) => {
      // Create a new Date object from the input string
      const date = new Date(dateString);
      
      // Check if the input string is a valid date
      if (isNaN(date.getTime())) {
        console.error(`Invalid date: ${dateString}`);
        return ''; // Return an empty string if the date is invalid
      }
      
      // Get the components of the date (year, month, day)
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
      const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if necessary
      
      // Return the date in yyyy-mm-dd format
      return `${year}-${month}-${day}`;
    };

    const handleChangeDate = (date, fieldName) => {
      setInputData((prevData) => ({
        ...prevData,
        [fieldName]: date, // Update the state with the selected date
      }));
    };
  
      const handleChange = (e) => {
      setInputData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

  // For Deleting Data 
  const handleDelete = async (employeeid) => {
    try {
        showAlert("Do you want to delete this employee?", "confirm",(result) => {
        if (result) {
          deleteEmployee(employeeid);
          deletePerformanceDatabaseForEmployee(employeeid);
          fetchLatestEmployeeId();
        }
        });
    } catch (error) {
      toast.error('Error deleting item:', "error");
    }
  };

  // Function for deleting the employee from the database 
  const deleteEmployee = async (employeeid) => {
    const apiUrl = `employee/employee_id/${employeeid}/`;
    try {
      const data = await deleteFromAPI(apiUrl);
      if (data.message === "Item deleted successfully") {
        SetEmployeeListData(data.data);
        toast.success("Employee deleted successfully", "success");
      } else {
        toast.error(data.message , "error");
      }
    } catch (error) {
        toast.error('Error deleting Employee', 'error');
    }
  };

      // For handling handleClose and onadding for the OK button
      const handleBothActions = async () => {

        // Validate each field
        const validationErrors = {};

        // Validation for first and last name
        if (!inputData.first_name || inputData.first_name.trim() === "") {
            validationErrors.first_name = "First name is required";
        }
        if (!inputData.last_name || inputData.last_name.trim() === "") {
            validationErrors.last_name = "Last name is required";
        }

        // Validation for contact and alternate contact numbers
        const phoneNumberPattern = /^\d{10}$/;

        // Validation for contact number
        if (!inputData.contact_no || !phoneNumberPattern.test(inputData.contact_no)) {
            validationErrors.contact_no = "Contact number must be a 10-digit number";
        } else {
            // Check if the entered contact number is already in use by another employee
            const otherEmployeesContactNos = employeeListData
                .filter(employee => employee.employee_id !== inputData.employee_id) // Exclude the current employee being edited
                .map(employee => employee.contact_no);
                
            if (otherEmployeesContactNos.includes(inputData.contact_no)) {
                validationErrors.contact_no = "Contact no. already in use";
            }
        }
        
        // Validation for alternate contact number if provided
        if (inputData.alternate_contact_no) {
            if (!phoneNumberPattern.test(inputData.alternate_contact_no)) {
                validationErrors.alternate_contact_no = "Alternate contact number must be a 10-digit number";
            } else {
                // Check if the entered alternate contact number is already in use by another employee
                const otherEmployeesAlternateContactNos = employeeListData
                    .filter(employee => employee.employee_id !== inputData.employee_id) // Exclude the current employee being edited
                    .map(employee => employee.alternate_contact_no);
                
                if (otherEmployeesAlternateContactNos.includes(inputData.alternate_contact_no)) {
                    validationErrors.alternate_contact_no = "Alternate contact no. already in use";
                }
            }
        }       

        // Validation for email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Validation for email
        if (!inputData.email || !emailPattern.test(inputData.email)) {
            validationErrors.email = "Invalid email address";
        } else {
            // Check if the entered email is already in use by another employee
            const otherEmployeesEmails = employeeListData
                .filter(employee => employee.employee_id !== inputData.employee_id) // Exclude the current employee being edited
                .map(employee => employee.email);
                
            if (otherEmployeesEmails.includes(inputData.email)) {
                validationErrors.email = "Email ID already in use";
            }
        }
        

        if(inputData.password === 'Select'|| !inputData.password){
          validationErrors.password = "This field is required";
        }

        if(!inputData.join_date){
          validationErrors.join_date = "This field is required";
        }

        if(!inputData.dob){
          validationErrors.dob = "This field is required";
        }

        if(inputData.gender === 'Select'|| !inputData.gender){
          validationErrors.gender = "This field is required";
        }

        if(inputData.department === 'Select Department'|| !inputData.department){
          validationErrors.department = "This field is required";
        }

        if(inputData.role === 'Select Role'|| !inputData.role){
          validationErrors.role = "This field is required";
        }

        if(inputData.reporting_to === 'Select'|| !inputData.reporting_to){
          validationErrors.reporting_to = "This field is required";
        }

        if(inputData.position === 'Select'|| !inputData.position){
          validationErrors.position = "This field is required";
        }

        if(inputData.employee_status === 'Select'|| !inputData.employee_status){
          validationErrors.employee_status = "This field is required";
        }

        if(inputData.employee_type === 'Select'|| !inputData.employee_type){
          validationErrors.employee_type = "This field is required";
        }

        if(inputData.experience === 'Select'|| !inputData.experience){
          validationErrors.experience = "This field is required";
        }

        if(inputData.marital_status === 'Select'|| !inputData.marital_status){
          validationErrors.marital_status = "This field is required";
        }

        const isDuplicate = employeeListData.some((employee) => (employee.employee_id.toString() === inputData.employee_id) && (employee.id !== editedTeamID));
        if (isDuplicate) {
          toast.warn('Project already exists. Please choose a different Project name.', 'error');
          return;
        }

        // Update errors state
        setErrors(validationErrors);

        // If there are validation errors, show alert and do not proceed with editing
        if (Object.keys(validationErrors).length !== 0) {
            toast.error("Please fill in all required fields correctly.", "error");
            return;
        }

        try {
            if (editedTeamID === '') {
              handleAddEmployee()
              toast.success('Employee Added Successfully','success');
              } else {
                updateDataInDatabase(inputData);
                toast.success('Employee Edited Successfully','success');
            }
            handleCloseModal()
        } catch (error) {
            toast.error("Error in handleBothActions.", error);
        }
      };

      const handleDrop = (e) => {
        e.preventDefault();
    }
    
    const handleDragOver = (e) => {
        e.preventDefault();
    }

      // States for conditon which is trigerring the blank entries in the form 
      const [errors, setErrors] = useState({
        profile_picture: "",
        first_name: "",
        last_name: "",
        employee_id: "",
        position: "",
        email: "",
        password: "",
        contact_no: "",
        department: "",
        role: "",
        join_date: "",
        dob: "",
        gender: "",
        marital_status: "",
        employee_status: "",
        employee_type: "",
        reporting_to: "",
        // reporting_to_management: "",
        experience: "",
        country: "",
        state: "",
        city: "",
      });

  // For handling Edit action
  const HandleEditActions = (team) => {
    setEditedTeamID(team.id);
    UpdatedModalContent(team);
    handleShowModal();
  };
  
  // For dropdown list   // Event handler for dropdown list
  const handleSelection = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  return (
    <>
        <div className="page-wrapper">
            <div className="page-content">
                <PageTitle breadcrumbLast="Employee List" />
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <div className="d-md-flex justify-content-between align-items-center mb-3">
                                        <Card.Title className="mb-0">Employee List</Card.Title>
                                        <div>
                                            <Button variant="soft-success ms-lg-3" className="btn-icon" onClick={handleShowModal}>
                                                <i className="bi bi-plus"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    <Table responsive bordered className="mb-0 table-nowrap w-100 maindatatable">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Employee Name</th>
                                                <th>Position</th>
                                                <th>Department</th>
                                                <th>Hire Date</th>
                                                <th>Contact No</th>
                                                <th>Email</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </div>

        {/* modal */}
        <Modal centered
         show={showModal} onHide={handleCloseModal}
         dialogClassName="modal-lg" aria-labelledby="contained-modal-title-vcenter" >
            <Modal.Header closeButton>
                <Modal.Title> {editedTeamID ? 'Edit Employee' : 'Add Employee'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form encType="multipart/form-data">
                    <Row>
                        <Col md={12}>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="position-relative mb-5">
                                    <div className="add-btn rounded-circle avatar avatar-xs bg-primary text-white">
                                        <label htmlFor="photoUpload" style={{ cursor: "pointer" }}>
                                            <i className="bi bi-plus"></i>
                                        </label>
                                        <input type="file" id="photoUpload" style={{ display: "none" }} onChange={(e) => handlePhotoUpload(e)} />
                                    </div>
                                    {inputData.profile_picture ? (
                                        <img src={inputData.profile_picture} className="rounded-circle img-thumbnail bg-white avatar-img-xxl" alt="selected"/>
                                    ) : ( 
                                        <img src={user} className="rounded-circle img-thumbnail bg-white avatar-img-xxl" alt="default"/>
                                    )}
                                </div>
                            </div>
                           
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.first_name ? 'has-error' : ''}`}>
                                            <Form.Label>First Name<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Enter First name... " name="first_name" id="first_name" onChange={handleChange} value={inputData.first_name}/>
                                            {errors.first_name && <p className="text-danger">{errors.first_name}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.last_name ? 'has-error' : ''}`}>
                                            <Form.Label>Last Name<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="text" placeholder="Enter Last name..." name="last_name" id="last_name" onChange={handleChange} value={inputData.last_name}/>
                                            {errors.last_name && <p className="text-danger">{errors.last_name}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.employee_id ? 'has-error' : ''}`}>
                                            <Form.Label>Employee ID<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="number" placeholder="Enter Employee id... " name="employee_id" id="employee_id" onChange={handleChange} value={ editedTeamID ? inputData.employee_id : latestEmployeeId} readOnly />
                                            {errors.employee_id && <p className="text-danger">{errors.employee_id}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.position ? 'has-error' : ''}`}>
                                            <Form.Label>Position<span className="text-danger">*</span></Form.Label>
                                            <Form.Select  name="position" id="position" onChange={handleChange} value={inputData.position}>
                                                <option>Select</option>
                                                <option>Admin</option>
                                                <option>HR</option>
                                                <option>Developer</option>
                                                <option>Team Leader</option>
                                                <option>Project Manager</option>
                                                <option>Tester</option>
                                                <option>Designer</option>
                                                <option>Sales Person</option>
                                            </Form.Select>
                                            {errors.position && <p className="text-danger">{errors.position}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.email ? 'has-error' : ''}`}>
                                            <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="email" placeholder="Enter email..." name="email" id="email" onChange={handleChange} value={inputData.email} />
                                            {errors.email && <p className="text-danger">{errors.email}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.password ? 'has-error' : ''}`}>
                                            <Form.Label>Password<span className="text-danger">*</span></Form.Label>
                                            <div className="input-icon-content">
                                              <i className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"} input-icon`}
                                                onMouseDown={() => setShowPassword(true)}
                                                onMouseUp={() => setShowPassword(false)}
                                                onMouseOut={() => setShowPassword(false)}
                                                onTouchStart={() => setShowPassword(true)}
                                                onTouchEnd={() => setShowPassword(false)}
                                              ></i>
                                            <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" name="password" id="password" onChange={handleChange} value={inputData.password} />
                                            {errors.password && <p className="text-danger">{errors.password}</p>}
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.contact_no ? 'has-error' : ''}`}>
                                            <Form.Label>Contact No<span className="text-danger">*</span></Form.Label>
                                            <Form.Control type="number" placeholder="+01 1234 5678 90" name="contact_no" id="contact_no" onChange={handleChange} value={inputData.contact_no}/>
                                            {errors.contact_no && <p className="text-danger">{errors.contact_no}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.alternate_contact_no ? 'has-error' : ''}`}>
                                            <Form.Label>Alternate Contact No</Form.Label>
                                            <Form.Control type="number" placeholder="+01 1234 5678 90" name="alternate_contact_no" id="alternate_contact_no" onChange={handleChange} value={inputData.alternate_contact_no} />
                                            {errors.alternate_contact_no && <p className="text-danger">{errors.alternate_contact_no}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <h5>Work</h5>

                                <Row>
                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.department ? 'has-error' : ''}`}>
                                            <Form.Label>Department<span className="text-danger">*</span></Form.Label>
                                            <Form.Select  name="department" id="department" onChange={handleSelection} value={inputData.department}>
                                                <option value="">Select Department</option>
                                                {departments.map((items) => (
                                                    <option key={items.department_name}>{items.department_name}</option>
                                                ))}
                                            </Form.Select>
                                            {errors.department && <p className="text-danger">{errors.department}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.role ? 'has-error' : ''}`}>
                                            <Form.Label>Role<span className="text-danger">*</span></Form.Label>
                                            <Form.Select
                                              name="role"
                                              id="role"
                                              value={inputData.role}
                                              onChange={handleSelection}
                                            >
                                              <option value="">Select Role</option>
                                              {roles.map((status) => (
                                                <option key={status.role_name}>
                                                  {status.role_name}
                                                </option>
                                              ))}
                                            </Form.Select>
                                            {errors.role && <p className="text-danger">{errors.role}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.reporting_to ? 'has-error' : ''}`}>
                                            <Form.Label>Reporting To<span className="text-danger">*</span></Form.Label>
                                            <Form.Select  name="reporting_to" id="reporting_to" onChange={handleSelection} value = {inputData.reporting_to}>
                                                <option>Select</option>
                                                <option>Super Admin</option>
                                                {employeeListData.map((items) => (
                                                    <option key={items.id}>{items.first_name} {items.last_name}</option>
                                                ))}
                                            </Form.Select>
                                            {errors.reporting_to && <p className="text-danger">{errors.reporting_to}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.join_date ? 'has-error' : ''}`}>
                                            <Form.Label>Date of Join<span className="text-danger">*</span></Form.Label>
                                            <div className="input-icon-content">
                                            <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('join_date').click()}></i>

                                            </div>
                                            <DatePicker
                                              selected={inputData.join_date? new Date(inputData.join_date) : null}
                                              name="join_date"
                                              id= "join_date"
                                              onChange={(date) => handleChangeDate(date, "join_date")}
                                              placeholderText="Select join date"
                                              dateFormat={formatDate(inputData.join_date)}     
                                              showYearDropdown
                                              scrollableYearDropdown
                                              yearDropdownItemNumber={100}                      
                                            />
                                            {errors.join_date && <p className="text-danger">{errors.join_date}</p>}
                                        </Form.Group>                                        
                                    </Col>

                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.employee_status ? 'has-error' : ''}`}>
                                            <Form.Label>Employee Status<span className="text-danger">*</span></Form.Label>
                                            <Form.Select  name="employee_status" id="employee_status" onChange={handleSelection} value={inputData.employee_status}>
                                                <option>Select</option>
                                                <option>Active</option>
                                                <option>Non-Active</option>
                                            </Form.Select>
                                            {errors.employee_status && <p className="text-danger">{errors.employee_status}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.employee_type ? 'has-error' : ''}`}>
                                            <Form.Label>Employee Type<span className="text-danger">*</span></Form.Label>
                                            <Form.Select  name="employee_type" id="employee_type" onChange={handleSelection} value={inputData.employee_type}>
                                                <option>Select</option>
                                                <option>Permanent</option>
                                                <option>Temporary</option>
                                            </Form.Select>
                                            {errors.employee_type && <p className="text-danger">{errors.employee_type}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    {/* <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.reporting_to_management ? 'has-error' : ''}`}>
                                            <Form.Label>Reporting To Managment<span className="text-danger">*</span></Form.Label>
                                            <Form.Select defaultValue="Select" name="reporting_to_management" id="reporting_to_management" onChange={handleSelection} value={inputData.reporting_to_management}>
                                                <option>Select</option>
                                                <option>Mary Barret</option>
                                                <option>Silvia Buhr</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col> */}
                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.experience ? 'has-error' : ''}`}>
                                            <Form.Label>Experience<span className="text-danger">*</span></Form.Label>
                                            <Form.Select  name="experience" id="experience" onChange={handleSelection} value={inputData.experience}>
                                                <option>Select</option>
                                                <option>Fresher</option>
                                                <option>1-2 year</option>
                                                <option>3-4 year</option>
                                                <option>5+ year</option>
                                            </Form.Select>
                                            {errors.experience && <p className="text-danger">{errors.experience}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <h5>Personal Details</h5>

                                <Row>
                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.country ? 'has-error' : ''}`}>
                                            <Form.Label>Country<span className="text-danger">*</span></Form.Label>
                                            <Form.Select name="country" id="country" onChange={(e) => handleCountry(e)} value={inputData.country}>
                                                <option value="">Select Country</option>
                                                {country.map((items) => (
                                                    <option key={items} value={getCountry}>
                                                        {items}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {errors.country && <p className="text-danger">{errors.country}</p>}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.state ? 'has-error' : ''}`}>
                                            <Form.Label>State<span className="text-danger">*</span></Form.Label>
                                            <Form.Select name="state" id="state" onChange={(e) => handleState(e)} value={inputData.state}>
                                                <option value="">Select State</option>
                                                {getState.map((items) => (
                                                    <option key={items} value={selectedState}>
                                                        {items}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            {errors.state && <p className="text-danger">{errors.state}</p>}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4}>
                                        <Form.Group className={`mb-3 ${!inputData.city ? 'has-error' : ''}`}>
                                            <Form.Label>City<span className="text-danger">*</span></Form.Label>
                                            <Form.Select name="city" id="city" onChange={handleSelection} value={inputData.city}>
                                                <option value="">Select City</option>
                                                {cities.map((items) => (
                                                    <option key={items}>{items}</option>
                                                ))}
                                            </Form.Select>
                                            {errors.city && <p className="text-danger">{errors.city}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.dob ? 'has-error' : ''}`}>
                                            <Form.Label>Date of Birth<span className="text-danger">*</span></Form.Label>
                                            <div className="input-icon-content">
                                            <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('dob').click()}></i>
                                            </div>
                                            <DatePicker
                                              selected={inputData.dob? new Date(inputData.dob) : null}
                                              name="dob"
                                              id= "dob"
                                              onChange={(date) => handleChangeDate(date, "dob")}
                                              placeholderText="Select date of birth"
                                              dateFormat={formatDate(inputData.dob)}     
                                              showYearDropdown
                                              scrollableYearDropdown
                                              yearDropdownItemNumber={100}                       
                                            />
                                            {errors.dob && <p className="text-danger">{errors.dob}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.gender ? 'has-error' : ''}`}>
                                            <Form.Label>Gender<span className="text-danger">*</span></Form.Label>
                                            <Form.Select  name="gender" id="gender" onChange={handleSelection} value={inputData.gender}>
                                                <option>Select</option>
                                                <option>Male</option>
                                                <option>Female</option>
                                                <option>Other</option>
                                            </Form.Select>
                                            {errors.gender && <p className="text-danger">{errors.gender}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className={`mb-3 ${!inputData.marital_status ? 'has-error' : ''}`}>
                                            <Form.Label>Marital Status<span className="text-danger">*</span></Form.Label>
                                            <Form.Select  name="marital_status" id="marital_status" onChange={handleSelection} value={inputData.marital_status}>
                                                <option>Select</option>
                                                <option>Married</option>
                                                <option>Unmarried</option>
                                            </Form.Select>
                                            {errors.marital_status && <p className="text-danger">{errors.marital_status}</p>}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mb-3 custom-form-input">
                                        <Form.Label>Hobbies<span className="text-danger"></span></Form.Label>
                                            <Form.Control type="text" placeholder="Enter hobbies... " name="hobbies" id="hobbies" onChange={handleChange} value={inputData.hobbies}/>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Col>
                                    <label htmlFor="files" className="form-label">
                                        Attached if any
                                    </label>
                                    <input className="form-control" type="file" accept=".pdf" id="files" name="files" onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)} onChange={(e) => handleFileUpload(e)}></input>
                                </Col>
                     
                        </Col>
                    </Row>
                  </form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleBothActions}>
                    Ok
                </Button>
                <Button variant="secondary" onClick = { handleCloseModal}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />

    </>
);
}