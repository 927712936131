import React, { useState, useEffect } from 'react';
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Container, Form, Row, Col, Button, Card, Modal } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import 'react-clock/dist/Clock.css';
import { useDateFormat } from "../../context/DateFormatContext.js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
import {
    sendNotification,
    getFromAPI,
    postToAPI,
    putToAPI,
    deleteFromAPI,
    showAlert,
} from "../Utils/utils.js"; // Import utility functions
import { toast, ToastContainer } from "react-toastify";
import { constant } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export default function Calendar() {

    // Get the current year and month
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const { selectedFormat } = useDateFormat();

    // This function runs when the component mounts
    useEffect(() => {
        // It sets the document title to 'Calendar | PMRS App'
        document.title = 'Calendar | PMRS App';
        // This return statement defines a cleanup function
        return () => {
            // This function runs when the component unmounts
            document.title = 'PMRS App';
            // It resets the document title back to 'PMRS App'
        };
    }, []);

    // Function to format the date based on the selected format
    const formatDate = (dateString) => {
        // If the dateString is empty or null, return an empty string
        if (!dateString) return '';

        // Create a new Date object with the parts in 'MM-DD-YYYY' format
        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.error(`Invalid date: ${dateString}`);
            return ''; // Return an empty string if the date is invalid
        }

        // Get the date components
        const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
        const year = date.getFullYear();

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

        // Custom formats based on the selected format
        switch (selectedFormat) {
            case 'YYYY-MM-DD':
                // Use custom format for 'YYYY-MM-DD'
                return year + "-" + month + "-" + day;
            case 'DD-MM-YYYY':
                // Use custom format for 'DD-MM-YYYY'
                return day + "-" + month + "-" + year;
            case 'MM-DD-YYYY':
                // Use custom format for 'MM-DD-YYYY'
                return month + "-" + day + "-" + year;
            // Add more cases for additional formats as needed
            default:
                // Default to the standard format
                return date.toLocaleDateString('en-GB', options);
        }
    };

    const handleChange = (e) => {
        setInputData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    const [inputData, setInputData] = useState({
        event_name: '',
        start_date: '',
        color: '',
    });

    const handleChangeDate = (date, fieldName) => {
        setInputData((prevData) => ({
            ...prevData,
            [fieldName]: date, // Update the state with the selected date
        }));
    };

    // State for showing or hiding modal
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const [projectData, setProjectData] = useState([]);


    // Funtion for get project details
    useEffect(() => {
        const fetchData = async () => {
            const projectResponse = await getFromAPI('project/')
            const employeeId = localStorage.getItem('employee_id');
            const projectsForUser = [];

            projectResponse.map(project => {
                var bValid = false;
                project.employee_select?.split(',').map((employee_id, index) => {
                    if (employee_id === employeeId) bValid = true;
                                    })
                if (bValid) projectsForUser.push(project);
            })

            setProjectData(projectsForUser)
        }
        fetchData();
    }, []);

    const getColor = (index) => {
        const className = ['soft-purple', 'soft-danger', 'soft-success', 'soft-warning']; // Add more colors as needed
        return className[index % className.length];
    };

    const projectevents = projectData.flatMap((project, index) => {
        const startDate = new Date(project.startdate);
        const endDate = new Date(project.enddate);

        // Set the time to midnight for both start and end dates
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        const formattedArrival = startDate.toISOString(); // Adjust the format as needed
        const formattedDeparture = endDate.toISOString(); // Adjust the format as needed
        return [
           
            {
                title: `Project End: ${project.projectname}`,
                start: formattedDeparture,
                id: `${project.id}-end`,
                className: getColor(index), // Use a function to determine the color
                allDay: true // Set to true to indicate that it's an all-day event
            }
        ];
    });


    const [milestoneData, setMilestoneData] = useState([]);

    // Funtion for get milestone details
    useEffect(() => {
        const fetchMilestondata = async () => {
            const MilestoneResponse = await getFromAPI('milestone/')
            setMilestoneData(MilestoneResponse)

            // Log start, end, smb_arrival, and smb_departure values to the console    
        }
        fetchMilestondata();
    }, []);

    const milestonevents = milestoneData.flatMap((milestone, index) => {
        const startDate = new Date(milestone.start_date);
        const endDate = new Date(milestone.end_date);

        // Set the time to midnight for both start and end dates
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        const formattedArrival = startDate.toISOString(); // Adjust the format as needed
        const formattedDeparture = endDate.toISOString(); // Adjust the format as needed

        return [
           
            {
                title: `Milestone End: ${milestone.milestone_name}`,
                start: formattedDeparture,
                id: `${milestone.id}-end`,
                className: getColor(index), // Use a function to determine the color
                allDay: true // Set to true to indicate that it's an all-day event
            }
        ];
    });


    // Creating function for Add event
    const [Addevent, setAddEvent] = useState([]);
    const [editedData, setEditedData] = useState({
        EventID: '',
    });
    // Funtion for get milestone details
    useEffect(() => {
        const fetchEventdata = async () => {
            const EventResponse = await getFromAPI('calendarEvent/')
            setAddEvent(EventResponse)

        }
        fetchEventdata();
    }, []);

    // Function to fetch updated locker data
    const fetchAddEventData = async () => {
        try {
            const response = await getFromAPI("calendarEvent/");
            setAddEvent(response)
        } catch (error) {
            console.error("Error while fetching the data:", error);
        }
    };

    const handleAdd = async () => {
        const isDuplicate = Addevent.some(
            (Event) => (Event.event_name === inputData.event_name));
        if (isDuplicate) {
            toast.warn("Event Name Already Exist Choose Different Event Name.", 'error');
            return;
        }
        try {
            
            const formattedStartDate = convertToYYYYMMDD(inputData.start_date);
            const response = await postToAPI('calendarEvent/', {
                event_name: inputData.event_name,
                start_date: formattedStartDate,
                color: inputData.color
            });
            if (response.status) {
                // Update state with the newly added event
                setAddEvent([...Addevent, response.data]);
                fetchAddEventData()
                toast.success('Event Added Successfully', 'success')
            }  else {
                if (response.error && response.error.includes('duplicate')) {
                  toast.error('Event entry. Please provide a unique Event name.', 'error');
                } else {
                  toast.error('Some Error Occurred...', 'error');
                }
              }
            setEditedData('')
        } catch (error) {
            // Log any caught errors
            console.error("Error adding event:", error);
            showAlert("Error adding event", "error"); // Show alert to the user
        }
    };

    const handleAction = () => {
        // Check if any required field is empty
        if (!inputData.event_name || inputData.event_name.trim() === "" ||
            !inputData.start_date || !inputData.color) {
            toast.error('All Fields are required!', 'error');
            return;
        }
    
        // If it's an existing event being edited
        if (editedData.EventID !== '') {
            // Update the event data in the database
            updateDataInDatabase(inputData);
            handleCloseModal(); // Close the modal
            return;
        }
    
        // Check for duplicate event names only when adding a new event
        const isDuplicate = Addevent.some(event => event.event_name === inputData.event_name);
        if (isDuplicate) {
            toast.warn("Event Name Already Exists. Please choose a different name.", 'error');
            return;
        }
    
        // If it's a new event (not editing an existing one)
        handleAdd(); // Add a new event
        handleCloseModal(); // Close the modal
    };
    
 // function for update data in database 
 async function updateDataInDatabase(itemDetails) {
    const eventid = editedData.EventID.split('-')[0];
    const updateUrl = `calendarEvent/${eventid}/`;
      const requestBody = {
        event_name: itemDetails.event_name || null,
        start_date: itemDetails.start_date || null,
        color: itemDetails.color ||null,

      };

      try {
        // Make a PUT request to update the data
        const data = await putToAPI(updateUrl, requestBody);
          if (data.message === 'Item updated successfully') {
            setAddEvent([...Addevent, data.data]);
            fetchAddEventData();
            toast.success('Event updated Successfully', 'success')
            handleCloseModal(); // Close the modal after adding the event
          } else {
            console.error("Unexpected response:", data.message);
          }
      } catch (error) {
        console.error('Error updating item:', error);
      }
  editedData.EventID = '';
}

// Function to handle edit actions
const HandleEditActions = (id) => {
    OnEditEvent(id);
    handleShowModal();
  }

// Code to Update set Edit dialog data
const OnEditEvent = async (itemId) => {
    const eventId = itemId.split('-')[0];

  try {
      const response = await getFromAPI(`calendarEvent/${eventId}/`);
      const jsonData = JSON.parse(response.data);
        if (response.status) {
          editedData.EventID = itemId;
          updateModalContent(jsonData[0].fields);
        } else {
          //showAlert("Some Error Occurred...");
          toast.error("Please fill all fields!", { autoClose: 2000 });
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
};

// Function to Update Modal Contect when click on edit button
function updateModalContent(itemDetails) {
  setInputData({
    event_name: itemDetails.event_name || "",
    start_date: itemDetails.start_date || "",
    color: itemDetails.color|| "",
  });
}
    // Convert bookingDetails to events format with the same property name as the event title
    const addCalendarEvent = Addevent.flatMap((Event, index) => {
        const startDate = new Date(Event.start_date);
        // Set the time to midnight for both start and end dates
        startDate.setHours(0, 0, 0, 0);
        return [
            {
                title: `Event: ${Event.event_name}`,
                start: startDate,
                id: `${Event.id}-start`,
                className: Event.color, // Use a function to determine the color
                allDay: true // Set to true to indicate that it's an all-day event
            },          
        ];
    });

    // Function to handle project deletion
    const handleDelete = async (itemId) => {
        const eventId = itemId.split('-')[0];

        try {
            // Show confirmation dialog before deleting
            showAlert(
                "Are you sure you want to delete this Event?",
                "confirm",
                async (isConfirmed) => {
                    if (!isConfirmed) {
                        return; // If not confirmed, do nothing
                    }

                    try {
                        const data = await deleteFromAPI(`calendarEvent/${eventId}/`);

                        if (data.message === "Item deleted successfully") {
                            setAddEvent([...Addevent, data.data]);
                            fetchAddEventData()
                            toast.success('Event deleted successfully!', 'success');

                        } else {
                            console.error("Unexpected response:", data.message);
                        }
                    } catch (error) {
                        console.error("Error Event project:", error);
                    }
                }
            );
        } catch (error) {
            console.error("Error showing Event confirmation:", error);
        }
    };


    // Function to convert any date format to yyyy-mm-dd format
    const convertToYYYYMMDD = (dateString) => {
        // Create a new Date object from the input string
        const date = new Date(dateString);

        // Check if the input string is a valid date
        if (isNaN(date.getTime())) {
            console.error(`Invalid date: ${dateString}`);
            return ''; // Return an empty string if the date is invalid
        }

        // Get the components of the date (year, month, day)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if necessary

        // Return the date in yyyy-mm-dd format
        return `${year}-${month}-${day}`;
    };

    const resetInputData = () => {
        setInputData({
            event_name: '',
            start_date: '',
            color: '',
        });
    };

    // Function to handle adding a department
    const ADDRole = () => {
        resetInputData(); // Reset input data when opening the modal for adding
        handleShowModal(); // Show the modal
    };
    const [hoveredEventId, setHoveredEventId] = useState(null);

    const handleMouseEnter = (eventId) => {
        setHoveredEventId(eventId);
    };

    const handleMouseLeave = () => {
        setHoveredEventId(null);
    };

    const [filter, setFilter] = useState(''); // State to hold the selected filter

    // Function to handle dropdown selection
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    //Leave module 
    const [LeaveData ,setLeaveData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
        const LeaveResponse = await getFromAPI('leave/')
        const employeeId = localStorage.getItem('employee_id');
        //setLeaveData(LeaveResponse)
        const approvedLeave = LeaveResponse.filter(leave => leave.leave_status === 'Approved')
        const leavesForUser = approvedLeave.filter(leave => leave.employee_Id === parseInt(employeeId));

        const isPositionAdmin = localStorage.getItem('Position');
        if(isPositionAdmin !== 'Admin'){
            setLeaveData(approvedLeave)
        }
        else{
            setLeaveData(leavesForUser)
        }
    }
    fetchData();
}, []);


const Leavevents = LeaveData.flatMap((leave, index) => {
    const startDate = new Date(leave.leave_start_date);
    const endDate = new Date(leave.leave_end_date);

    // Set the time to midnight for both start and end dates
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    const formattedArrival = startDate.toISOString(); // Adjust the format as needed
    const formattedDeparture = endDate.toISOString(); // Adjust the format as needed
    return [
        
       
        {
            title: `Leave Start: ${leave.leave_type}`,
            start: startDate,
            id: `${leave.id}-start`,
            className: getColor(index), // Use a function to determine the color
            allDay: true // Set to true to indicate that it's an all-day event
        },
        {
            title: `Leave End: ${leave.leave_type}`,
            start: endDate,
            id: `${leave.id}-end`,
            className: getColor(index), // Use a function to determine the color
            allDay: true // Set to true to indicate that it's an all-day event
        }
    ];
});

const allevents = [...projectevents, ...milestonevents, ...addCalendarEvent,...Leavevents]

// Filtered events based on selected filter
    const filteredEvents = filter ? allevents.filter(event => {
        if (filter === 'Release') {
            // Filter events related to projects and milestones
            return event.title.startsWith('Project End') || event.title.startsWith('Milestone End');
        } else if (filter === 'Leave') {
            return event.title.startsWith('Leave Start') || event.title.startsWith('Leave End')
        } else if (filter === 'Event') {
            // Filter only custom calendar events
            return !event.title.startsWith('Project End') && !event.title.startsWith('Milestone End')&&!( event.title.startsWith('Leave Start') || event.title.startsWith('Leave End'));
        } 
        else {
            // If filter is empty or 'All', show all events
            return true;
        }
    }) : allevents;

    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Calendar' />
                    <Container>
                        <Row>
                            <Col className='mb-4'>
                                <Card className='mb-0 h-100'>
                                    <Card.Body>
                                        <div className='custom-fullcalendar' id="calendar">
                                        <div className='mb-3'> 
                                                <select onChange={handleFilterChange}>
                                                    <option value="">All</option>
                                                    <option value="Release">Release</option>
                                                    <option value="Leave">Leave</option>
                                                    <option value="Event">Event</option>
                                                </select>
                                            </div>
                                            
                                            <FullCalendar
                                                responsive
                                                plugins={[dayGridPlugin]}
                                                initialView='dayGridMonth'
                                                weekends={true}
                                                eventContent={renderEventContent}
                                                events={filteredEvents}
                                                headerToolbar={{
                                                    left: 'prevYear,prev,next,nextYear', // will normally be on the left. if RTL, will be on the right
                                                    center: 'title',
                                                    right: 'today myCustomButton2', // Define the buttons for the right section of the headerToolbar
                                                    
                                                }}
                                                buttonText={{
                                                    today: 'Current Month', // Text for the "currentMonth" button
                                                }}
                                        
                                                customButtons={{
                                            //    myCustomButton1: {
                                            //            text: 'Column visibility',
                                            //        },
                                                    myCustomButton2: {
                                                        text: 'Add Event',
                                                        click: ADDRole
                                                    }
                                                }}
                                                dayMaxEventRows={true} // Apply to all non-TimeGrid views
                                                views={{
                                                    timeGrid: {
                                                        dayMaxEventRows: 6 // Adjust to 6 only for timeGridWeek/timeGridDay
                                                    }
                                                }}
                                            />
                                            
                                        </div>
                                        
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            {/* Modal for adding events */}
            <Modal centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-lg">
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title>{editedData.EventID ? 'Edit Event' : 'Add Event' }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Event Name</Form.Label>
                                    <Form.Control type="event"
                                        placeholder="Enter event name... "
                                        onChange={handleChange}
                                        required
                                        value={inputData.event_name}
                                        name="event_name"
                                        id="event_name" />
                                </Form.Group>
                            </Col>

                            <Row>
                               <Col lg={6} sm={12}>
                                <Form.Group className="custom-fr-group">
                                    <Form.Label>Date of the Event</Form.Label>
                                    <div className="input-icon-content custom-form-input">
                                        <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('start_date_picker').click()}></i>
                                    </div>
                                    <DatePicker
                                        id="start_date_picker"
                                        selected={inputData.start_date ? new Date(inputData.start_date) : null}
                                        onChange={(date) => handleChangeDate(date, "start_date")}
                                        placeholderText="Select start date"
                                        dateFormat={formatDate(inputData.start_date)}
                                        showPopperArrow={false} // Add this line to disable the popper arrow
                                    />
                                </Form.Group>
                                </Col>
                              

                            <Col lg={6} sm={12}>
                                <Form.Group className={`mb-3 ${!inputData.color ? 'has-error' : ''}`}>
                                    <Form.Label>Background-color</Form.Label>
                                    <Form.Select value={inputData.color} onChange={handleChange} name="color" id="color">
                                        <option value="">Select</option>
                                        <option value="soft-pink">Primary</option>
                                        <option value="soft-secondary">secondary</option>
                                        <option value="soft-success">success</option>
                                        <option value="soft-warning">warning</option>
                                        <option value="soft-danger">danger</option>
                                        <option value="soft-info">info</option>
                                        <option value="soft-light">light</option>
                                        <option value="soft-dark">dark</option>
                                        <option value="soft-link">link</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            </Row>

                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAction}>
                        Ok
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
        </>
    )

    function renderEventContent(eventInfo) {
        const showDeleteButton = eventInfo.event.id;
        if (eventInfo.event.title.startsWith("Event") || eventInfo.event.title.startsWith("Event")) {
            // Render the event title with a remove button
            return (
                <div className='calender-event-hover' onMouseEnter={() => handleMouseEnter(eventInfo.event.id)} onMouseLeave={handleMouseLeave}>
                    <b className={eventInfo.event.classNames + 'calendar-delete-btn' ? 'clndr-text' : ''}>
                        <i>{eventInfo.event.title}</i>
                    </b>
                    
                    {showDeleteButton && (
                        <div className='calendar-delete-btn'>
                            <Button variant="soft-danger" onClick={() => handleDelete(eventInfo.event.id)}>
                                <i className="bi bi-trash"></i> {/* Using bi bi-trash icon */}
                            </Button>

                             <Button variant="soft-primary" onClick={() => HandleEditActions(eventInfo.event.id)}>
                                <i className="bi bi-pencil-square"></i> {/* Using bi bi-trash icon */}
                            </Button>
                        </div>
                    )}
                </div>
            );
        } else {
            // Render the event title without the remove button
            return (
                <div onMouseEnter={() => handleMouseEnter(eventInfo.event.id)} onMouseLeave={handleMouseLeave}>
                    <b className={eventInfo.event.classNames}>
                        <i>{eventInfo.event.title}</i>
                    </b>
                </div>

            );
        }
    }
}