import React from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Container, Form, Card, Row, Col } from 'react-bootstrap';

export default function HelpCenter() {

    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Help Center' />

                    <Container>
                        <Row>
                            <Col className="d-flex justify-content-center custom-fr-group mt-5">
                                <Form>
                                    <h1 className="text-muted mb-3">Hello, How can we help you?</h1>
                                    <Form.Group>
                                        <div className="search-icon-content custom-form-input">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <i
                                                className={`bi bi-search search-icon`} style={{ paddingRight: '10px' }}
                                            ></i>
                                            <Form.Control type="search" placeholder="Search..." />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row className="d-flex justify-content-center">
                            <Col className='mb-4' lg={3}>
                                <Card className='mb-0 h-100 text-center'>
                                    <Card.Body>
                                        <i className="bi bi-compass fs-50"></i>
                                        <h5>Getting Started</h5>
                                        <p className="fs-14 mb-0 text-muted">Learn the basic of setting up your profile and connecting with friends.</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className='mb-4' lg={3}>
                                <Card className='mb-0 h-100 text-center'>
                                    <Card.Body>
                                        <i className="bi bi-chat-square fs-50"></i>
                                        <h5>FAQ</h5>
                                        <p className="fs-14 mb-0 text-muted">Vulputate faucibus est varius ullamcorper at a mi eget.</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className='mb-4' lg={3}>
                                <Card className='mb-0 h-100 text-center'>
                                    <Card.Body>
                                        <i className='bi bi-people-fill fs-50'></i>
                                        <h5>Community</h5>
                                        <p className="fs-14 mb-0 text-muted">Valputate faucibus set varius ullamcorper at a mi eget.</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div >
                <Footer />
            </div >
        </>
    )
}