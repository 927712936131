import React from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Container, Card, Row, Col, Accordion } from 'react-bootstrap';

export default function Guide() {
    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Guide' />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Employee Guide Manual</Card.Title>
                                        <h4 className="mb-0 text-muted">Welcome to Foogle Tech Software!</h4>

                                        <Accordion className="mt-3">
                                            <Row>
                                                <Col lg={6}>
                                                    <Accordion.Item eventKey="0" className="mb-3">
                                                        <Accordion.Header>1. Introduction</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p className="mb-0 text-muted">Welcome to Foogle Tech Software, where innovation meets excellence. As a valued member of our team, you play a crucial role in our mission to deliver cutting-edge software solutions. This guide serves as your roadmap to navigate our company culture, policies, and resources effectively.</p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="1" className="mb-3">
                                                        <Accordion.Header>2. Company Vision and Values:</Accordion.Header>
                                                        <Accordion.Body>
                                                            <h6 className="text-secondary">At Foogle Tech Software, we are committed to:</h6>
                                                            <p className="text-muted">Excellence: Striving for excellence in all endeavors.</p>
                                                            <p className="text-muted">Innovation: Pushing the boundaries of innovation to deliver unparalleled solutions.</p>
                                                            <p className="text-muted">Integrity: Conducting ourselves with the utmost integrity and ethical standards.</p>
                                                            <p className="mb-0 text-muted">Collaboration: Fostering a culture of collaboration and teamwork to achieve collective success.</p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="2" className="mb-3">
                                                        <Accordion.Header>3. Policies and Procedures:</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p className="text-muted">Code of Conduct: Adherence to our code of conduct is mandatory. It outlines our expectations regarding professional behavior, confidentiality, and respect for colleagues.</p>
                                                            <p className="text-muted">Compliance: Compliance with all applicable laws, regulations, and company policies is imperative.</p>
                                                            <p className="text-muted">Confidentiality: Safeguarding confidential company and client information is paramount. Violation of confidentiality may result in disciplinary action.</p>
                                                            <p className="mb-0 text-muted">Conflict of Interest: Employees must disclose any conflicts of interest that may arise between personal interests and company duties.</p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="3" className="mb-3">
                                                        <Accordion.Header>4. Communication Protocol:</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p className="text-muted">Clear and Concise: Effective communication is essential. Ensure your communications are clear, concise, and professional.</p>
                                                            <p className="text-muted">Open-Door Policy: We encourage open dialogue and transparency. Feel free to approach your supervisor or HR with any concerns or suggestions.</p>
                                                            <p className="mb-0 text-muted">Respectful Communication: Respect the opinions and viewpoints of your colleagues, fostering an environment of mutual respect and understanding.</p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Col>

                                                <Col lg={6}>
                                                    <Accordion.Item eventKey="4" className="mb-3">
                                                        <Accordion.Header>5. Work Environment Standards:</Accordion.Header>
                                                        <Accordion.Body>
                                                            <h6 className="text-secondary">At Foogle Tech Software, we are committed to:</h6>
                                                            <p className="text-muted">Professionalism: Maintain a professional demeanor at all times, reflecting positively on the company.</p>
                                                            <p className="text-muted">Workplace Etiquette: Respect shared spaces and resources, maintaining cleanliness and orderliness.</p>
                                                            <p className="mb-0 text-muted">Dress Code: Dress in attire that aligns with the professional standards of our industry unless otherwise specified.</p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="5" className="mb-3">
                                                        <Accordion.Header>6. Benefits and Compensation:</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p className="text-muted">Competitive Compensation: We offer competitive salaries and benefits packages to reward your contributions and dedication.</p>
                                                            <p className="text-muted">Health and Wellness: Take advantage of our comprehensive health insurance plans and wellness programs to prioritize your well-being.</p>
                                                            <p className="mb-0 text-muted">Retirement Planning: Secure your financial future with our retirement savings plans and investment options.</p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="7" className="mb-3">
                                                        <Accordion.Header>8. Health and Safety Guidelines:</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p className="text-muted">Workplace Safety: Prioritize safety in the workplace, adhering to all safety protocols and procedures.</p>
                                                            <p className="text-muted">Ergonomics: Maintain proper ergonomic practices to promote health and prevent injuries.</p>
                                                            <p className="mb-0 text-muted">Mental Health Support: Your mental health is important. Access our resources and support services to prioritize your well-being.</p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="8" className="mb-3">
                                                        <Accordion.Header>9. Additional Resources and Support:</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p className="text-muted">Employee Handbook: Refer to our comprehensive employee handbook for detailed policies, procedures, and guidelines.</p>
                                                            <p className="text-muted">HR Support: Contact our HR department for assistance with employment-related matters, including benefits, payroll, and policies.</p>
                                                            <p className="mb-0 text-muted">IT Support: Reach out to our IT department for technical assistance and support with software tools and systems.</p>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Col>
                                            </Row>
                                            <div className="text-center mt-2">
                                                <h4>Conclusion</h4>
                                                <p className=" mb-0 text-muted ">Workplace Safety: Prioritize safety in the workplace, adhering to all safety protocols and procedures.</p>
                                                <p className="mb-0 text-muted">Congratulations on joining the Foogle Tech Software team! We are excited to embark on this journey together and achieve great success. Should you have any questions or require assistance, our dedicated team is here to support you. Welcome aboard!</p>
                                            </div>
                                        </Accordion>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div >
                <Footer />
            </div >
        </>
    )
}
