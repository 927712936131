// Import necessary dependencies from React and external libraries
import React, { useState, useEffect , useCallback, useRef } from 'react'
import PageTitle from "../layout/PageTitle.js";
import Footer from "../layout/Footer.js";
import { Table, Form, Dropdown, Button, Card, Container, Row, Col, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import user from "../../images/user1.png";

// Import additional libraries for DataTable and API utility functions
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { getFromAPI, postToAPI, putToAPI } from '../Utils/utils.js'; // Import utility functions
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Navigate } from "react-router-dom";

// Component for the Performance page
export default function Performance() {

    // This function runs when the component mounts
    useEffect(() => {
        // It sets the document title to 'Performance | PMRS App'
        document.title = 'Performance | PMRS App';
        // This return statement defines a cleanup function
        return () => {
            // This function runs when the component unmounts
            document.title = 'PMRS App';
            // It resets the document title back to 'PMRS App'
        };
    }, []);

    const szfullName = localStorage.getItem('First_name') + ' ' + localStorage.getItem('Last_name');
    const szRole = localStorage.getItem('Role');

    const [targetAddedSuccessfully, settargetAddedSuccessfully] = useState(false);
    const [valueAddedSuccessfully, setvalueAddedSuccessfully] = useState(false);
    const [targetEditedSuccessfully, setTargetEditedSuccessfully] = useState(false);
    const [valueEditedSuccessfully, setvalueEditedSuccessfully] = useState(false);
    const [EmployeeeData, SetEmployeeeData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Navigate to Login page if no Employee ID Found
        const User_Id = localStorage.getItem('employee_id');
        if (User_Id === null) {
            navigate('/');
        }
    })

    // for fetching the employee data
    useEffect(() => {
        const fetchEmployeeeData = async () => {
            try {
                const User_Id = localStorage.getItem('employee_id');
                const response = await getFromAPI(`employee/employee_id/${User_Id}/`);
                SetEmployeeeData(JSON.parse(response.data)[0].fields);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchEmployeeeData();
    }, []);

    // Effect to display success message after role edition
    useEffect(() => {
        if (targetEditedSuccessfully) {
            toast.success("Target Edited successfully!", "success");
            setTimeout(() => {
                setTargetEditedSuccessfully(false);
            }, 3000); // Clear the success message after 3 seconds
        }
    }, [targetEditedSuccessfully]);

    useEffect(() => {
        if (valueEditedSuccessfully) {
            toast.success("Value Edited successfully!", "success");
            setTimeout(() => {
                setvalueEditedSuccessfully(false);
            }, 3000); // Clear the success message after 3 seconds
        }
    }, [valueEditedSuccessfully]);

    // Effect to display success message after role addition  
    useEffect(() => {
        if (targetAddedSuccessfully) {
            toast.success("Target added successfully!", "success");
            setTimeout(() => {
                settargetAddedSuccessfully(false);
            }, 3000); // Clear the success message after 3 seconds
        }
    }, [targetAddedSuccessfully]);

    useEffect(() => {
        if (valueAddedSuccessfully) {
            toast.success("Value added successfully!", "success");
            setTimeout(() => {
                setvalueAddedSuccessfully(false);
            }, 3000); // Clear the success message after 3 seconds
        }
    }, [valueAddedSuccessfully]);

    // State for holding performance data
    const [performanceData, setPerformanceData] = useState([
        // Default performance metrics with initial values
        { label: 'Number of Release', value: "0/0", color: "primary", icon: "database-check" },
        { label: 'Number of Bugs', value: "0/0", color: "danger", icon: "bug" },
        { label: 'Todo Done', value: "0/0", color: "warning", icon: "list-check" },
        { label: 'Line Added', value: "0/0", color: "secondary", icon: "card-text" },
        { label: 'Code Quality', value: "0/0", color: "info", icon: "code-slash" },
        { label: 'Responsibility', value: "0/0", color: "primary", icon: "person-check" },
        { label: 'Behavior', value: 0, color: "success", icon: "person-heart" },
        { label: 'Performance', value: 0 + "%", color: "danger", icon: "graph-up" },
    ]);

    // Selected employee id
    const [editedData] = useState({
        Employee_Id: '',
    });
    // Assigned weightages (For now embedded in code untill we have a sure way of adding them through UI or config)
    const Weightages = [
        0.2,  // Weightage for no of released
        0.15, // Weightage for no of bug fixed
        0.15, // Weightage for no of todo done
        0.1,  // Weightage for no of line added
        0.1,  // Weightage for bug of code quality
        0.1,  // Weightage for behaviour
        0.2,  // Weightage for responsibility
    ];

    // State for holding employee data
    const [employeeData, setemployeeData] = useState([]);
    const [isSupervisor, setIsSupervisor] = useState(false);
    // Fetch employee data from API when the component mounts
    const fetchData = async () => {
        try {
            const response = await getFromAPI(`employee/`);
            const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
            const loggedInUser = response.find(
                (employee) => employee.employee_id === loggedInUserId
            );
            if (loggedInUser && (loggedInUser.position.toLowerCase() === "team leader" || loggedInUser.position.toLowerCase() === "project manager")) {
                setIsSupervisor(true);
            }
            if (!response) {
                throw new Error('Network response was not ok');
            }
            // Set Employee data
            setemployeeData(response);
            // Check if employeeData is available before making the API call
            const fetchPerformanceData = async () => {
                try {
                    const User_Id = localStorage.getItem('employee_id');
                    const response = await getFromAPI(`performance/employee_id/${User_Id}/`);
                    if (!response || response.length === 0) {
                        throw new Error('Network response was not ok or response is empty');
                    }
                    // Set user performance info
                    UpdatePerformanceData(JSON.parse(response.data)[0].fields);
                } catch (error) {
                    toast.error('Error fetching performance data:' + error.message, "Error");
                }
            };

            fetchPerformanceData();
        } catch (error) {
            toast.error('Error fetching data:' + error.message, "Error");
        }
    };

    // Effect to fetch employee data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    // Update performance data based on API response
    function UpdatePerformanceData(itemDetails) {
        var no_release = itemDetails.added_no_release + "/" + itemDetails.target_no_release;
        var no_bug_Fixed = itemDetails.added_no_bug_Fixed + "/" + itemDetails.target_no_bug_Fixed;
        var no_todo_done = itemDetails.added_no_todo_done + "/" + itemDetails.target_no_todo_done;
        var no_line_added = itemDetails.added_no_line_added + "/" + itemDetails.target_no_line_added;
        var no_code_quality = itemDetails.added_no_code_quality + "/" + itemDetails.target_code_quality;
        // Extract and update performance info
        const updatedPerformanceInfo = [
            { label: 'Number of Release', value: no_release || 0, color: "primary", icon: "database-check" },
            { label: 'Number of Bugs', value: no_bug_Fixed || 0, color: "danger", icon: "bug" },
            { label: 'Todo Done', value: no_todo_done || 0, color: "warning", icon: "list-check" },
            { label: 'Line Added', value: no_line_added || 0, color: "secondary", icon: "card-text" },
            { label: 'Code Quality', value: (no_code_quality || 0) + "%", color: "info", icon: "code-slash" },
            { label: 'Responsibility', value: (itemDetails.added_responsibility || 0), color: "primary", icon: "person-check" },
            { label: 'Behavior', value: (itemDetails.added_behaviour || 0), color: "success", icon: "person-heart" },
            { label: 'Performance', value: (itemDetails.performance || 0) + "%", color: "danger", icon: "graph-up" },
            // Update with relevant data properties
        ];
        setPerformanceData(updatedPerformanceInfo);
    }

    DataTable.Buttons.jszip(JSZip);
    const tableRef = useRef(null); // Ref for DataTable instance
    // Initialize DataTable after updating the employeeData
    useEffect(() => {
        if (tableRef.current) {
            // If DataTable has already been initialized, destroy it first
            tableRef.current.clear().destroy();
          }
        
          // Initialize DataTable
          const dataTableInstance = $('.maindatatable').DataTable({
            dom: 'flrtip',
            language: {
              search: '',
              searchPlaceholder: 'Search...',
              paginate: {
                previous: '«',
                next: '»',
              },
            },
            data: employeeData, // Add milestoneData directly to the DataTable
            columns: [
              { data: null,
                render: function (data, type, row) {
                  return row.first_name + ' ' + row.last_name;
                } 
              },
              { data: 'employee_id' },
              { data: 'department' },
              { data: 'reporting_to' },
              {
                data: null,
                render: function (data, type, row) {
                  return `
                    <div class="d-flex">
                    <button class="btn btn-primary table-btn me-2" data-id="${row.employee_id}" data-action="target" data-bs-toggle="tooltip" title="Add Target">
                    <i class="bi bi-plus"></i>
                  </button>
                  <button class="btn btn-danger table-btn" data-id="${row.employee_id}" data-action="added" data-bs-toggle="tooltip" title="Add Value">
                    <i class="bi bi-plus"></i>
                  </button>
                    </div>
                  `;
                }
              }
            ]
          });
        
          // Store the DataTable instance in the ref
          tableRef.current = dataTableInstance;
          // Attach event listeners to handle edit and delete actions
          $(document.body).on('click', '.btn.table-btn', function() {
            const action = $(this).data('action');
            const id = $(this).data('id');
            if (action === 'target') {
                showModalTarget(id);
            } else if (action === 'added') {
                showModalAdded(id);
            }
          });
        }, [employeeData]);

    // State for controlling the target modal
    const [showTargetModal, setshowTargetModal] = useState(false);
    const handleCloseTargetModal = () => setshowTargetModal(false);
    const handleshowTargetModal = () => setshowTargetModal(true);

    // State for controlling the add value modal
    const [showAddValueModal, setshowAddValueModal] = useState(false);
    const handleCloseAddValueModal = () => setshowAddValueModal(false);
    const handleshowAddValueModal = () => setshowAddValueModal(true);

    // State to hold modal input data
    const [inputperformanceData, setInputPerformanceData] = useState({
        target_no_release: "",
        target_no_bug_Fixed: "",
        target_no_todo_done: "",
        target_no_line_added: "",
        target_code_quality: "",
        added_no_release: "",
        added_no_bug_Fixed: "",
        added_no_todo_done: "",
        added_no_line_added: "",
        added_no_code_quality: "",
        added_responsibility: "",
        added_behaviour: "",
        performance: "",
    });

    // Handling change event for input fields
    const handlePerformanceChange = (e) => {
        setInputPerformanceData({ ...inputperformanceData, [e.target.name]: e.target.value });
    };

    // Function to check if any value in an array is negative
    function hasNegativeValue(array) {
        return array.some(value => value < 0);
    }

    // Function to handle adding target performance
    const OnAddingTargetPerformance = useCallback(async () => {
        try {
            // Fetch current performance data for calculations
            const User_Id = localStorage.getItem('employee_id');
            const response = await getFromAPI(`performance/employee_id/${editedData.Employee_Id}/`);
            const data = JSON.parse(response.data);
            if (data && data.length > 0) {
                const jsonData = data[0].fields;
                // Extract target values, manual values, and percentage values for each metric
                const targetValues = [
                    inputperformanceData.target_no_release,
                    inputperformanceData.target_no_bug_Fixed,
                    inputperformanceData.target_no_todo_done,
                    inputperformanceData.target_no_line_added,
                    inputperformanceData.target_code_quality,
                    100,  // Behaviour is in percentage so the target value should be 100
                    100,  // Responsibility is in percentage so the target value should be 100
                ].map(Number); // Convert to numbers
    
                const manualValues = [
                    jsonData.added_no_release,
                    jsonData.added_no_bug_Fixed,
                    jsonData.added_no_todo_done,
                    jsonData.added_no_line_added,
                    jsonData.added_no_code_quality,
                    jsonData.added_responsibility,
                    jsonData.added_behaviour,
                ].map(Number); // Convert to numbers   
                // Calculate overall performance
                const performance_value = calculatePerformance(targetValues, manualValues, Weightages);
    
                const response = await putToAPI(`performance/employee_id/${editedData.Employee_Id}/`, {
                    target_no_release: inputperformanceData.target_no_release,
                    target_no_bug_Fixed: inputperformanceData.target_no_bug_Fixed,
                    target_no_todo_done: inputperformanceData.target_no_todo_done,
                    target_no_line_added: inputperformanceData.target_no_line_added,
                    target_code_quality: inputperformanceData.target_code_quality,
                    performance: Math.round(performance_value),
                });
                fetchData();
                // Only update data if same employee
                const PerformanceData = response.data.find(item => item.employee_id.toString() === User_Id);
                if (PerformanceData && editedData.Employee_Id.toString() === User_Id) {
                    UpdatePerformanceData(PerformanceData); // Update this line                        
                }
                settargetAddedSuccessfully(true);
                editedData.Employee_Id = "";
            } else {
                toast.error("Error: Empty or undefined response data.");
            }
        } catch (error) {
            toast.error("Error Adding Target: " + error.message, "Error");
        }
    }, [editedData.Employee_Id, inputperformanceData, fetchData, calculatePerformance, putToAPI, toast, fetchData, UpdatePerformanceData, settargetAddedSuccessfully]);
    
    // Function to handle adding manually added performance
    const OnAddingAddedPerformance = useCallback(async () => {
        try {
            // Fetch current performance data for calculations
            const User_Id = localStorage.getItem('employee_id');
            const response = await getFromAPI(`performance/employee_id/${editedData.Employee_Id}/`);
            const data = JSON.parse(response.data);
            if (data && data.length > 0) {
                const jsonData = data[0].fields;
                // Extract target values, manual values, and percentage values for each metric
                const targetValues = [
                    jsonData.target_no_release,
                    jsonData.target_no_bug_Fixed,
                    jsonData.target_no_todo_done,
                    jsonData.target_no_line_added,
                    jsonData.target_code_quality,
                    100,  // Behaviour is in percentage so the target value should be 100
                    100,  // Responsibility is in percentage so the target value should be 100
                ].map(Number); // Convert to numbers
    
                const manualValues = [
                    inputperformanceData.added_no_release,
                    inputperformanceData.added_no_bug_Fixed,
                    inputperformanceData.added_no_todo_done,
                    inputperformanceData.added_no_line_added,
                    inputperformanceData.added_no_code_quality,
                    inputperformanceData.added_behaviour,
                    inputperformanceData.added_responsibility,
                ].map(Number); // Convert to numbers
    
                const performance_value = calculatePerformance(targetValues, manualValues, Weightages);
                // Update API with added performance data
                const response = await putToAPI(`performance/employee_id/${editedData.Employee_Id}/`, {
                    added_no_release: inputperformanceData.added_no_release,
                    added_no_bug_Fixed: inputperformanceData.added_no_bug_Fixed,
                    added_no_todo_done: inputperformanceData.added_no_todo_done,
                    added_no_line_added: inputperformanceData.added_no_line_added,
                    added_no_code_quality: inputperformanceData.added_no_code_quality,
                    added_responsibility: inputperformanceData.added_responsibility,
                    added_behaviour: inputperformanceData.added_behaviour,
                    performance: Math.round(performance_value),
                });
                if (response.status) {
                    fetchData();
                    // Only update data if same employee
                    const PerformanceData = response.data.find(item => item.employee_id.toString() === User_Id);
                    if (PerformanceData && editedData.Employee_Id.toString() === User_Id) {
                        UpdatePerformanceData(PerformanceData); // Update this line                        
                    }
                    setvalueAddedSuccessfully(true)
                    editedData.Employee_Id = "";
                } else {
                    alert("Some Error Occurred...");
                }
                setInputPerformanceData({
                    target_no_release: "",
                    target_no_bug_Fixed: "",
                    target_no_todo_done: "",
                    target_no_line_added: "",
                    target_code_quality: "",
                    added_no_release: "",
                    added_no_bug_Fixed: "",
                    added_no_todo_done: "",
                    added_no_line_added: "",
                    added_no_code_quality: "",
                    added_responsibility: "",
                    added_behaviour: "",
                    performance: "",
                });
            } else {
                toast.error("Error: Empty or undefined response data.", "Error");
            }
        }
        catch (error) {
            toast.error("Error adding performance: " + error.message, "Error");
        }
    }, [editedData.Employee_Id, inputperformanceData, fetchData, calculatePerformance, putToAPI, toast, fetchData, UpdatePerformanceData, setvalueAddedSuccessfully]);
    
    // For handeling show modal fro target and add modal
    const showModalTarget = useCallback(async (Employee_id) => {
    editedData.Employee_Id = Employee_id;
    try {
        const response = await getFromAPI(`performance/employee_id/${editedData.Employee_Id}/`);
        const data = JSON.parse(response.data);
        if (data && data.length > 0) {
            const jsonData = data[0].fields;
            setInputPerformanceData({
                target_no_release: jsonData.target_no_release,
                target_no_bug_Fixed: jsonData.target_no_bug_Fixed,
                target_no_todo_done: jsonData.target_no_todo_done,
                target_no_line_added: jsonData.target_no_line_added,
                target_code_quality: jsonData.target_code_quality,
                added_no_release: jsonData.added_no_release,
                added_no_bug_Fixed: jsonData.added_no_bug_Fixed,
                added_no_todo_done: jsonData.added_no_todo_done,
                added_no_line_added: jsonData.added_no_line_added,
                added_no_code_quality: jsonData.added_no_code_quality,
                added_responsibility: jsonData.added_responsibility,
                added_behaviour: jsonData.added_behaviour,
            });
            handleshowTargetModal();
        }
    } catch (error) {
        console.error('Error:', error);
        // Handle error accordingly
    }
}, [handleshowTargetModal, setInputPerformanceData]);

const showModalAdded = useCallback(async (Employee_id) => {
    editedData.Employee_Id = Employee_id;
    try {
        const response = await getFromAPI(`performance/employee_id/${editedData.Employee_Id}/`);
        const data = JSON.parse(response.data);
        if (data && data.length > 0) {
            const jsonData = data[0].fields;
            setInputPerformanceData({
                target_no_release: jsonData.target_no_release,
                target_no_bug_Fixed: jsonData.target_no_bug_Fixed,
                target_no_todo_done: jsonData.target_no_todo_done,
                target_no_line_added: jsonData.target_no_line_added,
                target_code_quality: jsonData.target_code_quality,
                added_no_release: jsonData.added_no_release,
                added_no_bug_Fixed: jsonData.added_no_bug_Fixed,
                added_no_todo_done: jsonData.added_no_todo_done,
                added_no_line_added: jsonData.added_no_line_added,
                added_no_code_quality: jsonData.added_no_code_quality,
                added_responsibility: jsonData.added_responsibility,
                added_behaviour: jsonData.added_behaviour,
            });
            handleshowAddValueModal();
        }
    } catch (error) {
        console.error('Error:', error);
        // Handle error accordingly
    }
}, [handleshowAddValueModal, setInputPerformanceData]);

        // Function to check if input values are numeric
        const isNumericInput = (inputData) => {
            for (const key in inputData) {
                if (isNaN(inputData[key])) {
                    return false;
                }
            }
            return true;
        };

    // For handling Target and Addvalue module button events
    const handleTargetOKActions = useCallback(async () => {
        if (hasNegativeValue(Object.values(inputperformanceData))) {
            // Open the target modal for editing if any target value is negative
            handleshowTargetModal();
            toast.error("Target values cannot be negative.");
            return;
        }
        if (!isNumericInput(inputperformanceData)) {
            handleshowTargetModal();
            toast.error("Please enter numeric values only.");
            return;
        }
        // If no negative value, proceed with adding target performance
        await OnAddingTargetPerformance();
        handleCloseTargetModal();
    }, [inputperformanceData, handleshowTargetModal, OnAddingTargetPerformance, handleCloseTargetModal]);
    

    const handleAddedOKActions = useCallback(async () => {
        if (hasNegativeValue(Object.values(inputperformanceData))) {
            // Open the add value modal for editing if any value is negative
            handleshowAddValueModal();
            toast.error("Performance values cannot be negative.");
            return;
        }if (!isNumericInput(inputperformanceData)) {
            handleshowAddValueModal();
            toast.error("Please enter numeric values only.");
            return;
        }
    
        // If no negative value, proceed with adding added performance
        await OnAddingAddedPerformance();
        handleCloseAddValueModal();
    }, [inputperformanceData, handleshowAddValueModal, OnAddingAddedPerformance, handleCloseAddValueModal]);
    
    // Function to calculate average value
    function calculatePerformance(targetValues, addedValues, weightages) {
        let totalWeightedPercentage = 0;
        let totalWeight = 0;

        for (let key in targetValues) {
            const targetValue = targetValues[key];
            const addedValue = addedValues[key];
            const weight = weightages[key];

            // Calculate the percentage of target achieved
            const percentage = (addedValue / targetValue) * 100;

            // Calculate weighted percentage for each value
            const weightedPercentage = percentage * weight;

            // Add to total weighted percentage and total weight
            totalWeightedPercentage += weightedPercentage;
            totalWeight += weight;
        }

        // Calculate overall average percentage
        const overallAveragePercentage = totalWeightedPercentage / totalWeight;

        return overallAveragePercentage;
    }

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <PageTitle breadcrumbLast="Performance"/>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex align-items-center">
                                            <div className="flex-shrink-0 d-flex justify-content-center">
                                                {EmployeeeData.profile_picture ? (
                                                    <img className="rounded-circle img-thumbnail avatar-img-xxl" src={EmployeeeData.profile_picture} alt="default" />
                                                ) : (
                                                    <img className="rounded-circle img-thumbnail avatar-img-xxl" src={user} alt="default" />
                                                )}
                                            </div>
                                            <div className="flex-grow-1 ms-md-3 text-center text-md-start mt-4 mt-md-0">
                                                <h3>{szfullName}</h3>
                                                <h5 className="text-muted mb-2">{szRole}</h5>
                                            </div>
                                            <div className="d-md-flex justify-content-md-center text-center text-md-end align-items-center mt-4 mt-md-0">


                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Body className='pb-0'>
                                        <Card.Title>
                                            Performance Info
                                        </Card.Title>
                                        <Row>
                                            {performanceData.map((info, index) => (
                                                <Col md={6} lg={4} xxl={3} key={index}>
                                                    <Card className='shadow-none border'>
                                                        <Card.Body>
                                                            <div className="d-flex align-items-center">
                                                                <div className={`avatar avatar-xl bg-soft-${info.color} text-${info.color}`}>
                                                                    <i className={`bi bi-${info.icon} avatar-icon`}></i>
                                                                </div>
                                                                <div className="ms-3">
                                                                    <h4 className='mb-0'>{info.value}</h4>
                                                                    <p className="text-muted mb-0">{info.label}</p>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Card.Body>
                                </Card>
                                {isSupervisor && (
                                <Card>
                                    <Card.Body>
                                        <Table
                                            responsive
                                            bordered
                                            className="mb-0 table-nowrap w-100 maindatatable"
                                        >
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Employee Name</th>
                                                    <th>Employee ID</th>
                                                    <th>Department</th>
                                                    <th>Reporting To</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                            </tbody>
                                        </Table>
                                    </Card.Body>

                                </Card>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
                <Modal centered show={showTargetModal} onHide={handleCloseTargetModal} dialogClassName="modal-lg">
                    <Modal.Header className="border-0" closeButton>
                        <Modal.Title> Add Target</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>

                            <Row>
                                <Col>
                                    <Form>
                                        <Row>
                                            <Col lg={6} sm={12}>
                                                <Form.Group
                                                    className="custom-fr-group"
                                                >
                                                    <Form.Label>Number of Release </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Number of Release..."
                                                        value={inputperformanceData.target_no_release}
                                                        required
                                                        name="target_no_release"
                                                        id="target_no_release"
                                                        onChange={handlePerformanceChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group
                                                    className="custom-fr-group"
                                                >
                                                    <Form.Label>Number of Bugs Fixed </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Number of Bugs Fixed..."
                                                        value={inputperformanceData.target_no_bug_Fixed}
                                                        required
                                                        name="target_no_bug_Fixed"
                                                        id="target_no_bug_Fixed"
                                                        onChange={handlePerformanceChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6} sm={12}>
                                                <Form.Group
                                                    className="custom-fr-group"
                                                >
                                                    <Form.Label>To Do Done </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter To Do Done..."
                                                        value={inputperformanceData.target_no_todo_done}
                                                        required
                                                        name="target_no_todo_done"
                                                        id="target_no_todo_done"
                                                        onChange={handlePerformanceChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} sm={12}>
                                                <Form.Group
                                                    className="custom-fr-group"
                                                >
                                                    <Form.Label>Line Added</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Line Added..."
                                                        required
                                                        value={inputperformanceData.target_no_line_added}
                                                        name="target_no_line_added"
                                                        id="target_no_line_added"
                                                        onChange={handlePerformanceChange}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6} sm={12}>
                                                <Form.Group
                                                >
                                                    <Form.Label>Code Quality</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter Code Quality..."
                                                        required
                                                        value={inputperformanceData.target_code_quality}
                                                        name="target_code_quality"
                                                        id="target_code_quality"
                                                        onChange={handlePerformanceChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                    </Form>
                                </Col>
                            </Row>

                        </div>
                    </Modal.Body>
                    <Modal.Footer >
                        <Button variant="primary" onClick={handleTargetOKActions}>
                            Ok
                        </Button>
                        <Button variant="secondary" onClick={handleCloseTargetModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal centered show={showAddValueModal} onHide={handleCloseAddValueModal} dialogClassName="modal-lg">
                    <Modal.Header className="border-0" closeButton>
                        <Modal.Title> Add value</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group
                                                className="custom-fr-group"
                                            >
                                                <Form.Label>Number of Release </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Number of Release..."
                                                    value={inputperformanceData.added_no_release}
                                                    required
                                                    name="added_no_release"
                                                    id="added_no_release"
                                                    onChange={handlePerformanceChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group
                                                className="custom-fr-group"
                                            >
                                                <Form.Label>Number of Bugs Fixed </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Number of Bugs Fixed..."
                                                    value={inputperformanceData.added_no_bug_Fixed}
                                                    required
                                                    name="added_no_bug_Fixed"
                                                    id="added_no_bug_Fixed"
                                                    onChange={handlePerformanceChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group
                                                className="custom-fr-group"
                                            >
                                                <Form.Label>To Do Done </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter To Do Done..."
                                                    value={inputperformanceData.added_no_todo_done}
                                                    required
                                                    name="added_no_todo_done"
                                                    id="added_no_todo_done"
                                                    onChange={handlePerformanceChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group
                                                className="custom-fr-group"
                                            >
                                                <Form.Label>Line Added</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Line Added..."
                                                    value={inputperformanceData.added_no_line_added}
                                                    required
                                                    name="added_no_line_added"
                                                    id="added_no_line_added"
                                                    onChange={handlePerformanceChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group
                                                className="custom-fr-group"
                                            >
                                                <Form.Label>Code Quality</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Code Quality..."
                                                    required
                                                    value={inputperformanceData.added_no_code_quality}
                                                    name="added_no_code_quality"
                                                    id="added_no_code_quality"
                                                    onChange={handlePerformanceChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group
                                                className="custom-fr-group"
                                            >
                                                <Form.Label>Responsibility</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Responsibility..."
                                                    required
                                                    value={inputperformanceData.added_responsibility}
                                                    name="added_responsibility"
                                                    id="added_responsibility"
                                                    onChange={handlePerformanceChange}
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group
                                            >
                                                <Form.Label>Behaviour</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Behaviour..."
                                                    required
                                                    value={inputperformanceData.added_behaviour}
                                                    name="added_behaviour"
                                                    id="added_behaviour"
                                                    onChange={handlePerformanceChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer >
                        <Button variant="primary" onClick={handleAddedOKActions}>
                            Ok
                        </Button>
                        <Button variant="secondary" onClick={handleCloseAddValueModal}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer position="top-left" autoClose={3000} hideProgressBar theme="colored" />
            </div>
        </>
    )
}
