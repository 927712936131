import React, { useEffect, useState, useRef, useCallback } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import Select from "react-select";
import { Container, Row, Col, Card, Button, Table, Modal, Form, OverlayTrigger, Tooltip,Badge } from 'react-bootstrap';
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert } from '../Utils/utils.js'; // Import utility functions
import { WithContext as ReactTags } from 'react-tag-input';   // Import the react-tag-input for setup this also run this command:  "npm install save react-tag-input"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, Navigate } from "react-router-dom";

// Functional component for AdminProject
export default function AdminProject() {

  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Locker | PMRS App'
    document.title = 'Locker | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);

  DataTable.Buttons.jszip(JSZip);
  const navigate = useNavigate();

  // Fetch locker entries into LockerTableList
  const initialData = [];
  // State variables for success messages after CRUD operations

  const [showModal, setShowModal] = useState(false);
  const [LockerTableList, setLockerTableList] = useState(initialData);
  const [editedTeamID, setEditedTeamID] = useState('');
  const [employeeListData, setEmployeeListData] = useState([]);
  const titleInputRef = useRef(null);   
  const tableRef = useRef(null); // Ref for DataTable instance

  const [inputData, setInputData] = useState({
    title: '',
    link: '',
    description: '',
    uid: '',
    password: '',
    tags: [],
    Staffname: [],
  });

  useEffect(() => {

    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    if (User_Id === null) {
      navigate('/');
    }

    async function fetchData() {
      try {
        const lockerData = await getFromAPI('locker/')
        // Retrieve the logged-in user's employee ID from localStorage
        const employeeId = localStorage.getItem('employee_id');
        const LockerDataforUser = [];
        for (var iCount = 0; iCount < lockerData.length; iCount++)
        {
            var bValid = false;
            lockerData[iCount].Staffname.split(",").map(employee_Id => {
              if (employee_Id === employeeId) bValid = true;
            })
            if (bValid) LockerDataforUser.push(lockerData[iCount]);
        }
        setLockerTableList(LockerDataforUser)

        const employeeData = await getFromAPI('employee/');
        const updatedEmployeeData = employeeData.map(employee =>({
         value : employee.employee_id,
         label : `${employee.first_name} ${employee.last_name}`,
        }));
        setEmployeeListData(updatedEmployeeData)        
      } catch (error) {
        console.error('Error fetching team assign data:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    // Initialize or update DataTable when milestoneData changes
    if (tableRef.current) {
      // If DataTable has already been initialized, destroy it first
      tableRef.current.clear().destroy();
    }
  
    // Initialize DataTable
    const dataTableInstance = $('.maindatatable').DataTable({
      dom: 'flrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search...',
        paginate: {
          previous: '«',
          next: '»',
        },
      },
      data: LockerTableList, // Add milestoneData directly to the DataTable
      columns: [
        { data: null,  render: function (data, type, row, meta) { return meta.row + 1; } },
        { 
          data: null,
          render: function (data, type, row) {
            return `
            <span className="pointer-cursor" data-id=${row} data-action="title"}>
             ${row.title}
          </span>
            `;
          }
        },
        { 
          data: null,
          render: function (data, type, row) {
            return `
            <a href={${row.link}} target='blank'>${row.link}
            `;
          }
        },
        { 
          data: null,
          render: function (data, type, row) {
            const tags = row.tags ? row.tags.split(',') : []; // Split tags into an array if they exist
            const badgeElements = tags.map((tag, index) => (
              `<span key=${index} class="badge bg-primary text-white me-1">${tag.trim()}</span>`
            ));
            return badgeElements.join(' '); // Join badge elements into a string and return
          }
        },
        { data: 'description' },
        {
          data: 'password',
          render: function (data, type, row) {
              const asterisks = '*'.repeat(data.length); // Replace password with asterisks
              return `<span class="password-tooltip" data-original="${data}">${asterisks}</span>`;
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return `
              <div class="d-flex">
                <button class="btn btn-primary table-btn me-2" data-id='${JSON.stringify(row)}' data-action="edit"><i class="bi bi-pencil-square"></i></button>
                <button class="btn btn-danger table-btn" data-id='${JSON.stringify(row)}' data-action="delete"><i class="bi bi-trash3"></i></button>
              </div>
            `;
          }
        }
      ]
    });
  
    // Store the DataTable instance in the ref
    tableRef.current = dataTableInstance;

    $(document).ready(function() {
      $(document).on('mouseenter', '.password-tooltip', function() {
          // Show original password when hovering
          $(this).attr('title', $(this).attr('data-original'));
      }).on('mouseleave', '.password-tooltip', function() {
          // Hide original password when not hovering
          $(this).attr('title', '');
      });
    });
    
    // Attach event listeners to handle edit and delete actions
    $(document.body).on('click', '.btn.table-btn', function() {
      const action = $(this).data('action');
      const rowData = $(this).data('id');
      if (action === 'edit') {
        handleEditClick(rowData);
      } else if (action === 'delete') {
        handleDeleteLocker(rowData.id);
      } else if (action === 'title') {
        showLockerInfo(rowData.id);
      }
    });
  }, [LockerTableList, employeeListData]);
  
  const handleCloseModal = () => {
    setShowModal(false);
    setInputData({
      title: '',
      description: '',
      tags: [],
      uid: '',
      link: '',
      password: '',
      Staffname: [],
    })
    setShowModal(false);
    setEditedTeamID(''); // Reset editedTeamID

  };

  const handleShowModal = ()=> {
    setShowModal(true);
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }

  };
    // Post the locker details
    const handleAddLocker = useCallback (async () => {
      
      const isDuplicate = LockerTableList.some(
        (locker) => locker.title === inputData.title 
      );
      if (isDuplicate) {
        toast.error("Duplicate Locker name. Please choose a different Locker name.", 'error');
        return;
      }
      try{
      const data = await postToAPI("locker/", {
        title: inputData.title,
        link: inputData.link,
        tags: inputData.tags.map(tag => tag.text).join(','), // Convert tags array to string
        description: inputData.description,
        uid: inputData.uid,
        password: inputData.password,
        Staffname: inputData.Staffname.map(employee => employee.value).join(','),
      });

      //for save the employee name as assign staff in drop down
      if (data.status) {
        fetchLockerData();
        toast.success('Locker added successfully!', 'success');
      //  handleCloseModal();
      } else {
          if (data.error && data.error.includes('duplicate')) {
            toast.error('Locker entry. Please provide a unique Locker name.', 'error');
          } else {
            toast.error('Some Error Occurred...', 'error');
          }
        }
      setEditedTeamID('');
    } catch (error) {
        console.error('Error adding Locker data:', error);
        showAlert(error.message, 'error');
    }
    },[LockerTableList , inputData]);   

    // function for the updateing data add & edit time in database 
    const updateDataInDatabase = useCallback (async(itemDetails) => {
      const updateUrl = `locker/${editedTeamID}/`;
        if(!itemDetails.title || !itemDetails.link || !itemDetails.tags || !itemDetails.password || !itemDetails.uid ||!itemDetails.Staffname||!itemDetails.description){
          toast.error('All Fields Are Requires','error')
        }
        const requestBody = {
          title: itemDetails.title || null,
          description: itemDetails.description || null,
          link: itemDetails.link || null,
          password: itemDetails.password || null,
          uid: itemDetails.uid || null,
          tags: itemDetails.tags ? itemDetails.tags.map(tag => tag.text).join(',') : null, // Convert tags array to string
          Staffname: itemDetails.Staffname ?itemDetails.Staffname.map(employee => employee.value).join(','):null,
        };

        try{
          const data = await putToAPI(updateUrl,requestBody);
            if(data.message === 'Item updated successfully'){
              fetchLockerData();
              toast.success('Locker updated successfully!', 'success');
            }else {
              console.error('Unexpected response:', data.message);
            }
          } catch (error) {
              // Handle network or other errors
              console.error('Error updating Locker::', error);
              toast.warn('Locker Name Already Exist Choose Different Locker Name', 'error');
          }
          setEditedTeamID('')
     },[LockerTableList , editedTeamID ,inputData]);

    // function for the updateing modal content while edit time 
    const UpdatedModalContent = (data) => {

      const preselectedTags = (data.tags || '').split(",").map((tag, index) => ({ id: String(index), text: tag }));   // Set the tag in editform 
      const preselectedEmployees = (data.Staffname || '').split(',').filter(id => id.trim() !== '').map(employeeId =>{  // Set the employee in edit form
        const parseId = parseInt(employeeId , 10);
        const employee = employeeListData.find(emp => emp.value === parseId);
        if (employee) {
          return { value: parseId, label: employee.label };
        }
        return null;
      }).filter(Boolean);
    
      setInputData({
        title: data.title,
        link: data.link,
        uid: data.uid,
        password: data.password,
        tags: preselectedTags,
        Staffname: preselectedEmployees,
        description: data.description,
      });
    };
  
  // Handle edit button click
  const handleEditClick = (team) => {
    
    setEditedTeamID(team.id);
    UpdatedModalContent(team);
    handleShowModal();
  };
  
 // Handle Delete Locker Entry
  const handleDeleteLocker = async (Id) => {
    try {
      showAlert('Are you sure you want to delete this Locker?', 'confirm', (result) => {
        if (result) {
          // User confirmed the deletion
          deleteLocker(Id);
        } else {
          // User canceled the deletion
          // You can handle this case if needed
        }
      });
    } catch (error) {
      console.error('Error deleting Locker:', error);
      toast.error('Error deleting Locker', 'error');
    }
  };

  //Function for the deleteing locker fromt the database 
  const deleteLocker = async (Id) => {
    try {
      const response = await deleteFromAPI(`locker/${Id}/`);
      if (response.status) {
        fetchLockerData();
        toast.success('Locker deleted successfully!', 'success');
      } else {
        showAlert('Error deleting Locker', 'error');
      }
    } catch (error) {
      console.error('Error deleting Locker:', error);
      toast.error('Error deleting Locker', 'error');
    }
  };

  // Handle Select Change for employee selection
  const handleSelectChange = useCallback((selectedOptions) => {
    setInputData((inputData) => ({
      ...inputData,
      Staffname: selectedOptions,
    }));
  },[]);

    // Handle both Add and Update actions
    const handleBothActions = () => {

      if (!inputData.title.trim()) {
        toast.error('Please add proper title', 'error');
        return;
      }

      if (!inputData.link.trim()) {
        toast.error('Please add proper link', 'error');
        return;
      }

      if (!inputData.uid.trim()) {
        toast.error('Please add proper Uid', 'error');
        return;
      }

      if (!inputData.password) {
        toast.error('Please add proper Password', 'error');
        return;
      } else if (!inputData.password.trim()) {
        toast.error('Only space is not allowed for password', 'error');
        return;
      }


      if (!inputData.description) {
        toast.error('Please add proper Description', 'error');
        return;
      }

      if (inputData.tags.length === 0 ) {
        toast.error('Please add minimum one tag', 'error');
        return;
      }

      if (inputData.Staffname.length === 0) {
        toast.error('Please add minimum one staff', 'error');
        return;
      }


      const isDuplicate = LockerTableList.some(
        (locker) => (locker.title === inputData.title) && (locker.id !== editedTeamID));
      if (isDuplicate) {
        toast.warn("Locker Name Already Exist Choose Different Locker Name.", 'error');
        return;
      }
    
      if(editedTeamID === ''){
        handleAddLocker();
        handleCloseModal();
      }
      
      else {
      // For editing an existing team
      if (!inputData.title || !inputData.Staffname.length || !inputData.tags.length || !inputData.link || !inputData.password || !inputData.uid || !inputData.description) {
        toast.error('All fields are required', 'error');
      }
      else {
        updateDataInDatabase(inputData);
        handleCloseModal();
      } 
    }
    };

    // Set Reset the Add Locker after adding the entry.
    const resetInputData = () => {
      setInputData({
      title: '',
      link: '',
      password: '',
      tags: [],
     Staffname: [],
      uid: '',
      });
    };

    // Function to handle adding a department
    const ADDRole = () => {
      resetInputData(); // Reset input data when opening the modal for adding
      handleShowModal(); // Show the modal
    };

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const handleCloseModalTwo = () => setShowModalTwo(false);
  const handleShowModalTwo = () => setShowModalTwo(true);
 
 // Show the locker information 
  const showLockerInfo = (item) => {
    setSelectedItem(item);
    handleShowModalTwo();
  };

  const Link = ({ children, title }) => (
    <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
      <a href="#">{children}</a>
    </OverlayTrigger>
  );
  // Function to fetch updated locker data
  const fetchLockerData = async () => {
    try {
      const response = await getFromAPI("locker/");
      // Retrieve the logged-in user's employee ID from localStorage
      const employeeId = localStorage.getItem('employee_id');
      const LockerDataforUser = [];
      for (var iCount = 0; iCount < response.length; iCount++)
      {
          var bValid = false;
          response[iCount].Staffname.split(",").map(employee_Id => {
            if (employee_Id === employeeId) bValid = true;
          })
          if (bValid) LockerDataforUser.push(response[iCount]);
      }
      setLockerTableList(LockerDataforUser)
    } catch (error) {
      console.error("Error while fetching the data:", error);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false); // New state for showing password tooltip
  const handlePasswordMouseEnter = (id) => {
    setShowPasswordTooltip((prev) => ({ ...prev, [id]: true })); // Set showPasswordTooltip for this row to true
  };

  const handlePasswordMouseLeave = (id) => {
    setShowPasswordTooltip((prev) => ({ ...prev, [id]: false })); // Set showPasswordTooltip for this row to false
  };

  // Handle data change
  const handleChange = (e) => {
    if (e.target.name === "tags") {
      setInputData((prevData) => ({
        ...prevData,
        tags: e.target.value,
      }));
    } else {
      setInputData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }));
    }
  };

 // Handle for Tag remove
  const handleTagDelete = (index) => {
    setInputData((prevData) => ({
      ...prevData,
      tags: prevData.tags.filter((tag, i) => i !== index),
    }));
  };
  
  // Handle Tag Addition
  const handleTagAddition = (tag) => {
    if (tag && tag.text) {
      setInputData((prevData) => ({
        ...prevData,
        tags: [...prevData.tags, tag],
      }));
    } else {
      console.error("Invalid tag object:", tag);
    }
  };
  
  // Handle Tag Change
  const handleTagChange = (tags) => {
    setInputData((inputData) => ({
      ...inputData,
      tags: tags.map((tag, index) => ({ id: String(tag.id) || index, text: tag.text })),
    }));
  };
  
  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Locker" />
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-lg-flex justify-content-between align-items-center mb-3">
                      <Card.Title className='mb-lg-0'>Locker List</Card.Title>
                      <div className="d-md-flex">
                       <Button variant="soft-info mb-1 mb-md-0" className="btn-icon" onClick={() => ADDRole()}>
                          <i className="bi bi-plus"></i>
                        </Button>

                      </div>
                    </div>

                    <Table responsive bordered className="mb-0 table-nowrap w-100 maindatatable">
                      <thead className="table-light">
                        <tr>
                          <th>Sr.No.</th>
                          <th>Title</th>
                          <th>Link</th>
                          <th>Tag</th>
                          <th>Description</th>
                          <th>Password</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />

        <Modal id="AddLockerModal" centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-lg"><Modal.Header className="border-0" closeButton>
          <Modal.Title>{editedTeamID ? 'Edit Locker' : 'Add New Locker' }</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={12}>
                  <Form.Group className="custom-fr-group">
                    <Form.Label>Title</Form.Label>

                    <Form.Control
                      ref={titleInputRef}
                      type="text"
                      placeholder="Enter title..."
                      required
                      name="title"
                      id="title"
                      value={inputData.title}
                      onChange={handleChange} 
                      
                      />
                  </Form.Group>
                  <Form.Group className="custom-fr-group">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter link..."
                      required
                      name="link"
                      id="link"
                      value={inputData.link}
                      onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="custom-fr-group">
                    <Form.Label>User ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter user id..."
                      required
                      name="uid"
                      id="uid"
                      value={inputData.uid}
                      onChange={handleChange} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="custom-fr-group">
                    <Form.Label>Password</Form.Label>
                    <div className="input-icon-content">
                      <i className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"} input-icon`}
                        onMouseDown={() => setShowPassword(true)}
                        onMouseUp={() => setShowPassword(false)}
                        onMouseOut={() => setShowPassword(false)}
                        onTouchStart={() => setShowPassword(true)}
                        onTouchEnd={() => setShowPassword(false)}
                      ></i>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password..."
                        required
                        name='password'
                        id='password'
                        value={inputData.password}
                        onChange={handleChange} />
                    </div>
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="custom-fr-group">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Enter description..."
                      required
                      name="description"
                      id="description"
                      value={inputData.description}
                      onChange={handleChange} />
                  </Form.Group>
                  <Form.Group className="custom-fr-group">
                    <Form.Label>Tag</Form.Label>
                     {/*Set the React Tag*/}
                    <ReactTags    
                        //className="form-control"
                        tags={inputData.tags}
                        handleDelete={(index) => handleTagDelete(index)}
                        handleAddition={(tag) => handleTagAddition(tag)}
                        name="tags"
                        isMulti={true}  
                        allowDeleteFromEmptyInput={false} // Prevent deletion when input field is empty
                        placeholder={"Add tags and press enter .."}
                        onChange = {handleTagChange}
                      />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="custom-fr-group">
                    <Form.Label>Assign to Staff</Form.Label>
                        <Select
                          options={employeeListData}
                          placeholder="Select Staff..."
                          name="Staffname"
                          onChange={handleSelectChange}
                          isMulti={true} // Ensure isMulti is set to true
                          value={inputData.Staffname}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleBothActions}>
              Ok
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal centered show={showModalTwo} onHide={handleCloseModalTwo} dialogClassName="modal-lg">
          <Modal.Header className="border-0" closeButton>
            <Modal.Title>Locker Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedItem && (
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Title</Form.Label>
                      <Form.Control type="text" name="LockerInfoTitle" id="LockerInfoTitle" value={selectedItem.title} readOnly />
                    </Form.Group>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Link</Form.Label>
                      <Form.Control type="text" name="LockerInfoLink" id="LockerInfoLink" value={selectedItem.link} readOnly />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>User ID</Form.Label>
                      <Form.Control type="text" name="LockerInfoUserId" id="LockerInfoUserId" value={selectedItem.uid} readOnly />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Password</Form.Label>
                      <div className="input-icon-content">
                        <i className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"} input-icon`}
                          onMouseDown={() => setShowPassword(true)}
                          onMouseUp={() => setShowPassword(false)}
                          onMouseOut={() => setShowPassword(false)}
                          onTouchStart={() => setShowPassword(true)}
                          onTouchEnd={() => setShowPassword(false)}
                        ></i>
                        <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter Password..." required name='LockerPassword' id='LockerPassword' value={selectedItem.password} readOnly />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" rows={4} name="LockerInfoDescription" id="LockerInfoDescription" value={selectedItem.description} readOnly />
                    </Form.Group>
                    <Form.Group className="custom-fr-group">
                      <Form.Label>Tag</Form.Label>
                      <Form.Control type="text" name="LockerInfoTag" id="LockerInfoTag" value={selectedItem.tags} readOnly />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                  <Form.Group className="custom-form-input">
                        <Form.Label>Team Assign</Form.Label>
                        <Select
                          options={employeeListData}
                          placeholder="Select Staff..."
                          name="Staffname"
                          onChange={handleSelectChange}
                          value={selectedItem.Staffname} // Pass selectedItem.Staffname as the value
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Modal.Body>         
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModalTwo}>Close</Button>
          </Modal.Footer>
        </Modal>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
      </div>
    </>
  )
}
