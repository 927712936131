import React, { createContext, useContext, useState, useEffect } from "react";

const DateFormatContext = createContext();

export const useDateFormat = () => useContext(DateFormatContext);

export const DateFormatProvider = ({ children }) => {
  const [selectedFormat, setSelectedFormat] = useState(() => {
    // Get the selected format from localStorage, default to 'YYYY-MM-DD'
    return localStorage.getItem('selectedDateFormat') || 'YYYY-MM-DD';
  });

  const updateSelectedFormat = (format) => {
    setSelectedFormat(format);
    // Store the selected format in localStorage
    localStorage.setItem('selectedDateFormat', format);
  };

  return (
    <DateFormatContext.Provider value={{ selectedFormat, updateSelectedFormat }}>
      {children}
    </DateFormatContext.Provider>
  );
};
