const statusNumber = 65;
var charts = {
    progressChart: {
        series: [statusNumber],

        options: {
            chart: {
                type: "radialBar",
                OffsetY: -20,
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: "70%",
                        background: "#fff",
                        position: "front",

                        dropShadow: {
                            enabled: true,
                            top: 0,
                            left: 0,
                            blur: 4,
                            opacity: 0.24
                        }

                    },

                    track: {
                        margin: -5, // margin is in pixels
                        strokeWidth: "100%",
                        background: "#fff",
                        border: true,
                        dropShadow: {
                            enabled: true,
                            top: 0,
                            left: 0,
                            right: 5,
                            blur: 0,
                        }
                    },
                    dataLabels: {
                        showOn: "always",
                        value: {
                            color: "#3C5ED4",
                            fontSize: "38px",
                            fontWeight: "bold",
                            show: true,
                            offsetY: -10,
                        },
                        name: {
                            offsetY: 30,
                            show: true,
                            fontsize: "130px",
                            color: "#000",
                        },
                    },
                },
            },
            labels: ["Completed"],

            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    shadeIntensity: 0.5,
                    gradientToColors: ["#14C1A2"],
                    stops: [0, 100],
                },
            },
        },
    },
};
export default charts;