import React, { useState, useEffect, useRef ,useCallback } from "react";
import { Container, Row, Col, Breadcrumb, Card, Button, Badge, Table, Modal, Form , Tooltip , OverlayTrigger} from "react-bootstrap";
import Footer from "../layout/Footer";
import TopBar from "../layout/TopBar.js";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
//Data-table
import JSZip, { forEach } from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import ProgressBar from "react-bootstrap/ProgressBar";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDateFormat } from "../../context/DateFormatContext.js";

import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert, sendNotification } from '../Utils/utils.js'; // Import utility functions
import { toast, ToastContainer } from "react-toastify";
import { Navigate } from "react-router-dom";
import user from "../../images/user1.png";
export default function Dashboard() {

    // This function runs when the component mounts
    useEffect (() =>{
        // It sets the document title to 'Dashboard | PMRS App'
        document.title = 'Dashboard | PMRS App';
        // This return statement defines a cleanup function
        return ()=> {
        // This function runs when the component unmounts
        document.title = 'PMRS App';
        // It resets the document title back to 'PMRS App'
        };
      },[]);

    const [AdminProjectData, SetAdminProjectData] = useState([]);
    const [totalProjects, setTotalProjects] = useState(0);
    const [uatTestingProjects, setUatTestingProjects] = useState(0);
    const [inProgressProjects, setInProgressProjects] = useState(0);
    const [completedProjects, setCompletedProjects] = useState(0);
    const initialData = [];
    const [employeeListData, setEmployeeListData] = useState(initialData);
    const [Project_status, setProject_status] = useState(initialData);
    const { selectedFormat } = useDateFormat();
    const tableRef = useRef(null);
    const [oldprojectname ,setOldProjectName]=useState();
    const [oldprojectmember ,setOldProjectMember]= useState();
    const [oldprojectstatus ,setOldProjectStatus]=useState();

    // Inside your functional component
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    // State variables for showing and closing modal dialogs
    const [showModalOne, setShowModalOne] = useState(false);
    // setted values to null when closing the modal
  const handleCloseModalOne = () => {
    setShowModalOne(false);
    setInputData({
      projectname: '',
      project_type: '',
      projectstatus: '',
      startdate: '',
      enddate: '',
      project_type: '',
      project_price: '',
      employee_select: [],
      reason_for_hold: '', // Add Field reason for Hold
    })
    setShowModalOne(false);
    setEditedTeamID(''); // Reset editedTeamID

  };
    const handleShowModalOne = () => setShowModalOne(true);
    const [refreshFlag, setRefreshFlag] = useState(false); // State variable for refresh flag
    // map to store id of field which need to be edited
    const [editedTeamID, setEditedTeamID] = useState('');
    const navigate = useNavigate();

// Initialize DataTable on milestoneData change
useEffect(() => {
    // Initialize or update DataTable when milestoneData changes
    if (tableRef.current) {
      // If DataTable has already been initialized, destroy it first
      tableRef.current.clear().destroy();
    }

    // Initialize DataTable
    const dataTableInstance = $('.maindatatable').DataTable({
      dom: 'flrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search...',
        paginate: {
          previous: '«',
          next: '»',
        },
      },
      data: AdminProjectData,
       // Add milestoneData directly to the DataTable
      columns: [
        { data: 'projectname',
        render: function (data, type, row) {
          const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
          const isHold = row.projectstatus === "On Hold";
          const isDrop = row.projectstatus === "Project Drop";
          let badgeClass = "";
          if (isHold) {
            badgeClass = " text-warning";
          } else if (isDrop) {
            badgeClass = " text-orange";
          } else if (isPastDueAndNotCompleted) {
            badgeClass = " text-danger";
          }
          return (
            `<span class="${badgeClass}">${data}</span>`
          );}
      },
        {
          data: null,
          render: function (data, type, row) {
            const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
            const isHold = row.projectstatus === "On Hold";
            const isDrop = row.projectstatus === "Project Drop";
            const returnvalue = formatDate(row.startdate) + ' To ' + formatDate(row.enddate);
            let badgeClass = "";
            if (isHold) {
              badgeClass = " text-warning";
            } else if (isDrop) {
              badgeClass = " text-orange";
            } else if (isPastDueAndNotCompleted) {
              badgeClass = " text-danger";
            }
            return (
              `<span class="${badgeClass}">${returnvalue}</span>`
            );}
        },
        {
          data: null,
          render: function (data, type, row) {
            const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
            const isHold = row.projectstatus === "On Hold";
            const isDrop = row.projectstatus === "Project Drop";
            const returnvalue = szcalculateDuration(convertToYYYYMMDD(row.startdate), convertToYYYYMMDD(row.enddate));
            let badgeClass = "";
            if (isHold) {
              badgeClass = " text-warning";
            } else if (isDrop) {
              badgeClass = " text-orange";
            } else if (isPastDueAndNotCompleted) {
              badgeClass = " text-danger";
            }
            return (
              `<span class="${badgeClass}">${returnvalue}</span>`
            );}
        },
        {
          data: 'project_type',
          render: function (data, type, row) {
            const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
            const isHold = row.projectstatus === "On Hold";
            const isDrop = row.projectstatus === "Project Drop";
            let badgeClass = "";
            if (isHold) {
              badgeClass = " text-warning";
            } else if (isDrop) {
              badgeClass = " text-orange";
            } else if (isPastDueAndNotCompleted) {
              badgeClass = " text-danger";
            }
            return (
              `<span class="${badgeClass}">${data}</span>`
            );}
        },
        {
          data: 'employee_select',
          render: function (data, type, row) {
            const ids = data.split(',');
            let imgHTML = '';
            // Loop through each employee ID
            ids.forEach(employeeId => {
                // Find the employee object with the corresponding ID
                const employee = employeeListData.find(emp => emp.value === parseInt(employeeId, 10));
                // Check if the employee object is found
                if (employee) {
                    // Check if the profileImage attribute exists
                    const profileImage = employee.profileImage ? employee.profileImage: user;
                    if (profileImage) {
                        // If profileImage exists, display the image wrapped in a div
                        imgHTML += `<img className="auth-img rounded-circle" src="${profileImage}" style="width: 25px; height: 25px; object-fit: cover; border-radius: 50%;" />`;
                    } else {
                        // If profileImage doesn't exist, display a blank circle with grey border wrapped in a div
                        imgHTML += `<img className="auth-img rounded-circle" src="${profileImage}" style="width: 25px; height: 25px; object-fit: cover; border-radius: 50%;" />`;
                    }
                }
            });
            // Return the HTML for displaying profile pictures
            return `<div class="text-dark" style="display: inline-block;">${imgHTML}</div>`;
            }
        },
        {
          data: 'projectstatus',
          render: function (data, type, row) {
            const status = row.projectstatus ? row.projectstatus : null;
            const isPastDueAndNotCompleted = isPastDue(row.enddate) && row.projectstatus !== "Project Completed";
            const isHold = row.projectstatus === "On Hold";
            const isDrop = row.projectstatus === "Project Drop";
            let badgeClass = "badge badge-sm bg-soft-info";
            if (isHold) {
              badgeClass = "badge badge-sm bg-soft-warning";
            } else if (isDrop) {
              badgeClass = "badge badge-sm bg-soft-orange";
            } else if (isPastDueAndNotCompleted) {
              badgeClass = "badge badge-sm bg-soft-danger";
            }
            return (
              `<span class="${badgeClass}">${status}</span>`
            );
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            const progressColor = getProgressColor(row.projectstatus);
            const progressWidth = getProgressWidth(row.projectstatus);
            return `<div class="progress" style= "height : 8px;">
                           <ProgressBar
                                  style="width: ${progressWidth}%; height : 8px;"
                                  class="progressbar-sm bg-${progressColor}"
                                />  
                         </div>
                     `;
          }
        },
        {
          data: null,
          render: function (data, type, row) {
            return `
              <div class="d-flex">
                <button class="btn btn-primary table-btn me-2" data-id='${JSON.stringify(row)}' data-action="edit"><i class="bi bi-pencil-square"></i></button>
              </div>
            `;
          }
        }
      ]
    });

    // Store the DataTable instance in the ref
    tableRef.current = dataTableInstance;
    // Attach event listeners to handle edit and delete actions
    $(document.body).on('click', '.btn.table-btn', function () {
      const action = $(this).data('action');
      const rowData = $(this).data('id');
      if (action === 'edit') {
        handleEditClick(rowData);
      }
    });
  }, [AdminProjectData, employeeListData]);

    // fetch employee name from employee Headerbar
    const { szfullName = localStorage.getItem('First_name') + ' ' + localStorage.getItem('Last_name') } = TopBar();
    const User_Id = localStorage.getItem('employee_id');

    useEffect(() => {
        async function fetchData() {
            try {

                    if(User_Id === null)
                    {
                        navigate('/');
                    }

                    // Fetch employee data
                    const employeeData = await getFromAPI('employee/');
                    const updatedEmployeeListData = employeeData.map(employee => ({
                        value: employee.employee_id,
                        label: `${employee.first_name} ${employee.last_name}`,
                        first_name: employee.first_name,
                        last_name: employee.last_name,
                        profileImage: employee.profile_picture,
                    }));
                    setEmployeeListData(updatedEmployeeListData);

                    // Fetch Project data
                    const ProjectData = await getFromAPI('project/');
                    // Retrieve the logged-in user's employee ID from localStorage
                    const employeeId = localStorage.getItem('employee_id');
                    const projectsForUser = [];

                    ProjectData.map(project => {
                        var bValid = false;
                        project.employee_select?.split(',').map((employee_id, index) => {
                            if (employee_id === employeeId) bValid = true;
                        })
                        if (bValid) projectsForUser.push(project);
                    })

              SetAdminProjectData(projectsForUser);
              // Update card data based on fetched project data
              updateCardData(projectsForUser);
              
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [refreshFlag]);

    // function for the user is admin or not
    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        async function fetchData() {
            try {
                // Fetch employee data
                const employeeData = await getFromAPI("employee/");
                // Check if the logged-in user is admin
                const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
                const loggedInUser = employeeData.find(
                    (employee) => employee.employee_id === loggedInUserId
                );
                if (loggedInUser && loggedInUser.role.toLowerCase() === "admin") {
                    setIsAdmin(true);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, []);

    // function for the dashbaord data filtering by the logged in person 
    async function fetchDashBoardData() {
        const ProjectData = await getFromAPI('project/');
        // Retrieve the logged-in user's employee ID from localStorage
        const employeeId = localStorage.getItem('employee_id');
        const projectsForUser = [];
        ProjectData.map(project => {
            var bValid = false;
            project.employee_select?.split(',').map((employee_id, index) => {
                if (employee_id === employeeId) bValid = true;
            })
            if (bValid) projectsForUser.push(project);
        })
        SetAdminProjectData(projectsForUser);
        // Update card data based on fetched project data
        updateCardData(projectsForUser);
    };

    // Function to update card data based on project data
    const updateCardData = (projectsData) => {
        const visibleRowCount = projectsData.length;
        setTotalProjects(visibleRowCount);
        const uatTestingProjectsCount = projectsData.filter(project => project.projectstatus === "In UAT Phase").length;
        setUatTestingProjects(uatTestingProjectsCount);
        const completedProjectsCount = projectsData.filter(project => project.projectstatus === "Project Completed").length;
        setCompletedProjects(completedProjectsCount);
        const inProgressProjectsCount = visibleRowCount - (completedProjectsCount);
        setInProgressProjects(inProgressProjectsCount);
    };

    // Handle multi-select changes
    const handleSelectChange = (selectedOptions) => {
        setInputData((inputData) => ({
            ...inputData,
            employee_select: selectedOptions,
        }));
    };

    // Temporary disabled untill task module is implemented
    const staticProjectStatusData = [
        {
            project_status_name: "Bug Fixing Phase ", project_status_description: "60%", select_color: "Danger",
        },
        {
            project_status_name: "Invoice Generated", project_status_description: "85%", select_color: "Purple",
        },
        {
            project_status_name: "Waiting for Payment", project_status_description: "90%", select_color: "Dark",
        },
        {
            project_status_name: "Project Completed", project_status_description: "100%", select_color: "Success",
        },
        {
            project_status_name: "Delivered To Customer", project_status_description: "80%", select_color: "Teal",
        },
        {
            project_status_name: "Designing Phase", project_status_description: "10%", select_color: "Light",
        },
        {
            project_status_name: "Development Phase", project_status_description: "45%", select_color: "Warning",
        },
        {
            project_status_name: "In UAT Phase", project_status_description: "70%", select_color: "Orange",
        },
        {
            project_status_name: "On Hold", project_status_description: "-", select_color: "Red",
        },
        {
            project_status_name: "Project Not Assigned to Member", project_status_description: "15%", select_color: "Secondary",
        },
        {
            project_status_name: "Testing Phase", project_status_description: "50%", select_color: "Cyan",
        },
        {
            project_status_name: "UI Development Phase", project_status_description: "30%", select_color: "Primary",
        },
        {
            project_status_name: "Waiting for Customer Response", project_status_description: "75%", select_color: "Pink",
        },
        {
            project_status_name: "Project Drop", project_status_description: "-", select_color: "Purple",
        }
    ];

    // Fetching all project Status data to fill dropdown
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getFromAPI("projectstatus/");
                // Temporary disabled untill task module is implemented
                // setProject_status(response);
                setProject_status(staticProjectStatusData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, []);

    // Map to store input data
    const [inputData, setInputData] = useState({
        ProjectName: "",
        ProjectDescription: "",
        ProjectStatus: "",
        StartDate: "",
        EndDate: "",
        project_type: "",
        project_price: "",
        employee_select: [],
        reason_for_hold: "", // field reason for hold
    });

    // For adding values to the database
    const OnAddingProject = useCallback(async (e) => {
        try {
            // Attempt to make the API request
            const response = await postToAPI("project/", {
                projectname: inputData.ProjectName,
                projectdescription: inputData.ProjectDescription,
                projectstatus: inputData.ProjectStatus,
                startdate: inputData.StartDate,
                enddate: inputData.EndDate,
                project_type: inputData.project_type,
                project_price: inputData.project_price,
                employee_select: inputData.employee_select.map(employee => employee.value).join(','),
                reason_for_hold : inputData.reason_for_hold // field reason for hold
            });
            // Check if the API request was successful
            if (response.status) {
                fetchDashBoardData();
                // Show a success message
                toast.success('Admin project added successfully!', 'success');
            } else {
                showAlert("Some Error Occurred...", "error");
            }
        } catch (error) {
            // Handle the error by updating the UI with an error message
            toast.error("Project Name  Allowed Exist Choose Different Name" + error.message, "error");
        }
    }, []);

    // function for edit values in the database 
    const OnEditProject = async (itemId) => {
        try {
            const data = await getFromAPI(`project/${itemId}/`);

            const jsonData = JSON.parse(data.data);
            if (data.status) {
                fetchDashBoardData();
                setEditedTeamID((prevData) => ({ ...prevData, ProjectID: itemId })); // Correcting ProjectID assignmen               
                updateModalContent(jsonData[0].fields);
            } else {
                showAlert('Some Error Occurred...', 'error');
            }
        } catch (error) {
            console.error('Error fetching Project data for editing:', error);
            showAlert('Error fetching Project data for editing', 'error');
        }
    };

    // for updating values to database
    async function updateDataInDatabase(itemDetails) {
        const updateUrl = `project/${editedTeamID}/`;
        const formattedStartDate = convertToYYYYMMDD(inputData.StartDate);
        const formattedEndDate = convertToYYYYMMDD(inputData.EndDate)
        const requestBody = {
            projectname: itemDetails.ProjectName || null,
            projectdescription: itemDetails.ProjectDescription || null,
            projectstatus: itemDetails.ProjectStatus || null,
            startdate: formattedStartDate || null,
            enddate: formattedEndDate || null,
            project_type: itemDetails.project_type || null,
            project_price: itemDetails.project_price || null,
            employee_select: itemDetails.employee_select ? itemDetails.employee_select.map(employee => employee.value).join(',') : null,
            reason_for_hold : itemDetails.reason_for_hold || null,
        };
        try {
            // Make a PUT request to update the data
            const data = await putToAPI(updateUrl, requestBody);
            if (data.message === "Item updated successfully") {
              const updatedProjectData = data.data;
              const User_Id = localStorage.getItem("employee_id");
              const Employee_projects = updatedProjectData.filter((project) =>
                project.employee_select.split(",").includes(User_Id)
              );
              SetAdminProjectData(Employee_projects);
              toast.success("Admin Project Updated Succesffully", "success");
              // Constructing the notification message dynamically
              const creation_time = new Date().toISOString();
              const type = "project";
              // Notification for project name
              itemDetails.employee_select.forEach(async (employee) => {
                // Notification for name change
                if (oldprojectname !== itemDetails.ProjectName) {
                  const message = `The project name ${oldprojectname} has been updated to ${itemDetails.ProjectName}`;
                  await sendNotification(
                    "notification/",
                    type,
                    message,
                    creation_time,
                    employee.value,
                    false
                  );
                }
              });
              // Notification for project status
              itemDetails.employee_select.forEach(async (employee) => {
                // Notification for name change
                console.log(oldprojectstatus);
                if (oldprojectstatus !== itemDetails.ProjectStatus) {
                  const message = `The project name ${itemDetails.ProjectName} has updated its status to ${itemDetails.ProjectStatus}`;
                  await sendNotification(
                    "notification/",
                    type,
                    message,
                    creation_time,
                    employee.value,
                    false
                  );
                }
              });

              //Notification for Start date change
              itemDetails.employee_select.forEach(async (employee) => {
                if (
                  itemDetails.StartDate &&
                  itemDetails.StartDate !== formattedStartDate
                ) {
                  const message = `Start date of ${itemDetails.ProjectName} has been updated to ${formattedStartDate}`;
                  await sendNotification(
                    "notification/",
                    type,
                    message,
                    creation_time,
                    employee.value,
                    false
                  );
                }
              });
              //Notification for End date change
              itemDetails.employee_select.forEach(async (employee) => {
                if (
                  itemDetails.EndDate &&
                  itemDetails.EndDate !== formattedEndDate
                ) {
                  const message = `End date of ${itemDetails.ProjectName} has been updated to ${formattedEndDate}`;
                  await sendNotification(
                    "notification/",
                    type,
                    message,
                    creation_time,
                    employee.value,
                    false
                  );
                }
              });
              //Notification for project ends today or tomorrow
              itemDetails.employee_select.forEach(async (employee) => {
                if (
                  itemDetails.EndDate &&
                  itemDetails.EndDate !== formattedEndDate
                ) {
                  const today = new Date();
                  const endDate = new Date(itemDetails.EndDate);
                  const timeDifference = endDate.getTime() - today.getTime();
                  const daysDifference = Math.ceil(
                    timeDifference / (1000 * 60 * 60 * 24)
                  );

                  if (daysDifference === 1) {
                    const message = `${itemDetails.ProjectName} will end tomorrow`;
                    await sendNotification(
                      "notification/",
                      type,
                      message,
                      creation_time,
                      employee.value,
                      false
                    );
                  } else if (daysDifference === 0) {
                    const message = `${itemDetails.ProjectName} will end today`;
                    await sendNotification(
                      "notification/",
                      type,
                      message,
                      creation_time,
                      employee.value,
                      false
                    );
                  }
                }
              });

              // Find newly added members
              const oldMembers = oldprojectmember
                .split(",")
                .map((member) => member.trim());
              const newMembers = itemDetails.employee_select.map((employee) =>
                String(employee.value, false)
              );
              const newlyAddedMembers = newMembers.filter(
                (member) => !oldMembers.includes(member)
              );

              // Find removed members
              const removedMembers = [];
              oldMembers.forEach((member) => {
                if (!newMembers.includes(String(member))) {
                  removedMembers.push(member);
                }
              });
              // Check if both newly added members and removed members exist
              if (newlyAddedMembers.length > 0 && removedMembers.length > 0) {
                // Generate names of newly added members
                const newlyAddedMembersNames = newlyAddedMembers
                  .map((employeeId) => {
                    const employee = employeeListData.find(
                      (emp) => emp.value.toString() === employeeId
                    );
                    return employee
                      ? `${employee.first_name} ${employee.last_name}`
                      : "";
                  })
                  .join(", ");

                // Generate names of newly removed members
                const newlyRemovedMembersNames = removedMembers
                  .map((employeeId) => {
                    const employee = employeeListData.find(
                      (emp) => emp.value.toString() === employeeId
                    );
                    return employee
                      ? `${employee.first_name} ${employee.last_name}`
                      : "";
                  })
                  .join(", ");

                // Generate combined notification message
                const message = `${newlyAddedMembersNames} have been added & ${newlyRemovedMembersNames} have been removed from project ${itemDetails.ProjectName}`;

                // Send combined notification to newMembers
                newMembers.forEach(async (employeeId) => {
                  await sendNotification(
                    "notification/",
                    type,
                    message,
                    creation_time,
                    employeeId,
                    false
                  );
                });
              } else {
                // Check if there are newly added members
                if (newlyAddedMembers.length > 0) {
                  // Generate names of newly added members
                  const newlyAddedMembersNames = newlyAddedMembers
                    .map((employeeId) => {
                      const employee = employeeListData.find(
                        (emp) => emp.value.toString() === employeeId
                      );
                      return employee
                        ? `${employee.first_name} ${employee.last_name}`
                        : "";
                    })
                    .join(", ");

                  // Notification for old members with newly added member's names
                  oldMembers.forEach(async (employeeId) => {
                    const message = `${newlyAddedMembersNames} have been added to project ${itemDetails.ProjectName}`;
                    await sendNotification(
                      "notification/",
                      type,
                      message,
                      creation_time,
                      employeeId,
                      false
                    );
                  });

                  // Notification for newly added members
                  newlyAddedMembers.forEach(async (employeeId) => {
                    const message = `You have been added to project ${itemDetails.ProjectName}`;
                    await sendNotification(
                      "notification/",
                      type,
                      message,
                      creation_time,
                      employeeId,
                      false
                    );
                  });
                }

                // Check if users have been removed
                if (removedMembers.length > 0) {
                  // Generate names of newly removed members
                  const newlyRemovedMembersNames = removedMembers
                    .map((employeeId) => {
                      const employee = employeeListData.find(
                        (emp) => emp.value.toString() === employeeId
                      );
                      return employee
                        ? `${employee.first_name} ${employee.last_name}`
                        : "";
                    })
                    .join(", ");

                  // Notification for other members with removed member's names
                  newMembers.forEach(async (employeeId) => {
                    const message = `${newlyRemovedMembersNames} have been removed from project ${itemDetails.ProjectName}`;
                    await sendNotification(
                      "notification/",
                      type,
                      message,
                      creation_time,
                      employeeId,
                      false
                    );
                  });

                  // Notification for removed users
                  removedMembers.forEach(async (employeeId) => {
                    const employee = employeeListData.find(
                      (emp) => emp.value === parseInt(employeeId, 10)
                    );
                    if (employee) {
                      const message = `You have been removed from project ${itemDetails.ProjectName}`;
                      await sendNotification(
                        "notification/",
                        type,
                        message,
                        creation_time,
                        employee.value,
                        false
                      );
                    }
                  });
                }
              }
              setEditedTeamID('');
              fetchDashBoardData();
              const visibleRowCount = Employee_projects.length;
              setTotalProjects(visibleRowCount);
              const uatTestingProjectsCount = countProjectsByStatus(
                Employee_projects,
                "In UAT Phase"
              );
              setUatTestingProjects(uatTestingProjectsCount);
              const completedProjectsCount = countProjectsByStatus(
                Employee_projects,
                "Project Completed"
              );
              setCompletedProjects(completedProjectsCount);
              const inProgressProjectsCount =
                visibleRowCount - completedProjectsCount;
              setInProgressProjects(inProgressProjectsCount);
            } else {
                console.error("Unexpected response:", data.message);
            }
        } catch (error) {
            // Handle network or other errors
            console.error('Error updating Project:', error);
            toast.warn('Project Name Already Exists choose different Project Name', 'error');
        }
    }

    function countProjectsByStatus(projects, status) {
        return projects.reduce((count, project) => {
            return project.projectstatus === status ? count + 1 : count;
        }, 0);
    }

    function updateModalContent(itemDetails) {
        let selectedEmployees = [];
        if (itemDetails.employee_select) {
            // Convert comma-separated employee IDs string to an array
            const employeeIds = itemDetails.employee_select.split(',');
            // Map each employee ID to the corresponding employee object
            selectedEmployees = employeeIds.map(employeeId => {
                const employee = employeeListData.find(employee => employee.value === parseInt(employeeId));
                if (employee) {
                    return {
                        value: employee.value,
                        label: `${employee.label} ` // Customize the label if needed
                    };
                }
            });
        }
        fetchDashBoardData();
        const Employee_projects = [];

        const visibleRowCount = Employee_projects.length;
        setTotalProjects(visibleRowCount);

        const uatTestingProjectsCount = Employee_projects.filter(project => project.projectstatus === "In UAT Phase").length;
        setUatTestingProjects(uatTestingProjectsCount);

        const completedProjectsCount = Employee_projects.filter(project => project.projectstatus === 'Project Completed').length;
        setCompletedProjects(completedProjectsCount);

        const inProgressProjectsCount = visibleRowCount - (completedProjectsCount)
        setInProgressProjects(inProgressProjectsCount);

        setInputData({
            ...inputData,
            ProjectName: itemDetails.projectname || "",
            ProjectDescription: itemDetails.projectdescription || "",
            ProjectStatus: itemDetails.projectstatus || "",
            StartDate: itemDetails.startdate || "",
            EndDate: itemDetails.enddate || "",
            project_type: itemDetails.project_type || "",
            project_price: itemDetails.project_price || "",
            employee_select: selectedEmployees || [], // Set the array of selected employees
            reason_for_hold: itemDetails.reason_for_hold || "",
        });
    }

    // Function to handle both adding and editing actions in the modal
    const handleBothActions = async () => {
        try {
            if (!inputData.ProjectName || !inputData.ProjectDescription || !inputData.project_price|| !inputData.StartDate || !inputData.EndDate ||  inputData.employee_select.length === 0 || !inputData.ProjectStatus || !inputData.project_type) {
                toast.error('All fields are required', 'error');
                return;
              }
              //  Set the Alert field for On Hold and Project Drop required
              if((inputData.ProjectStatus === 'On Hold'|| inputData.ProjectStatus === 'Project Drop') && !inputData.reason_for_hold)
                    {  
                        if(inputData.ProjectStatus === 'On Hold'){
                            toast.error("Reason for Hold is required",'error')
                        }
                        if(inputData.ProjectStatus === 'Project Drop'){
                            toast.error("Reason for Project Drop is required",'error')
                        }
                        return;
                    }
              if(inputData.project_price<0)
                    {
                        toast.error("Project Price Should Be Greater Than 0",'error');
                        return
                    }
              if(convertToYYYYMMDD(inputData.StartDate)>convertToYYYYMMDD(inputData.EndDate))
                    {
                        toast.error("End Date Should be greater than Start date",'error')
                        return;
                    }
            if (editedTeamID === '') 
                    {
                        await OnAddingProject();
                        handleCloseModalOne();
                    } else {

                            if (!inputData.ProjectName || !inputData.ProjectDescription || !inputData.project_price|| !inputData.StartDate || !inputData.EndDate ||  inputData.employee_select.length === 0 || !inputData.ProjectStatus || !inputData.project_type) {
                                toast.error('All fields are required', 'error');
                                return;
                            }
                            if(inputData.project_price<0)
                                {
                                    toast.error("Project Price Should Be Greater Than 0",'error');
                                    return
                                }
                            if(convertToYYYYMMDD(inputData.StartDate)>convertToYYYYMMDD(inputData.EndDate))
                                {
                                    toast.error("End Date Should be greater than Start date",'error')
                                    return;
                                }
                            //  Set the Alert field for On Hold and Project Drop required
                            if((inputData.ProjectStatus === 'On Hold'|| inputData.ProjectStatus === 'Project Drop') && !inputData.reason_for_hold)
                                { 
                                    if(inputData.ProjectStatus === 'On Hold')
                                        {
                                            toast.error("Reason for Hold is required",'error')
                                        }
                                if(inputData.ProjectStatus === 'Project Drop')
                                        { 
                                            toast.error("Reason for Project Drop is required",'error')
                                        }return;
                                } else {
                                        await updateDataInDatabase(inputData);
                                        fetchDashBoardData();
                                        handleCloseModalOne();
                                    }              
                         }
                // Toggle refresh flag to trigger data refresh
                setRefreshFlag(prevFlag => !prevFlag);
        } catch (error) {
            console.error('Error:', error);
            // Handle any errors here
        }
    };
    // Handle edit button click
  const handleEditClick = (team) => {
    setEditedTeamID(team.id);
    setOldProjectName(team.projectname);
    setOldProjectMember(team.employee_select);
    setOldProjectStatus(team.projectstatus);
    updateModalContent(team);
    handleShowModalOne();
  };
    // For handling Edit action
    const HandleEditActions = (id) => {
        OnEditProject(id);
        handleShowModalOne();
    };
    // for handling modelone function and the handleeditaction function at same time
    const handleModelwitheditaction = (id) => {
        handleShowModalOne();
        updateModalContent(id)
        HandleEditActions(id);
    }

    const getProgressColor = (status) => {
        switch (status) {
            case "Designing Phase":
                return "light";
            case "Project Not Assigned to Member":
                return "secondary";
            case "UI Development Phase":
                return "primary";
            case "Development Phase":
                return "warning";
            case "Testing Phase":
                return "info";
            case "Bug Fixing Phase":
                return "danger";
            case "In UAT Phase":
                return "orange";
            case "Waiting for Customer Response":
                return "pink";
            case "Delivered To Customer":
                return "teal";
            case "Invoice Generated":
                return "purple";
            case "Waiting for Payment":
                return "dark";
            case "Project Completed":
                return "success";
            case "On Hold":
                return "red";
            case "Project Drop":
                return "purple";    
            default:
                return "info";
        }
    };

    const getProgressWidth = (status) => {
        switch (status) {
            case "Bug Fixing Phase":
                return 60;
              case "Delivered To Customer":
                return 80;
              case "Designing Phase":
                return 10;
              case "Development Phase":
                return 45;
              case "In UAT Phase":
                return 70;
              case "On Hold":
                return 100;
              case "Project Drop":
                return 100;
              case "Project Not Assigned to Member":
                return 15;
              case "Testing Phase":
                return 60;
              case "UI Development Phase":
                return 30;
              case "Waiting for Customer Response":
                return 75;
              case "Project Completed":
                return 100;
              case "Invoice Generated":
                return 85;
              case "Waiting for Payment":
                return 90;
              default:
                return 0; // Default to 0% width if status doesn't match
        }
    };

    // Function to format the date based on the selected format
    const formatDate = (dateString) => {
        // If the dateString is empty or null, return an empty string
        if (!dateString) return '';

        // Create a new Date object with the parts in 'MM-DD-YYYY' format
        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.error(`Invalid date: ${dateString}`);
            return ''; // Return an empty string if the date is invalid
        }

        // Get the date components
        const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
        const year = date.getFullYear();

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

        // Custom formats based on the selected format
        switch (selectedFormat) {
            case 'YYYY-MM-DD':
                // Use custom format for 'YYYY-MM-DD'
                return year + "-" + month + "-" + day;
            case 'DD-MM-YYYY':
                // Use custom format for 'DD-MM-YYYY'
                return day + "-" + month + "-" + year;
            case 'MM-DD-YYYY':
                // Use custom format for 'MM-DD-YYYY'
                return month + "-" + day + "-" + year;
            // Add more cases for additional formats as needed
            default:
                // Default to the standard format
                return date.toLocaleDateString('en-GB', options);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
      
        if (name === "projectstatus" && value !== "On Hold") {
          setInputData((prevData) => ({
            ...prevData,
            reason_for_hold: "", // Clear the reason_for_hold field when changing project status from "On Hold"
            [name]: value,
          }));
        } else {
          setInputData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      };

    const handleChangeDate = (date, fieldName) => {
        setInputData((prevData) => ({
            ...prevData,
            [fieldName]: date, // Update the state with the selected date
        }));
    };

    const isPastDue = (endDate) => {
        const currentDate = new Date();
        const projectEndDate = new Date(endDate);
        return currentDate > projectEndDate;
      };

    // Set function for total days
    const szcalculateDuration = (start, end) => {
        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
        const startDate = new Date(start);
        const endDate = new Date(end);
        const daysDifference = Math.round(Math.abs((startDate - endDate) / oneDay));
        return isNaN(daysDifference) ? '' : daysDifference; // Check if daysDifference is NaN and return an empty string if so
      };
     
      // Function for convert to YYYY-MM-DD form
      const convertToYYYYMMDD = (dateString) => {
        
        // Return if no date is passed
        if (dateString === "") return;

        // Create a new Date object from the input string
        const date = new Date(dateString);
        
        // Check if the input string is a valid date
        if (isNaN(date.getTime())) {
          console.error(`Invalid date: ${dateString}`);
          return ''; // Return an empty string if the date is invalid
        }
        
        // Get the components of the date (year, month, day)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if necessary
        
        // Return the date in yyyy-mm-dd format
        return `${year}-${month}-${day}`;
      };

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <Container>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between align-items-center page-title-content">
                                    <h3 className="page-title">Welcome Back {szfullName}!</h3>
                                    <Breadcrumb className="d-none d-md-block">
                                        <Breadcrumb.Item href="/dashboard">PMRS</Breadcrumb.Item>
                                        <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col className="mb-4" md={6} xxl={3}>
                                <Card className="mb-0 h-100">
                                    <Card.Body>
                                        <div className="d-flex ">
                                            <div className="avatar avatar-xl bg-soft-primary text-primary">
                                                <i className="bi bi-cloud-upload fs-32"></i>
                                            </div>
                                            <div className="ms-3">
                                                <h3 className="text-primary fw-semibold mb-1">{uatTestingProjects}</h3>
                                                <h5 className="mb-0">UAT Project</h5>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="mb-4" md={6} xxl={3}>
                                <Card className="mb-0 h-100">
                                    <Card.Body>
                                        <div className="d-flex ">
                                            <div className="avatar avatar-xl bg-soft-warning text-warning">
                                                <i className="bi bi-graph-up fs-32"></i>
                                            </div>
                                            <div className="ms-3">
                                                <h3 className="text-warning fw-semibold mb-1">{inProgressProjects}</h3>
                                                <h5 className="mb-0">In-Progress Project</h5>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="mb-4" md={6} xxl={3}>
                                <Card className="mb-0 h-100">
                                    <Card.Body>
                                        <div className="d-flex ">
                                            <div className="avatar avatar-xl bg-soft-info text-info">
                                                <i className="bi bi-files fs-32"></i>
                                            </div>
                                            <div className="ms-3">
                                                <h3 className="text-info fw-semibold mb-1">{totalProjects}</h3>
                                                <h5 className="mb-0">Total Project</h5>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className="mb-4" md={6} xxl={3}>
                                <Card className="mb-0 h-100">
                                    <Card.Body>
                                        <div className="d-flex ">
                                            <div className="avatar avatar-xl bg-soft-success text-success">
                                                <i className="bi bi-check2-circle fs-32"></i>
                                            </div>
                                            <div className="ms-3">
                                                <h3 className="text-success fw-semibold mb-1">{completedProjects}</h3>
                                                <h5 className="mb-0">Completed Project</h5>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Projects List</Card.Title>

                                        <Table responsive bordered className="mb-0 table-nowrap w-100 maindatatable" size="sm">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Project Name</th>
                                                    <th>Work Timeline</th>
                                                    <th>Total Days</th>
                                                    <th>Project Types</th>
                                                    <th>Projects Team</th>
                                                    <th>Status</th>
                                                    <th>Progress</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            <Modal
                id="AddProjectModal"
                centered
                show={showModalOne}
                onHide={handleCloseModalOne}
                dialogClassName="modal-lg"
            >
                <Modal.Header className="border-0" closeButton>
                    <Modal.Title> Edit Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col>
                                <Form>
                                    <Col md={12}>
                                        <Form.Group className="custom-fr-group">
                                            <Form.Label>Project Name </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter project name..."
                                                required
                                                name="ProjectName"
                                                id="ProjectName"
                                                value={inputData.ProjectName}

                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="custom-fr-group">
                                            <Form.Label>Project Description</Form.Label>
                                            <Form.Control
                                                placeholder="Enter description..."
                                                as="textarea"
                                                rows={4}
                                                name="ProjectDescription"
                                                id="ProjectDescription"
                                                value={inputData.ProjectDescription}

                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Row>
                                        <Col lg={12} sm={12}>
                                            <Form.Group className="custom-form-input">
                                                <Form.Label>Project Status</Form.Label>
                                                <Form.Select
                                                    name="ProjectStatus"
                                                    id="ProjectStatus"
                                                    value={inputData.ProjectStatus}

                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select  Project Status..</option>
                                                    {Project_status.map((status) => (
                                                        <option key={status.project_status_name} value={status.project_status_name}>
                                                            {status.project_status_name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        {/*Add input field condition for On Hold and Project Drop*/}
                                        {(inputData.ProjectStatus === "On Hold" || inputData.ProjectStatus === "Project Drop") && (
                                            <Form.Group>
                                            <Form.Label>{inputData.ProjectStatus === "On Hold" ? "Reason For Hold" : "Reason For Project Drop"}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name="reason_for_hold"
                                                placeholder={inputData.ProjectStatus === "On Hold" ? "Enter Reason for Hold..." : "Enter Reason for Project Drop..."}
                                                value = {inputData.reason_for_hold}
                                                onChange={handleChange}
                                                required
                                            />
                                            </Form.Group>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col lg={4} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Start Date</Form.Label>
                                                <div className="input-icon-content custom-form-input">
                                                <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('start_Date').click()}></i>
                                                </div>
                                                {isAdmin ? (
                                                <DatePicker
                                                    id = "start_Date"
                                                    selected={inputData.StartDate ? new Date(inputData.StartDate) : null}
                                                    onChange={(date) => handleChangeDate(date, "StartDate")}
                                                    name="StartDate"
                                                    placeholderText="Select start date"
                                                    dateFormat={formatDate(inputData.StartDate)}
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={100} 
                                                />
                                                ) : (
                                                    <DatePicker
                                                    id = "start_date"
                                                    selected={inputData.StartDate ? new Date(inputData.StartDate) : null}
                                                    onChange={(date) => handleChangeDate(date, "StartDate")}
                                                    disabled={true}
                                                    name="StartDate"
                                                    placeholderText="Select start date"
                                                    dateFormat={formatDate(inputData.StartDate)}
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={100} 
                                                /> 
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} sm={12}>
                                            <Form.Group>
                                                <Form.Label>End Date</Form.Label>
                                                <div className="input-icon-content">
                                                <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('end_Date').click()}></i>
                                                </div>
                                                <DatePicker
                                                    id = 'end_Date'
                                                    selected={inputData.EndDate ? new Date(inputData.EndDate) : null}
                                                    onChange={(date) => handleChangeDate(date, "EndDate")}
                                                    name="EndDate"
                                                    placeholderText="Select end date"
                                                    dateFormat={formatDate(inputData.EndDate)}
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={100} 
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} sm={12}>
                                        <Form.Group>
                                          <Form.Label>Total Days</Form.Label>
                                           <Form.Control
                                           type="days"
                                           name="days"
                                           id="days"
                                           placeholder="Total Days"
                                           value={szcalculateDuration(convertToYYYYMMDD(inputData.StartDate), convertToYYYYMMDD(inputData.EndDate))}
                                           onChange={handleChange}
                                           readOnly
                                           />
                                        </Form.Group>
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Project Type</Form.Label>
                                                {isAdmin ? (
                                                    <Form.Select
                                                        name="project_type"
                                                        id="project_type"
                                                        value={inputData.project_type}
                                                        onChange={handleChange}
                                                        >
                                                        <option value="">Select Project type</option>
                                                        <option>Recurring</option>
                                                        <option>Fixed</option>
                                                    </Form.Select>
                                                 ) : (
                                                    <Form.Select
                                                        name="project_type"
                                                        id="project_type"
                                                        value={inputData.project_type}
                                                        disabled={true}
                                                        onChange={handleChange}
                                                        >
                                                        <option value="">Select Project type</option>
                                                        <option>Recurring</option>
                                                        <option>Fixed</option>
                                                    </Form.Select>
                                                )}  
                                            </Form.Group>
                                        </Col>
                                        {/* <Col lg={6} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Project Price </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter project price..."
                                                    required
                                                    name="project_price"
                                                    id="project_price"
                                                    value={inputData.project_price}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col> */}
                                    </Row>
                                    <Col md={12}>
                                        <Form.Group className="custom-form-input">
                                            <Form.Label>Member Assign</Form.Label>
                                            {isAdmin ? (
                                                <Select
                                                    options={employeeListData}
                                                    placeholder="Select employees..."
                                                    name="employee_select"
                                                    onChange={handleSelectChange}
                                                    isMulti={true}
                                                    value={inputData.employee_select}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.value}
                                                />
                                            ) : (
                                                <Select
                                                    options={employeeListData}
                                                    placeholder="Select employees..."
                                                    name="employee_select"
                                                    isDisabled={true} // Disable the Select component
                                                    isMulti={true}
                                                    value={inputData.employee_select}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.value}
                                                />
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer className="">
                    <Button variant="primary" onClick={handleBothActions}>
                        Ok
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModalOne}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />

        </>

    );
}