import React, { useEffect, useState } from "react";
import Select from "react-select";
import PageTitle from "../layout/PageTitle";
import Footer from '../layout/Footer';
import { Form, Button, Card, Container, Row, Col, Modal, Dropdown } from "react-bootstrap";
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert, sendNotification} from '../Utils/utils.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Navigate } from "react-router-dom";

export default function Team() {
  // This function runs when the component mounts
  useEffect(() =>{
    // It sets the document title to 'Team | PMRS App'
    document.title = 'Team | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [teamListData, setTeamListData] = useState([]);
  const [employeeListData, setEmployeeListData] = useState([]);
  const handleShowModal = () => setShowModal(true);
  const [editedTeamID, setEditedTeamID] = useState('');
  const navigate = useNavigate();
  const [inputData, setInputData] = useState({
    team_name: "",
    employee_select: [],
  });
  const [oldteammember ,SetOldTeamMember] = useState([]);
  // Modal open and close functions
  const handleCloseModal = () => {
    setInputData({
      team_name: '',
      employee_select: [],
    });
    setEditedTeamID('');
    setShowModal(false);
  };

  // Fetch data on component mount
  const fetchData = async () => {
    try {

      // Navigate to Login page if no Employee ID Found
      const User_Id = localStorage.getItem('employee_id');
      const isPositionAdmin = localStorage.getItem('Position');
      if (User_Id === null || isPositionAdmin !== 'Admin') {
       navigate('/');
      }

      const [teamData, employeeData] = await Promise.all([
        getFromAPI('team/'),
        getFromAPI('employee/')
      ]);
      setTeamListData(teamData);
      const updatedEmployeeListData = employeeData.map(employee => ({
        value: employee.employee_id,
        label: `${employee.first_name} ${employee.last_name}`,
        first_name: employee.first_name,
        last_name: employee.last_name
      }));
      setEmployeeListData(updatedEmployeeListData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Add team to the database
  const onAdding = async () => {
    try {
      if (!inputData.team_name || !inputData.employee_select.length) {
        toast.error('Please fill in all the fields', 'error');
        return;
      }
      const data = await postToAPI('team/', {
        team_name: inputData.team_name,
        employee_select: inputData.employee_select.map(employee => employee.value).join(','),
      });
      if (data.status) {
        setTeamListData(data.data);
        toast.success('Team added successfully!', 'success');
        handleCloseModal()
        // notification
        const teamName = inputData.team_name;
        const type = "team";
        const creation_time = new Date().toISOString();
        inputData.employee_select.forEach(async (employee) => {
            const message = `You have been added to team ${teamName}`;
            await sendNotification('notification/', type, message,creation_time,employee.value, false);
        });
      } else {
        toast.error('Some Error Occurred...', 'error');
      }
      setEditedTeamID('');
    } catch (error) {
      console.error('Error adding team:', 'error');
    }
  };

  // Update team data in the database
  const updateDataInDatabase = async (itemDetails) => {
      const updateUrl = `team/${editedTeamID}/`;
        if (!itemDetails.team_name || !itemDetails.employee_select) {
          toast.error('All Fields Are Required','error');
          return;
        }
        const requestBody = {
          team_name: itemDetails.team_name || null,
          employee_select: itemDetails.employee_select.map(employee => employee.value).join(',') || null,
        };

        try {
          const data = await putToAPI(updateUrl, requestBody);
          if (data.message === 'Item updated successfully') {
            setTeamListData(data.data);
            toast.success("Team updated successfully!", "success");
            // Find newly added members
            const oldMembers = oldteammember
              .split(",")
              .map((member) => member.trim());
            const newMembers = itemDetails.employee_select.map((employee) =>
              String(employee.value)
            );
            const newlyAddedMembers = newMembers.filter(
              (member) => !oldMembers.includes(member)
            );

            // Find removed members
            const removedMembers = [];
            oldMembers.forEach((member) => {
              if (!newMembers.includes(String(member))) {
                removedMembers.push(member);
              }
            });
            // notification
            const type = "team";
            const creation_time = new Date().toISOString();

            // Check if both newly added members and removed members exist
            if (newlyAddedMembers.length > 0 && removedMembers.length > 0) {
              // Generate names of newly added members
              const newlyAddedMembersNames = newlyAddedMembers.map(employeeId => {
                  const employee = employeeListData.find(emp => emp.value.toString() === employeeId);
                  return employee ? `${employee.first_name} ${employee.last_name}` : '';
              }).join(', ');

              // Generate names of newly removed members
              const newlyRemovedMembersNames = removedMembers.map(employeeId => {
                  const employee = employeeListData.find(emp => emp.value.toString() === employeeId);
                  return employee ? `${employee.first_name} ${employee.last_name}` : '';
              }).join(', ');

              // Generate combined notification message
              const message = `${newlyAddedMembersNames} have been added & ${newlyRemovedMembersNames} have been removed from team ${inputData.team_name}`;

              // Send combined notification to newMembers
              newMembers.forEach(async (employeeId) => {
                  await sendNotification('notification/', type, message, creation_time, employeeId, false);
              });
            } else {
              // Check if there are newly added members
              if (newlyAddedMembers.length > 0) {
                  // Generate names of newly added members
                  const newlyAddedMembersNames = newlyAddedMembers.map(employeeId => {
                      const employee = employeeListData.find(emp => emp.value.toString() === employeeId);
                      return employee ? `${employee.first_name} ${employee.last_name}` : '';
                  }).join(', ');

                  // Notification for old members with newly added member's names
                  oldMembers.forEach(async (employeeId) => {
                      const message = `${newlyAddedMembersNames} have been added to team ${inputData.team_name}`;
                      await sendNotification('notification/', type, message, creation_time, employeeId, false);
                  });

                  // Notification for newly added members
                  newlyAddedMembers.forEach(async (employeeId) => {
                      const message = `You have been added to team ${inputData.team_name}`;
                      await sendNotification('notification/', type, message, creation_time, employeeId, false);
                  });
              }

              // Check if users have been removed
              if (removedMembers.length > 0) {
                  // Generate names of newly removed members
                  const newlyRemovedMembersNames = removedMembers.map(employeeId => {
                      const employee = employeeListData.find(emp => emp.value.toString() === employeeId);
                      return employee ? `${employee.first_name} ${employee.last_name}` : '';
                  }).join(', ');

                  // Notification for other members with removed member's names
                  newMembers.forEach(async (employeeId) => {
                      const message = `${newlyRemovedMembersNames} have been removed from team ${inputData.team_name}`;
                      await sendNotification('notification/', type, message, creation_time, employeeId, false);
                  });

                  // Notification for removed users
                  removedMembers.forEach(async (employeeId) => {
                      const employee = employeeListData.find(emp => emp.value === parseInt(employeeId, 10));
                      if (employee) {
                          const message = `You have been removed from team ${inputData.team_name}`;
                          await sendNotification('notification/', type, message, creation_time, employee.value, false);
                      }
                  });
              }
            }

          } else {
            toast.error('Unexpected response', 'error');
          }
        } catch (error) {
          console.error('Error updating team:', error);
          toast.warn('Team already exists. Please choose a different team name.', 'error');
        }

     setEditedTeamID('');
    };

  // Prepare preselected employees for editing
  const updateModalContent = (data) => {
    const preselectedEmployees = (data.employee_select || '').split(',').filter(id => id.trim() !== '').map(employeeId => {
      const parsedId = parseInt(employeeId, 10);
      const employee = employeeListData.find(emp => emp.value === parsedId);
      return employee ? { value: parsedId, label: employee.label } : null;
    }).filter(Boolean);
    
    setInputData({
      team_name: data.team_name,
      employee_select: preselectedEmployees, // Set all preselected employees
    });
  };

  // Handle multi-select changes
  const handleSelectChange = (selectedOptions) => {
    setInputData(prevInputData => ({
      ...prevInputData,
      employee_select: selectedOptions,
    }));
  };

  // Handle actions when OK button is clicked in the modal
  const handleBothActions = () => {
    if (!inputData.team_name || inputData.team_name.trim() === "") {
      toast.error("Team Name is required!",'error')
      return;
    }
  
    // For editing an existing team
    if (!inputData.team_name || !inputData.employee_select.length) {
      toast.error('All fields are required', 'error');
      return;
    }
    
    // for the duplicate entry
    const isDuplicate = teamListData.some((team) => (team.team_name === inputData.team_name) && (team.id !== editedTeamID));
      if (isDuplicate) {
        toast.warn('Team already exists. Please choose a different team name.', 'error');
        return;
    }
    
    if (editedTeamID === '') {
      // For adding a new team
      onAdding();
      handleCloseModal();
    } else {
      // For editing an existing team
      if (!inputData.team_name || !inputData.employee_select.length) {
        toast.error('All fields are required', 'error');
        return;
      }
      if (!inputData.team_name || inputData.team_name.trim() === "") {
        toast.error("Team Name is required!",'error')
        return;
      }
      else {
        updateDataInDatabase(inputData);
        handleCloseModal();
      }
    }
  };

  // Handle delete button click
  const handleDeleteClick = (teamId) => {
    showAlert('Are you sure you want to delete this Team?', 'confirm', async (isConfirmed) => {
      if (isConfirmed) {
        try {
          const response = await deleteFromAPI(`team/${teamId}/`);
          if (response.message === 'Item deleted successfully') {
            setTeamListData(response.data);
            toast.success('Team deleted successfully!', 'success');
          } else {
            toast.error('Unexpected response', 'error');
          }
        } catch (error) {
          console.error('Error deleting team:', error);
          toast.error('Error deleting team', 'error');
        }
      }
    });
  };

  // Handle edit button click
  const handleEditClick = (team) => {
    setEditedTeamID(team.id);
    SetOldTeamMember(team.employee_select);
    updateModalContent(team);
    handleShowModal();
  };
  
  // Handle form input changes
  const handleChange = (e) => {
    setInputData({ ...inputData, team_name: e.target.value });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Team" />
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-lg-flex justify-content-between align-items-center">
                      <Card.Title className="mb-lg-0">Teams List</Card.Title>
                      <div className="d-md-flex">
                        <Button
                          variant="soft-primary mb-1 mb-md-0"
                          className="btn-icon"
                          onClick={handleShowModal}
                        >
                          <i className="bi bi-plus"></i>
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Row>
                  {teamListData.map((team) => (
                    <Col key={team.id} xl={4} md={6}>
                      <Card>
                        <div className="bg-light">
                          <Card.Header className="bg-transparent d-flex justify-content-between align-items-center">
                            <Card.Title className="mb-0">{team.team_name || 'Unnamed Team'}</Card.Title>
                            <Dropdown align="end">
                              <Dropdown.Toggle
                                variant="link text-dark p-0"
                                id={`dropdown-${team.id}`}
                              >
                                <i className="bi bi-three-dots-vertical"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleEditClick(team)}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDeleteClick(team.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Card.Header>
                        </div>
                        <Card.Body>
                          <Row>
                            <ul className="list-unstyled mb-0 fs-15">
                              {team.employee_select.split(',').map((employeeId, index) => {
                                const employee = employeeListData.find(emp => emp.value === parseInt(employeeId, 10));
                                return (
                                  <li key={index} className="c-mb-6">
                                    {employee && (
                                      <>
                                        <img
                                          className="rounded-circle img-thumbnail avatar-img-sm c-me-12"
                                          alt=""
                                        />
                                        {employee.label}
                                      </>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>

      {/* Modal for adding/editing team */}
      <Modal centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>{editedTeamID ? 'Edit Team' : 'Add Team'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form>
                <Col md={12}>
                  <Form.Group className="custom-fr-group">
                    <Form.Label>Team Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter team name..."
                      required
                      value={inputData.team_name}
                      name="team_name"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="custom-form-input">
                    <Form.Label>Employee Select</Form.Label>
                    <Select
                      options={employeeListData}
                      placeholder="Select employees..."
                      name="employee_select"
                      onChange={handleSelectChange}
                      isMulti={true} // Ensure isMulti is set to true
                      value={inputData.employee_select}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  </Form.Group>
                </Col>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="primary" onClick={handleBothActions}>
            Ok
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="top-left" autoClose={3000} hideProgressBar theme="colored" />
    </>
  );
}
