import React, { useContext ,useState, useEffect} from 'react';
import { Container, Form, Dropdown, Button,OverlayTrigger,Tooltip } from 'react-bootstrap/';
import { getFromAPI,postToAPI, putToAPI } from '../Utils/utils.js'; // Import utility functions
import { Link } from 'react-router-dom';
import user from "../../images/user1.png";
// img
import Avatar1 from "../../images/avatar/avatar-1.jpg";
import Avatar2 from "../../images/avatar/avatar-2.jpg";
import Avatar3 from "../../images/avatar/avatar-3.jpg";
import MenuContext from '../../context/MenuContext';
import Notifications from '../Notifications/Notifications.js';


export default function TopBar() {
    const context = useContext(MenuContext);
    const {iconMenu, handleclick} = context;
    
    const [EmployeeeData, SetEmployeeData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0); // Counter for unread notifications
    const fetchNotificationsAndCountUnread = async () => {
        try {
          const data = await getFromAPI("notification/");
          const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
          const filteredData = data.filter(
            (notification) => notification.user === loggedInUserId && !notification.is_read
          );
          setNotifications(filteredData);
          setUnreadNotificationCount(filteredData.length); // Set unread notifications count
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      };
    
      useEffect(() => {
        const fetchEmployeeData = async () => {
          try {
            const User_Id = localStorage.getItem("employee_id");
            const response = await getFromAPI(
              `employee/employee_id/${User_Id}/`
            );
            SetEmployeeData(JSON.parse(response.data)[0].fields);
          } catch (error) {
            console.error("Error:", error);
          }
        };
        fetchEmployeeData();
        fetchNotificationsAndCountUnread();
      }, []);
      useEffect(() => {
        const fetchEmployee = async () => {
          try {
            if (EmployeeeData.position === "Admin") {
              setIsAdmin(true);
            }
          } catch (error) {
            console.error("Error:", error);
          }
        };
        fetchEmployee();
      });
      useEffect(() => {
        // Refresh unread notifications count every 5 minutes
        const refreshUnreadNotificationCount = setInterval(() => {
          fetchNotificationsAndCountUnread();
        }, 300000); 
    
        return () => clearInterval(refreshUnreadNotificationCount); // Cleanup interval
    
      }, []);
    
    // Inside your functional component
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleLogout = async () => { 
        await postToAPI("logout/")
        
        localStorage.removeItem('employee_id');
        localStorage.removeItem('First_name');
        localStorage.removeItem('Last_name');
    };
    const handleNotificationClick = async () => {
        // Mark all notifications as read when the bell icon is clicked
        try {
            const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
            const unreadNotificationIds = notifications.map(notification => notification.id);
            // Set is_read to true for each notification and update in the database
            await Promise.all(unreadNotificationIds.map(notificationId =>
                putToAPI(`notification/${notificationId}/`, { is_read: true })
            ));
            setNotifications([]);
            setUnreadNotificationCount(0);
        } catch (error) {
            console.error("Error marking notifications as read:", error);
        }
    };
    return (
        <>
            <div className="topbar">
                <Container fluid>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <div className="text-dark fs-24 me-3" onClick={handleclick}>
                                {iconMenu ? <i className='bi bi-list open'></i> : <i className='bi bi-list close avatar-icon' ></i>}
                            </div>
                        </div>
                        <div className='topbar-right-content'>
                            <ul className='list-unstyled d-flex align-items-center mb-0'>
                                {/* Noti icon */}
                                <li className='topbar-icon d-block d-md-none me-3'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant=" p-0 top-drop-btn" id="dropdown-basic">

                                            <div className="topbar-avatar text-secondary avatar avatar-md rounded">
                                                <i className="bi bi-search avatar-icon"></i>
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='dropdown-xl' align="end" title="Dropdown end">

                                            <div className='top-search-sm'>
                                                <Form className='top-search-input-content'>
                                                    <Form.Group className="top-search-input">
                                                        <i className="bi bi-search fs-20 top-search-icon"></i>
                                                        <Form.Control type="email" placeholder="Search..." />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className='topbar-icon topbar-noti-content me-3'>

                                    {/* topbar Notifications */}
                                    <Dropdown>
                                        <Dropdown.Toggle variant=" p-0 top-drop-btn" id="dropdown-basic">
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip>Notifications</Tooltip>}
                                              >
                                            <div className="topbar-avatar text-secondary avatar avatar-md rounded position-relative"
                                                onMouseEnter={handleMouseEnter} 
                                                onMouseLeave={handleMouseLeave}>
                                                   <span>
                                                        {unreadNotificationCount > 0 && <span className="badge bg-danger badge-circle">{unreadNotificationCount}</span>}
                                                    </span>

                                                    <i className="bi bi-bell avatar-icon" onClick={handleNotificationClick}></i>   
                                                </div>
                                             
                                            </OverlayTrigger>
                                                
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='dropdown-xl pb-0' align="end" title="">
                                            <Dropdown.Header className='d-flex justify-content-between align-items-center'>
                                                <h5 className='mb-0 fs-17'>Notifications</h5>
                                                {/* <a href="/setting" className='text-muted'><i className="bi bi-gear fs-22"></i></a> */}
                                                {isAdmin && (<Link  to="/setting"  onClick={() => document.getElementById('dropdown-basic').click()}><i className="bi bi-gear fs-22"></i> </Link>)}
                                            </Dropdown.Header>
                                            <Dropdown.Item href="#/action-1">
                                            <Notifications/>
                                            </Dropdown.Item>
                                            
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>

                                {/* Logout icon */}
                                <li className='topbar-icon topbar-noti-content me-3'>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Logout</Tooltip>}
                                  >
                                      <Link to='/' className="topbar-avatar text-secondary avatar avatar-md rounded position-relative" 
                                            onMouseEnter={handleMouseEnter} 
                                            onMouseLeave={handleMouseLeave}
                                            onClick={handleLogout}>
                                            <i className="bi bi-box-arrow-right avatar-icon"></i>
                                        </Link>
                                </OverlayTrigger>
                                </li>
                                {/* topbar user */}
                                <li className='topbar-user'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant=" p-0 top-drop-btn top-user-drop-btn" id="dropdown-basic">
                                            <span className='top-user-img rounded-circle'>
                                            {EmployeeeData.profile_picture ? (
                                                <img className="rounded-circle img-thumbnail avatar-img-md" src={EmployeeeData.profile_picture} alt="default" />
                                            ) : (
                                                <img className="rounded-circle img-thumbnail avatar-img-md" src={user} alt="default" />
                                            )}
                                            </span>
                                            <span className='top-user-name d-lg-block d-none c-ms-10'>
                                                <h6 className='c-mb-2 fs-15'>{EmployeeeData.first_name} {EmployeeeData.last_name}</h6>
                                                <p className='text-muted fs-13 mb-0'>{EmployeeeData.position}</p>
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu align="end" title="">
                                            <Link className='dropdown-item' to="/employee_profile"     onClick={() => document.getElementById('dropdown-basic').click()}><i className="bi bi-person-circle fs-18 me-2"></i> Profile</Link>
                                            {/* <Dropdown.Item href="#/action-2"><i className="bi bi-sliders fs-18 me-2"></i> Integration</Dropdown.Item> */}
                                            {isAdmin && (<Link className='dropdown-item' to="/setting"     onClick={() => document.getElementById('dropdown-basic').click()}><i className="bi bi-gear-wide-connected fs-18 me-2"></i> Setting</Link>)}
                                            <Dropdown.Divider />
                                            <Link to="/Guide" className='dropdown-item'><i className="bi bi-file-text fs-18 me-2"></i> Guide</Link> 
                                            <Link to="/HelpCenter" className='dropdown-item'><i className="bi bi-info-circle fs-18 me-2"></i> Help Center</Link> 
                                            <Dropdown.Divider />
                                            <Link to='/' className='dropdown-item' onClick={handleLogout}><i className="bi bi-box-arrow-right fs-18 me-2"></i> Logout</Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}