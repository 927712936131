import React, { useState, useEffect } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Button, Container, Card, Row, Col, Modal, Form } from "react-bootstrap";
import { getFromAPI, postToAPI , putToAPI } from '../Utils/utils.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDateFormat } from "../../context/DateFormatContext.js";
import { useNavigate } from "react-router-dom";

export default function Setting() {
  const { selectedFormat, updateSelectedFormat } = useDateFormat();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [originalFormat, setOriginalFormat] = useState(selectedFormat);

  useEffect(() => {
    document.title = 'General Setting | PMRS App';
    return () => {
      document.title = 'PMRS App';
    };
  }, []);

  useEffect(() => {
    const User_Id = localStorage.getItem('employee_id');
    const isPositionAdmin = localStorage.getItem('Position');
    if (User_Id === null || isPositionAdmin !== 'Admin') { 
      navigate('/');
    }
  });

  const saveDateFormat = async () => {
    try {
      const Existing_setting =await getFromAPI('setting/');
      if (Existing_setting.length === 0) {
        await postToAPI('setting/',{setting_name : selectedFormat});
        setShowModal(false);
        toast.success('Date format saved successfully!');
      } else {
        const Existing_id = Existing_setting[0].id;
        await putToAPI(`setting/${Existing_id}/`, { setting_name: selectedFormat });
        toast.success('Date format saved successfully!');
        setShowModal(false);
        setOriginalFormat(selectedFormat); // Update original format after saving
      }
    } catch (error) {
      toast.error('Failed to save date format. Please try again later.');
    }
  };

  const changeDropdown = () => {
    const selectElement = document.getElementById("exampleForm.ControlSelect1");
    if (selectElement) {
      const selectedOption = selectElement.options[selectElement.selectedIndex].value;
      updateSelectedFormat(selectedOption);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    updateSelectedFormat(originalFormat); // Reset to original format on modal close
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="General Setting" />
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-md-flex justify-content-between align-items-center mb-3">
                      <Card.Title className="mb-md-0">Date Format Setting</Card.Title>
                      <div>
                        <Button onClick={() => setShowModal(true)}>Change Date Format</Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Format</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>
              Select Format<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              name="selectedFormat"
              id="position"
              onChange={(e) => updateSelectedFormat(e.target.value)}
              value={selectedFormat}
            >
              <option value="YYYY-MM-DD">YYYY-MM-DD</option>
              <option value="DD-MM-YYYY">DD-MM-YYYY</option>
              <option value="MM-DD-YYYY">MM-DD-YYYY</option>
            </Form.Select>
          </Form.Group>
          {/* <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Select Format</Form.Label>
            <div className="input-icon-content">
              <i className={`bi bi-chevron-up input-icon`} onClick={changeDropdown}></i>
            </div>
            <Form.Control as="select" value={selectedFormat} onChange={(e) => updateSelectedFormat(e.target.value)}>
              <option value="YYYY-MM-DD">YYYY-MM-DD</option>
              <option value="DD-MM-YYYY">DD-MM-YYYY</option>
              <option value="MM-DD-YYYY">MM-DD-YYYY</option>
            </Form.Control>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={saveDateFormat}>Save</Button>
          <Button variant="secondary" onClick={handleModalClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}