import React from 'react';
import TopBar from './TopBar';
import LeftSideBar from './LeftSideBar';

export default function Layout() {
  return (
    <>
      <TopBar />
      <LeftSideBar />
    </>
  )
}
