import React, { useState ,useEffect} from "react";
import { Button, Card, Container, Form, Row, Col ,OverlayTrigger,Tooltip } from "react-bootstrap";
import LogoSm from "../images/logo-sm.png";
import { useNavigate } from "react-router-dom";
import { postToAPI } from './Utils/utils.js'; // Import only the required utility function
import { toast, ToastContainer } from "react-toastify";

export default function Login() {

  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Login | PMRS App'
    document.title = 'Login | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);

  const [showPassword, setShowPassword] = useState(false);
  const [inputData, setInputData] = useState({
    user_email: "",
    password: "",
  });

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  // Function to handle the login process
  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
       // Validate the form
      const form = document.getElementById("loginForm");
      if (form.checkValidity() === false) {
       setValidated(true); // Set validated to true to show the validation errors
       return; // If form validation fails, return without proceeding to login
      } else {
       setValidated(false); // Reset validated state if form is valid
     }

      // Make a POST request to the login endpoint with user credentials
      const response = await postToAPI("login/", {
        email: inputData.user_email,
        password: inputData.password,
      });

      if (response.status) {
        // If login is successful, parse the response and navigate to the dashboard
        var parsedResponse = JSON.parse(response.data);
        localStorage.setItem('employee_id', parsedResponse[0].fields.employee_id);
        localStorage.setItem('First_name',parsedResponse[0].fields.first_name)
        localStorage.setItem('Last_name', parsedResponse[0].fields.last_name);
        localStorage.setItem('Position',parsedResponse[0].fields.position)


        navigate("/dashboard");
      } else {
        // If login fails, show an alert with the error message
        alert(response.message);
      }
    } catch (error) {
      console.error("Error during login:", error);
      if(error.message==="Employee not found" || error.message === "Incorrect password")
      toast.error("Invalid Email Or Password",'error');             
    }
  };

  // Function to handle changes in the input fields
  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {/* Container for the login page */}
      <div className="auth-bg py-4 py-sm-5">
        <Container>
          {/* Row for centering content */}
          <Row className="justify-content-center">
            <Col sm={8} md={6} lg={5} xxl={3}>
              {/* Card for styling */}
              <Card>
                <Card.Body>
                  {/* Avatar and welcome message */}
                  <div className="avtar avatar-xxxl bg-border-soft-primary rounded-circle d-flex align-items-center justify-content-center d-block mx-auto">
                    <img src={LogoSm} alt="default" className="img-shadow rounded-circle" />
                  </div>

                  <Card.Title className="text-center mt-3 mb-0">Welcome Back!</Card.Title>
                  <p className="text-center text-muted">Please enter your details to login.</p>

                  {/* Social login buttons */}
                  <div className="d-flex justify-content-center mb-2">
                    <Button variant="soft-dark" className="btn-w-md">
                      <i className="bi bi-apple"></i>
                    </Button>
                    <Button variant="soft-primary" className="btn-w-md ms-2">
                      <i className="bi bi-google"></i>
                    </Button>
                  </div>

                  {/* Divider */}
                  <p className="auth-or-border fs-14 text-muted mt-3 text-center position-relative">OR</p>

                  {/* Login Form */}
                  <Form id="loginForm" noValidate validated={validated}>
                    {/* Email Input */}
                    <Form.Group className="custom-fr-group" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control 
                         type="email" 
                         placeholder="Enter Email..." 
                         name="user_email" 
                         onChange={handleChange}
                         onKeyDown={(e) => {
                          if (e.key === " ") e.preventDefault(); // Prevent space input
                        }}
                        required />
                      <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
                    </Form.Group>

                    {/* Password Input */}
                    <Form.Group className="custom-fr-group" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <div className="input-icon-content">
                        {/* Password visibility toggle */}
                        <i
                          className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"
                            } input-icon`}
                          onMouseDown={() => setShowPassword(true)}
                          onMouseUp={() => setShowPassword(false)}
                          onMouseOut={() => setShowPassword(false)}
                          onTouchStart={() => setShowPassword(true)}
                          onTouchEnd={() => setShowPassword(false)}
                        ></i>
                        {/* Password Input */}
                        <Form.Control type={showPassword ? "text" : "password"} placeholder="Enter Password..." name="password" onChange={handleChange} required />
                        <Form.Control.Feedback type="invalid">Please enter a valid password.</Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    {/* Remember me and Forgot Password links */}
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />
                      </Form.Group>
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="tooltip">This functionality is temporarily disabled.</Tooltip>}
                      >
                        <a className="text-decoration-underline text-dark fs-14" href="/">
                          Forgot Password?
                        </a>
                      </OverlayTrigger>
                    </div>

                    {/* Login Button */}
                    <Button type="submit" className="btn-soft-primary w-100" onClick={handleLogin}>
                      <i className="bi bi-box-arrow-in-right me-2"></i>Login
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />

    </>
  );
}