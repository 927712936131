import React, { useState, useEffect, useRef } from 'react';
import PageTitle from "../layout/PageTitle";
import Footer from '../layout/Footer';
import { Table, Form, Button, Card, Container, Row, Col, Modal } from "react-bootstrap";
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI,showAlert } from '../Utils/utils.js'; // Updated import to use utility functions
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Navigate } from "react-router-dom";

const staticData = [
  { id: 'static1', milestone_status_name: "Completed", milestone_status_description: "Milestone Completed", Select_color: "Success", isStatic: true },
  { id: 'static2', milestone_status_name: "Pending", milestone_status_description: "Milestone Pending", Select_color: "Warning", isStatic: true }
];
function MilestoneStatusList() {

  // React-select options for milestone status colors
  const colorOptions = [
    { value: "Primary", label: "Primary" },
    { value: "Success", label: "Success" },
    { value: "Info", label: "Info" },
    { value: "Warning", label: "Warning" },
    { value: "Danger", label: "Danger" },
    { value: "Dark", label: "Dark" },
    { value: "Secondary", label: "Secondary" },
    { value: "Light", label: "Light" },
  ];
  const [milestoneStatusListData, setMilestoneStatusListData] = useState([]);
  const [dynamicData, setDynamicData] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(false); // State variable for refresh flag
  const navigate = useNavigate();
  const tableRef = useRef(null); // Ref for DataTable instance

  // Fetching all milestone related data
  useEffect(() => {

    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    if (User_Id === null) {
      navigate('/');
    }

    async function fetchData() {
      try {
        const fetchedDynamicData = await getFromAPI("milestonestatus/");
        setDynamicData(fetchedDynamicData);
      } catch (error) {
        console.error("Error fetching dynamic data:", error);
      }
    }
    fetchData();
  }, [refreshFlag]);

  // State to hold modal input data for colour
  const [inputData, setInputData] = useState([]);
  const [editedData,setEditedData] = useState({
    milestoneStatusID: '',
  });

  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Milestone Status | PMRS App'
    document.title = 'Milestone Status | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);

  DataTable.Buttons.jszip(JSZip);
  useEffect(() => {
    if (tableRef.current) {
      // If DataTable has already been initialized, destroy it first
      tableRef.current.clear().destroy();
    }
    // Initialize DataTable
    const dataTableInstance = $('.maindatatable').DataTable({
      dom: 'flrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search...',
        paginate: {
          previous: '«',
          next: '»',
        },
      },
      data:  staticData.concat(dynamicData), // Concatenate staticData and dynamicData
      columns: [
        { data: 'milestone_status_name' },
        { data: 'milestone_status_description' },
        { data: 'Select_color' },
        {
          data: null,
          render: function (data, type, row, meta) {
            if (!row.isStatic) {
              // Render buttons only for rows from staticData
              return `
                <div class="d-flex">
                  <button class="btn btn-primary table-btn me-2" data-id="${row.id}" data-action="edit"><i class="bi bi-pencil-square"></i></button>
                  <button class="btn btn-danger table-btn" data-id="${row.id}" data-action="delete"><i class="bi bi-trash3"></i></button>
                </div>
              `;
            } else {
                // Render without buttons for rows not from staticData
                return '';
            }
          }
        }
      ]
    });
  
    // Store the DataTable instance in the ref
    tableRef.current = dataTableInstance;
    // Attach event listeners to handle edit and delete actions
    $(document.body).on('click', '.btn.table-btn', function() {
      const action = $(this).data('action');
      const id = $(this).data('id');
      if (action === 'edit') {
        handleEditAction(id);
      } else if (action === 'delete') {
        handleDelete(id);
      }
    });
  }, [dynamicData]);
  // State for showing or hiding modal
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setEditedData((prevData) => ({
      ...prevData,
      milestoneStatusID: '' // Reset milestoneStatusID to empty string
    }));
  };
  const handleShowModal = () => {
    // Reset the input data to blank state
    setInputData({
      milestone_status_name: "",
      milestone_status_description: "",
      Select_color: "Select Color"
    });
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);

    // Function to add data to the database
    const addMilestoneStatus = async () => {
      try {
        const response = await postToAPI("milestonestatus/", {
          milestone_status_name: inputData.milestone_status_name,
          milestone_status_description: inputData.milestone_status_description,
          Select_color: inputData.Select_color,
        });
        if (response.status) {
          setDynamicData(response.data);
          toast.success("Milestone Added successfully!",'success');
          setRefreshFlag(prevFlag => !prevFlag);
        } else {
          toast.error("Some Error Occurred...");
        }
        setEditedData.milestoneStatusID('');
      } catch (error) {
        console.error("Error adding Milestone Status:",'error', error);
      }
    };

  // Function to update modal content
  function updateModalContent(data) {
    setInputData({
      milestone_status_name: data.milestone_status_name || "",
      milestone_status_description: data.milestone_status_description || "",
      Select_color: data.Select_color || ""
    });
  }

  // Function to fetch data for editing
  const fetchEditData = async (itemId) => {
    try {
      const data = await getFromAPI(`milestonestatus/${itemId}/`);
      const jsonData = JSON.parse(data.data);
      if (data.status) {
        editedData.milestoneStatusID = itemId;
        updateModalContent(jsonData[0].fields);
      } else {
        toast.error("Some Error Occurred...");
      }
    } catch (error) {
      console.error("Error fetching data for editing:", error);
    }
  };

  // Handling Edit action event
  const handleEditAction = (id) => {
    fetchEditData(id);
    handleShowModal();
  };

  // Handling change event for input fields
  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const [MilesotneStatusDeletedSuccessfully, setMilestoneStatusDeletedSuccessfully] = useState(false);

  // Function to delete data
  const handleDelete = async (itemID) => {
    try {
      // Show confirmation dialog before deleting
      showAlert("Are you sure you want to delete this Milestone Status?", 'confirm', async (isConfirmed) => {
        if (!isConfirmed) {
          return; // If not confirmed, do nothing
        }
        try {
          const response = await deleteFromAPI(`milestonestatus/${itemID}/`);
          if (response.status) {
            setDynamicData(response.data);
            //showAlert("Milestone Status Deleted Successfully.", 'success'); 
            toast.success("Milestone Status Deleted Successfully.", 'success');
            setRefreshFlag(prevFlag => !prevFlag);
          } else {
            console.error("Unexpected response:", response.message);
          }
        } catch (error) {
          console.error("Error deleting item:", error);
        }
      });
    } catch (error) {
      console.error("Error showing delete confirmation:", error);
    }
  };
  
  useEffect(() => {
    if (MilesotneStatusDeletedSuccessfully) {
      toast.success('Milestone status Deleted successfully!','success');
      setTimeout(() => {
        setMilestoneStatusDeletedSuccessfully(false);
      }, 3000); // Clear the success message after 3 seconds
    }
  }, [MilesotneStatusDeletedSuccessfully]);

  // Function to update data in the database
  function updateDataInDatabase(itemDetails) {
    const updateUrl = `milestonestatus/${editedData.milestoneStatusID}/`;
      if (!itemDetails.milestone_status_name || !itemDetails.milestone_status_description) {
        toast.error("All fields are required.", 'error');
        return; // Stop further execution
      }
      const requestBody = {
        milestone_status_name: itemDetails.milestone_status_name || null,
        milestone_status_description: itemDetails.milestone_status_description || null,
        Select_color: itemDetails.Select_color || null,
      };
      // Check if the updated milestone_status_name already exists
      const isDuplicate = dynamicData.some(
        (item) =>
          item.milestone_status_name === requestBody.milestone_status_name &&
          item.id !== editedData.milestoneStatusID
      );
      if (isDuplicate) {
        toast.warn("Duplicate entry. Please choose a different Milestone Status Name.", 'error');
        return;
      }
        putToAPI(updateUrl, requestBody)
          .then((response) => {
            if (response.message === 'Item updated successfully') {
              setDynamicData(response.data);
              toast.success("Milestone Status updated successfully", 'success');
              setRefreshFlag(prevFlag => !prevFlag);
              } else {
              console.error("Unexpected response:", response.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
    editedData.milestoneStatusID = '';
  }
  // Function to handle OK and Cancel actions
  const handleOKAction = () => {

        if (!inputData.milestone_status_name || inputData.milestone_status_name.trim() === "") {
          toast.error("Milestone Status Name is required!",'error')
          return;
        }
        if (!inputData.milestone_status_description || inputData.milestone_status_description.trim() === "") {
          toast.error("Milestone Status Description is required!",'error')
          return;
        }

        // Check for null or empty values
        if (!inputData.milestone_status_name || !inputData.milestone_status_description ||
          inputData.Select_color === "Select Color" || inputData.milestone_status_name.trim() ==="" || inputData.milestone_status_description.trim() ==="")
            {
              toast.error('Please fill in all the required fields.','error');
              return;
            }

        // Check if the project already exists
        const DynamicProjectExists = dynamicData.some((item) => (item.milestone_status_name === inputData.milestone_status_name) && (item.id !== editedData.milestoneStatusID));
          const StaticProjectExist = staticData.some((item) => (item.milestone_status_name === inputData.milestone_status_name));
            if (DynamicProjectExists || StaticProjectExist) {
              toast.warn('Milestone Status Name already exists. Please choose a different name.','error');
              return;
            }

            if (editedData.milestoneStatusID === '') {
              addMilestoneStatus();
            } else {
              if (!inputData.milestone_status_name || inputData.milestone_status_name.trim() === "") {
                toast.error("Milestone Statues is required!",'error')
                return;
              }
              if (!inputData.milestone_status_description || inputData.milestone_status_description.trim() === "") {
                toast.error("Milestone Status Description is required!",'error')
                return;
              }
              else{
              updateDataInDatabase(inputData);
              }
            }
      handleClose();
      setRefreshFlag(prevFlag => !prevFlag);
    };

  // Function to handle dropdown selection
  const handleSelection = (e) => {
    const selectedColor = e.target.value;
    setInputData({ ...inputData, Select_color: selectedColor });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Milestone Status" />
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <div className="d-lg-flex justify-content-between align-items-center mb-3">
                      <Card.Title className='mb-lg-0'>Milestone Status</Card.Title>
                      <div className="d-md-flex">
                        <Button variant="soft-success mb-1 mb-md-0" className="btn-icon" onClick={handleShowModal}>
                          <i className="bi bi-plus"></i>
                        </Button>
                      </div>
                    </div>
                    <Table
                      responsive
                      bordered
                      className="mb-0 table-nowrap w-100 maindatatable">
                      <thead className="table-light">
                        <tr>
                          <th>Status Name</th>
                          <th>Description</th>
                          <th>Color</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
        <Modal centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-lg">
          <Modal.Header closeButton>
            <Modal.Title> Add Milestone Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Row>
                <Col>
                  <Form>
                    <Col md={12}>
                      <Form.Group
                        className="custom-fr-group">
                        <Form.Label>Milestone Status Name </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Milestone status name..."
                          required
                          name="milestone_status_name"
                          id="milestone_status_name"
                          value={inputData.milestone_status_name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="custom-fr-group">
                        <Form.Label>Milestone Status Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Milestone Status description..."
                          as="textarea"
                          rows={4}
                          name="milestone_status_description"
                          id="milestone_status_description"
                          value={inputData.milestone_status_description}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="custom-form-input">
                        <Form.Label>Select Color</Form.Label>
                        <Form.Select name="Select_color" id="Select_color" value={inputData.Select_color} onChange={handleSelection}>
                          <option value="">Select Color</option>
                          <option value="Primary">Primary</option>
                          <option value="Success">Success</option>
                          <option value="Info">Info</option>
                          <option value="Warning">Warning</option>
                          <option value="Danger">Danger</option>
                          <option value="Dark">Dark</option>
                          <option value="Secondary">Secondary</option>
                          <option value="Light">Light</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button variant="primary" onClick={handleOKAction}>
              Ok
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer
          position="top-left"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </>
  )
}
// Export the MilestoneStatusList component
export default MilestoneStatusList;
