import React, { useState, useEffect, useRef} from 'react';
import Select from 'react-select';
import PageTitle from '../layout/PageTitle';
import Footer from '../layout/Footer';
import { Table, Form, Button, Badge, Card, Container, Row, Col, Modal } from 'react-bootstrap';
import JSZip from 'jszip';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import $ from 'jquery';
import DataTable from 'datatables.net-dt';
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI, showAlert, sendNotification } from '../Utils/utils.js';
import { useDateFormat } from "../../context/DateFormatContext.js";
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Navigate } from "react-router-dom";

DataTable.Buttons.jszip(JSZip);

// Define static milestone status data
const staticMilestoneStatusData = [
  {milestone_status_name: "Completed", milestone_status_description: "Milestone Completed", Select_color: "Success" },
  {milestone_status_name: "Pending", milestone_status_description: "Milestone Pending", Select_color: "Warning" }
];

export default function Milestone() {

  // This function runs when the component mounts
  useEffect (() =>{
    // It sets the document title to 'Milestone | PMRS App'
    document.title = 'Milestone | PMRS App';
    // This return statement defines a cleanup function
    return ()=> {
      // This function runs when the component unmounts
      document.title = 'PMRS App';
      // It resets the document title back to 'PMRS App'
    };
  },[]);
  
  const tableRef = useRef(null); // Ref for DataTable instance
  const [milestoneData, setMilestoneData] = useState([]);
  const { selectedFormat } = useDateFormat();
  const [projectData, setProjectData] = useState([]);
  const [milestoneStatusData, setMilestoneStatusData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editedTeamID, setEditedTeamID] = useState('');
  const [teamListData, setTeamListData] = useState([]);
  const navigate = useNavigate();
  const [oldmilestonestatus ,setOldMilestoneStatus]=useState();
  const [inputData, setInputData] = useState({
    milestone_name: '',
    milestone_description: '',
    project_select: '',
    start_date: '',
    end_date: '',
    project_milestone: '',
    project_price: '',
    team_select: [], // Store selected teams as an array
  });

  // for fetching data from team, milestone ,project ,miletsone status
  useEffect(() => {

    // Navigate to Login page if no Employee ID Found
    const User_Id = localStorage.getItem('employee_id');
    if (User_Id === null) {
      navigate('/');
    }

    async function fetchData() {
      try {

        const teamData = await getFromAPI('team/');
        const updatedteamData = teamData.map(team => ({
          value: team.id,
          label: team.team_name,
        }));
        setTeamListData(updatedteamData)

        const milestonedata = await getFromAPI('milestone/')
        setMilestoneData(milestonedata)

        const projectData = await getFromAPI('project/');
        setProjectData(projectData)

        const milestonestatusdata = await getFromAPI('milestonestatus/');
        // Merge static data with dynamic data
        setMilestoneStatusData([...milestonestatusdata, ...staticMilestoneStatusData]);
      } catch (error) {
        toast.error('Error fetching team assign data:', error);
      }
    }
    fetchData();
  }, []);
  
  // Initialize DataTable on milestoneData change
  useEffect(() => {
    // Initialize or update DataTable when milestoneData changes
    if (tableRef.current) {
      // If DataTable has already been initialized, destroy it first
      tableRef.current.clear().destroy();
    }
  
    // Initialize DataTable
    const dataTableInstance = $('.maindatatable').DataTable({
      dom: 'flrtip',
      language: {
        search: '',
        searchPlaceholder: 'Search...',
        paginate: {
          previous: '«',
          next: '»',
        },
      },
      data: milestoneData, // Add milestoneData directly to the DataTable
      columns: [
        { data: 'project_select',
        render: function (data, type, row) {
          const selectedProject = projectData.find(project => project.id.toString() === data);
          var ProjectName = "Not Available";
          if (selectedProject) ProjectName = selectedProject.projectname;
          const isPastDueAndNotCompleted = isPastDue(row.end_date) && row.project_milestone !== "Completed";
          let badgeClass = "";
          if (isPastDueAndNotCompleted) {
            badgeClass = " text-danger";
          }
          return (
            `<span class="${badgeClass}">${ProjectName}</span>`
          );
        }
       },
        { 
          data: 'milestone_name',
          render: function (data, type, row) {
            const isPastDueAndNotCompleted = isPastDue(row.end_date) && row.project_milestone !== "Completed";
            let badgeClass = "";
            if (isPastDueAndNotCompleted) {
              badgeClass = " text-danger";
            }
            return (
              `<span class="${badgeClass}">${data}</span>`
            );
          }
        },
        { 
          data: null,
          render: function (data, type, row) {
            const isPastDueAndNotCompleted = isPastDue(row.end_date) && row.project_milestone !== "Completed";
            let badgeClass = "";
            if (isPastDueAndNotCompleted) {
              badgeClass = " text-danger";
            }
            return (
              `<span class="${badgeClass}"> ${formatDate(row.start_date) + ' To ' + formatDate(row.end_date)}</span>`
              );
          }
        },
        { data: 'project_price',
        render: function (data, type, row) {
          const isPastDueAndNotCompleted = isPastDue(row.end_date) && row.project_milestone !== "Completed";
          let badgeClass = "";
          if (isPastDueAndNotCompleted) {
            badgeClass = " text-danger";
          }
          return (
            `<span class="${badgeClass}">${data}</span>`
          );
        }
        },
        {
          data: 'team_select',
          render: function (data,type,row) {
            // Assuming teamListData is an array of objects with a 'value' property
            const teams = data.split(',').map(teamId => {
              const team = teamListData.find(item => item.value === parseInt(teamId, 10));
              return team ? team.label : '';
            });
            const isPastDueAndNotCompleted = isPastDue(row.end_date) && row.project_milestone !== "Completed";
          let badgeClass = "";
          if (isPastDueAndNotCompleted) {
            badgeClass = " text-danger";
          }
          return (
            `<span class="${badgeClass}">${teams.join(', ')}</span>`)
          }
        },
        { data: 'project_milestone',
        render: function (data, type, row) {
          const isPastDueAndNotCompleted = isPastDue(row.end_date) && row.project_milestone !== "Completed";
          let badgeClass = "";
          if (isPastDueAndNotCompleted) {
            badgeClass = " text-danger";
          }
          return (
            `<span class="${badgeClass}">${data}</span>`
          );
        } },
        {
          data: null,
          render: function (data, type, row) {
            return `
              <div class="d-flex">
                <button class="btn btn-primary table-btn me-2" data-id='${JSON.stringify(row)}' data-action="edit"><i class="bi bi-pencil-square"></i></button>
                <button class="btn btn-danger table-btn" data-id='${JSON.stringify(row)}' data-action="delete"><i class="bi bi-trash3"></i></button>
              </div>
            `;
          }
        }
      ]
    });
  
    // Store the DataTable instance in the ref
    tableRef.current = dataTableInstance;
    // Attach event listeners to handle edit and delete actions
    $(document.body).on('click', '.btn.table-btn', function() {
      const action = $(this).data('action');
      const rowData = $(this).data('id');
      if (action === 'edit') {
        handleEditClick(rowData);
      } else if (action === 'delete') {
        handleDeleteMilestone(rowData.id);
      }
    });
  }, [milestoneData, projectData]);

  // function for closeing modal setup
  const handleCloseModal = () => {
    setShowModal(false);
    setInputData({
      milestone_name: '',
      milestone_description: '',
      project_select: '',
      project_milestone: '',
      start_date: '',
      end_date: '',
      project_price: '',
      team_select: [],
    })
    setShowModal(false);
    setEditedTeamID(''); // Reset editedTeamID

  };

  const handleShowModal = () => {
    setShowModal(true);

  };

  // function for adding the Milestone data
  const handleAddMilestone = async () => {
    try {
          // Check if any required field is empty
          const requiredFields = ['milestone_name', 'milestone_description', 'project_select', 'start_date', 'end_date', 'project_milestone', 'project_price', 'team_select'];
          const missingFields = requiredFields.filter(field => !inputData[field]);
          if (missingFields.length > 0) {
            toast.error("All Fields Are Required", "error");
            return;
          }

          // Format the start_date and end_date strings
          const formattedStartDate = convertToYYYYMMDD(inputData.start_date);
          const formattedEndDate = convertToYYYYMMDD(inputData.end_date);

          const data = await postToAPI('milestone/', {
            milestone_name: inputData.milestone_name,
            milestone_description: inputData.milestone_description,
            project_select: inputData.project_select,
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            project_milestone: inputData.project_milestone,
            project_price: inputData.project_price,
            team_select: inputData.team_select.map(team => team.value).join(','),
          });

          if (data.status) {
            setMilestoneData(data.data);
            toast.success('Milestone added successfully!','success');
            handleCloseModal();
            // Send notification to each employee
            const type = "milestone";
            const creation_time = new Date().toISOString();
            const selectedTeams = inputData.team_select.map(team => team.value);
            const uniqueEmployees = [];
            selectedTeams.forEach(selectedTeamId => {
                const selectedTeam = teamListData.find(team => team.id === selectedTeamId);
                if (selectedTeam) {
                    selectedTeam.employee_select.split(',').forEach(employeeId => {
                        const trimmedId = parseInt(employeeId.trim());
                        if (!uniqueEmployees.includes(trimmedId)) {
                            uniqueEmployees.push(trimmedId);
                        }
                    });
                }
            });
            
            // Send notifications to unique employees
            uniqueEmployees.forEach(async (employeeId) => {      
                const message = `New ${inputData.milestone_name} milestone has been Assigned to you under project ${inputData.project_select}`;
                await sendNotification('notification/', type, message,creation_time, employeeId, false);
              });
            // Notification for milestone ends today or tomorrow
            uniqueEmployees.forEach(async (employeeId) => {
              const endDate = new Date(inputData.end_date);
              const today = new Date();
              const timeDifference = endDate.getTime() - today.getTime();
              const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                  if (daysDifference === 1) {
                    const message = `${inputData.milestone_name} will end tomorrow`;
                    await sendNotification('notification/', type, message, creation_time, employeeId, false);
                } else if (daysDifference === 0) {
                    const message = `${inputData.milestone_name} will end today`;
                    await sendNotification('notification/', type, message, creation_time, employeeId, false);
                }});
              } 
            setEditedTeamID('');
          
      
        } catch (error) {
            toast.warn('Milestone already exists. Please choose a different name.', 'error');
          }
    };

  // function for the updating data in database   
  const updateDataInDatabase = async (itemDetails) => {
    const updateUrl = `milestone/${editedTeamID}/`;
      if (!itemDetails.milestone_name || !itemDetails.milestone_description || !itemDetails.project_select || !itemDetails.start_date || !itemDetails.end_date || !itemDetails.project_milestone || !itemDetails.project_price || !itemDetails.team_select) {
        toast.error('All Fields Are Requires', 'error')
      }
  
    const formattedStartDate = convertToYYYYMMDD(inputData.start_date);
    const formattedEndDate = convertToYYYYMMDD(inputData.end_date);
    const requestBody = {
      milestone_name: itemDetails.milestone_name || null,
      milestone_description: itemDetails.milestone_description || null,
      project_select: itemDetails.project_select || null,
      start_date:  formattedStartDate|| null,
      end_date:  formattedEndDate || null,
      project_milestone: itemDetails.project_milestone || null,
      project_price: itemDetails.project_price || null,
      team_select: itemDetails.team_select ? itemDetails.team_select.map(team => team.value).join(',') : null,
    };

    try {
      const data = await putToAPI(updateUrl, requestBody);
        if (data.message === 'Item updated successfully') {
          setMilestoneData(data.data);
          toast.success('Milestone updated successfully!', 'success');
          // notification
          const type = "milestone";
          const creation_time = new Date().toISOString();
          const selectedTeams = itemDetails.team_select.map(team => team.value);
          //unique employee id's of different teams 
          const uniqueEmployees = [];
          selectedTeams.forEach(selectedTeamId => {
              const selectedTeam = teamListData.find(team => team.id === selectedTeamId);
              if (selectedTeam) {
                  selectedTeam.employee_select.split(',').forEach(employeeId => {
                      const trimmedId = parseInt(employeeId.trim());
                      if (!uniqueEmployees.includes(trimmedId)) {
                          uniqueEmployees.push(trimmedId);
                      }
                  });
              }
          });
          //Milestone Status Change
          uniqueEmployees.forEach(async (employeeId) => {
            if (oldmilestonestatus !== itemDetails.project_milestone) {
              const message = `The milestone name ${itemDetails.milestone_name} has updated its status to ${itemDetails.project_milestone}.`;
              await sendNotification('notification/', type, message, creation_time, employeeId, false);
          }
          });
          //Milestone Ends Today or Tomorrow 
          uniqueEmployees.forEach(async (employeeId) => {
            const endDate = new Date(itemDetails.end_date);
            const today = new Date();
            const timeDifference = endDate.getTime() - today.getTime();
            const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
                if (daysDifference === 1) {
                  const message = `${itemDetails.milestone_name} will end tomorrow`;
                  await sendNotification('notification/', type, message, creation_time, employeeId, false);
              } else if (daysDifference === 0) {
                  const message = `${itemDetails.milestone_name} will end today`;
                  await sendNotification('notification/', type, message, creation_time, employeeId, false);
          }});
        } else {
          toast.error('Unexpected response:', data.message);
        }
      } catch (error) {
        // Handle network or other errors
        toast.warn('Milestone already exists. Please choose a different name.', 'error');
        }
    setEditedTeamID('')
  };

  // function for the updating data on edit time
  const UpdatedModalContent = (data) => {
    const preSelectedTeams = (data.team_select || '').split(',').filter(id => id.trim() !== '').map(teamId => {
      const parseId = parseInt(teamId, 10);
      const team = teamListData.find(team => team.value === parseId);
      if (team) {
        return { value: parseId, label: team.label };
      }
      return null;
    }).filter(Boolean);

    setInputData({
      milestone_name: data.milestone_name,
      milestone_description: data.milestone_description,
      project_select: data.project_select,
      project_milestone: data.project_milestone,
      start_date: data.start_date,
      end_date: data.end_date,
      project_price: data.project_price,
      team_select: preSelectedTeams, // Set all preselected teams
    });
  };

  // Handle edit button click
  const handleEditClick = (team) => {
    setEditedTeamID(team.id);
    UpdatedModalContent(team);
    handleShowModal();
  };

  //function for the deleting the milestone
    const handleDeleteMilestone = async (milestoneId) => {
      try {
        showAlert('Are you sure you want to delete this Milestone?', 'confirm', (result) => {
          if (result) {
            // User confirmed the deletion
            deleteMilestone(milestoneId);
          } else {
            // User canceled the deletion
            // You can handle this case if needed
          }
        });
      } catch (error) {
        toast.error('Error deleting milestone', 'error');
      }
    };

    //function for the deleting the milestone form the database
    const deleteMilestone = async (milestoneId) => {
      try {
        const response = await deleteFromAPI(`milestone/${milestoneId}/`);
        if (response.status) {
          setMilestoneData((prevData) => prevData.filter((milestone) => milestone.id !== milestoneId));
          toast.success('Milestone deleted successfully!', 'success');
        } else {
          toast.error('Error deleting milestone', 'error');
        }
      } catch (error) {
        toast.error('Error deleting milestone', 'error');
      }
    };

  const handleChange = (e) => {
    setInputData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (selectedOptions) => {
    setInputData((inputData) => ({
      ...inputData,
      team_select: selectedOptions,
    }));
  };

  // function for the validate miestone dates 
  const validateMilestoneDate = () => {
    // Find the selected project by project name
    const selectedProject = projectData.find(project => project.id.toString() === inputData.project_select);
      if (selectedProject) {
        const projectStartDate = new Date(selectedProject.startdate);
        const projectEndDate = new Date(selectedProject.enddate);
        const milestoneStartDate = new Date(convertToYYYYMMDD(inputData.start_date));
        const milestoneEndDate = new Date(convertToYYYYMMDD(inputData.end_date));

        if (milestoneStartDate < projectStartDate || milestoneEndDate > projectEndDate) {
          toast.error('Milestone date should be between project start date and end date', 'error');
          return false;
        }

      } else {
        toast.error('Please select a project first', 'error');
        return false;
      }
    return true;
  };

    // Function to format the date based on the selected format
    const formatDate = (dateString) => {
      // If the dateString is empty or null, return an empty string
      if (!dateString) return '';

      // Create a new Date object with the parts in 'MM-DD-YYYY' format
      const date = new Date(dateString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.error(`Invalid date: ${dateString}`);
        return ''; // Return an empty string if the date is invalid
      }

      // Get the date components
      const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero if necessary
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with leading zero if necessary
      const year = date.getFullYear();

      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }; // Define the default format

      // Custom formats based on the selected format
      switch (selectedFormat) {
        case 'YYYY-MM-DD':
        // Use custom format for 'YYYY-MM-DD'
          return year + "-" + month + "-" + day;
        case 'DD-MM-YYYY':
        // Use custom format for 'DD-MM-YYYY'
          return day + "-" + month + "-" + year;
        case 'MM-DD-YYYY':
        // Use custom format for 'MM-DD-YYYY'
          return month + "-" + day + "-" + year;
        // Add more cases for additional formats as needed
        default:
        // Default to the standard format
          return date.toLocaleDateString('en-GB', options);
      }
    };

      // Function to convert any date format to yyyy-mm-dd format
      const convertToYYYYMMDD = (dateString) => {
      // Create a new Date object from the input string
      const date = new Date(dateString);
      
      // Check if the input string is a valid date
      if (isNaN(date.getTime())) {
        console.error(`Invalid date: ${dateString}`);
        return ''; // Return an empty string if the date is invalid
      }
      
      // Get the components of the date (year, month, day)
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
      const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if necessary
      
      // Return the date in yyyy-mm-dd format
      return `${year}-${month}-${day}`;
      };

    // Handle both Add and Update actions
    const handleBothActions = () => {

    if(convertToYYYYMMDD(inputData.start_date)>convertToYYYYMMDD(inputData.end_date))
    {
      toast.error("End Date should be greater than Start Date",'error')
      return;
    }
    if(inputData.project_price<0)
    {
     toast.error("Milestone Price Should Be Greater Than 0",'error');
     return
    }
    if (!inputData.milestone_name || inputData.milestone_name.trim() === "") {
      toast.error("Milestone Name is required!",'error')
      return;
    }
    if (!inputData.milestone_description || inputData.milestone_description.trim() === "") {
      toast.error("Milestone Description is required!", 'error')
      return;
    }
    
    if (editedTeamID === '') {
      if (
        !inputData.milestone_name ||
        !inputData.milestone_description ||
        !inputData.team_select?.length > 0 ||
        !inputData.project_milestone ||
        !inputData.project_price ||
        !inputData.start_date ||
        !inputData.end_date
      ) {
        toast.error('All fields are required', 'error');
        return;
      }

      // Validate milestone price against project price
    const selectedProject = projectData.find(project => project.id.toString() === inputData.project_select);
    if (selectedProject && parseFloat(inputData.project_price) > parseFloat(selectedProject.project_price)) {
      toast.error("Milestone Price Should Be Less Than or Equal to Project Price", 'error');
      return;
    }
      // function for checking valid entry or duplicate entry
      const isDuplicate = milestoneData.some((milestone) => (milestone.milestone_name === inputData.milestone_name) && (milestone.id !== editedTeamID));
      if (isDuplicate) {
        toast.warn('MileStone already exists. Please choose a different MileStone name.', 'error');
        return;
      } else {
        if (validateMilestoneDate()) {
          handleAddMilestone();
          handleCloseModal();
        }
      }
    } else {
      // For editing an existing team
      if (
        !inputData.milestone_name ||
        !inputData.milestone_description ||
        !inputData.team_select?.length > 0 ||
        !inputData.project_select ||
        !inputData.project_milestone ||
        !inputData.project_price ||
        !inputData.start_date ||
        !inputData.end_date
      ) {
        toast.error('All fields are required', 'error');
        return;
      } 
      // Validate milestone price against project price
    const selectedProject = projectData.find(project => project.id.toString() === inputData.project_select);
    if (selectedProject && parseFloat(inputData.project_price) > parseFloat(selectedProject.project_price)) {
      toast.error("Milestone Price Should Be Less Than or Equal to Project Price", 'error');
      return;
    }
      const isDuplicate = milestoneData.some((milestone) => (milestone.milestone_name === inputData.milestone_name) && (milestone.id !== editedTeamID));
      if (isDuplicate) {
        toast.warn('MileStone already exists. Please choose a different MileStone name.', 'error');
        return;
      } else {
        if(convertToYYYYMMDD(inputData.start_date)>convertToYYYYMMDD(inputData.end_date))
        {
          toast.error("End Date should be greater than Start Date",'error')
          return;
        }
        if(inputData.project_price<0)
        {
         toast.error("Milestone Price Should Be Greater Than 0",'error');
         return
        }
        if (!inputData.milestone_name || inputData.milestone_name.trim() === "") {
          toast.error("Milestone Name is required!",'error')
          return;
        }

        if(!inputData.milestone_description || inputData.milestone_description.trim() === "") {
          toast.error("Milestone Description is required!",'error')
          return;
        }

        if (validateMilestoneDate()) {
          updateDataInDatabase(inputData);
          handleCloseModal();
        }
      }
    }
  };
  
  const resetInputData = () => {
    setInputData({
      milestone_name: '',
      milestone_description: '',
      project_select: '',
      start_date: '',
      end_date: '',
      project_milestone: '',
      project_price: '',
    });
  };

  // Function to handle adding a department
  const AddMilestone = () => {
    resetInputData(); // Reset input data when opening the modal for adding
    handleShowModal(); // Show the modal
  };
  
  //For comparing today's date with project enddate
  const isPastDue = (endDate) => {
    const currentDate = new Date();
    const projectEndDate = new Date(endDate);
    return currentDate > projectEndDate;
  };

  // function for the set the date format
  const handleChangeDate = (date, fieldName) => {
    setInputData((prevData) => ({
      ...prevData,
      [fieldName]: date, // Update the state with the selected date
    }));
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Milestone" />
          <Container>
            <Row>
              <Col className="mb-4">
                <Card>
                  <Card.Body>
                    <div className="d-lg-flex justify-content-between align-items-center mb-3">
                      <Card.Title className="mb-lg-0">Milestone</Card.Title>
                      <div className="d-md-flex">
                        <Button variant="soft-success" className="btn-icon" onClick={AddMilestone}>
                          <i className="bi bi-plus"></i>
                        </Button>
                      </div>
                    </div>
                    <Table responsive bordered className="mb-0 table-nowrap w-100 maindatatable">
                      <thead className="table-light">
                        <tr>
                          <th>Project Name</th>
                          <th>Milestone Name</th>
                          <th>Milestone Date</th>
                          <th>Milestone Price</th>
                          <th>Teams</th>
                          <th>Milestone Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      <Footer />
      </div>
      <Modal centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-lg">
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>{editedTeamID ? 'Edit Milestone' : 'Add Milestone'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
              <Row>
                <Col>
                  <Form>
                    <Col md={12}>
                      <Form.Group className="custom-fr-group">
                        <Form.Label>Milestone Name </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter milestone name..."
                          onChange={handleChange}
                          value={inputData.milestone_name}
                          required
                          name="milestone_name"
                          id="milestone_name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="custom-fr-group">
                        <Form.Label>Milestone Description</Form.Label>
                        <Form.Control
                          placeholder="Enter description..."
                          as="textarea"
                          rows={4}
                          name="milestone_description"
                          id="milestone_description"
                          value={inputData.milestone_description}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Row>
                      <Col lg={6} sm={12}>
                        <Form.Group className="custom-fr-group custom-form-input">
                          <Form.Label>Project Select</Form.Label>
                          <Form.Select name="project_select" id="project_select" onChange={handleChange} value={inputData.project_select}>
                            <option value="">Select Project</option>
                            {projectData.map((item) => (
                              <option key={item.projectname} value={item.id}>{item.projectname}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col lg={6} sm={12}>
                        <Form.Group className="custom-fr-group custom-form-input">
                          <Form.Label>Milestone Status</Form.Label>
                          <Form.Select name="project_milestone" id="project_milestone" onChange={handleChange} value={inputData.project_milestone}>
                            <option value="">Select Milestone Status</option>
                            {milestoneStatusData.map((item) => (
                              <option key={item.milestone_status_name}>{item.milestone_status_name}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Form.Group className="custom-fr-group">
                          <Form.Label>Milestone Price</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter Milestone price..."
                            onChange={handleChange}
                            required
                            name="project_price"
                            id="project_price"
                            value={inputData.project_price}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6} sm={12}>
                        <Form.Group className="custom-fr-group">
                          {/* Add any additional form groups as needed */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} sm={12}>
                        <Form.Group className="custom-fr-group">
                          <Form.Label>Start Date</Form.Label>
                          <div className="input-icon-content custom-form-input">
                          <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('start_date').click()}></i>
                          </div>
                          <DatePicker
                              id="start_date"
                              selected={inputData.start_date ? new Date(inputData.start_date) : null}
                              onChange={(date) => handleChangeDate(date, "start_date")}
                              placeholderText="Select start date"
                              dateFormat={formatDate(inputData.start_date)}
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                            />
                          </Form.Group>
                      </Col>
                      <Col lg={6} sm={12}>
                        <Form.Group>
                          <Form.Label>End Date</Form.Label>
                          <div className="input-icon-content">
                          <i className={`bi bi-calendar input-icon`} onClick={() => document.getElementById('end_date').click()}></i>
                          </div>
                          <DatePicker
                            id="end_date"
                            selected={inputData.end_date ? new Date(inputData.end_date) : null}
                            onChange={(date) => handleChangeDate(date, "end_date")}
                            placeholderText="Select end date"
                            dateFormat={formatDate(inputData.end_date)}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}                            
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Col md={12}>
                      <Form.Group className="custom-form-input">
                        <Form.Label>Team Assign</Form.Label>
                        <Select
                          options={teamListData}
                          placeholder="Select teams..."
                          name="team_select"
                          onChange={handleSelectChange}
                          isMulti={true} // Ensure isMulti is set to true
                          value={inputData.team_select}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </Form.Group>
                    </Col>
                  </Form>
                </Col>
              </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleBothActions}>
            Ok
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
      position="top-left"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
       />
    </>
  );
}