import React from 'react'
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Card, Col, Container, Row } from 'react-bootstrap';

export default function TermsCondition() {
    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Terms and Condition' />

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Welcome to Foogle Tech Software!</Card.Title>

                                        <div className='text-muted mb-0'>
                                            <p>These terms and conditions outline the rules and regulations for the use of Foogle Tech Software's Website and Software Applications.</p>
                                            <p>By accessing this website and using our software applications, we assume you accept these terms and conditions in full. Do not continue to use Foogle Tech Software's website or applications if you do not accept all of the terms and conditions stated on this page.</p>
                                            <p className='mb-0'>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website and accepting Foogle Tech Software's terms and conditions. "Foogle Tech Software", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration or any other means, for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of . Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</p>
                                        </div>

                                        <div className='mt-3'>
                                            <h5>License</h5>
                                            <p className='text-muted mb-0'>Unless otherwise stated, Foogle Tech Software and/or its licensors own the intellectual property rights for all material on Foogle Tech Software. All intellectual property rights are reserved. You may access this from Foogle Tech Software for your own personal use subjected to restrictions set in these terms and conditions.</p>
                                        </div>

                                        <div className='mt-3'>
                                            <h5>You must not</h5>
                                            <p className='text-muted mb-0'>Republish material from Foogle Tech Software</p>
                                            <p className='text-muted mb-0'>Sell, rent or sub-license material from Foogle Tech Software</p>
                                            <p className='text-muted mb-0'>Reproduce, duplicate or copy material from Foogle Tech Software</p>
                                            <p className='text-muted mb-0'>Redistribute content from Foogle Tech Software</p>

                                        </div>

                                        <div className='mt-3'>
                                            <h5>Content Liability</h5>
                                            <p className='text-muted mb-0'>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
                                        </div>

                                        <div className='mt-3'>
                                            <h5>Reservation of Rights</h5>
                                            <p className='text-muted mb-0'>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
                                        </div>

                                        <div className='mt-3'>
                                            <h5>Removal of links from our website</h5>
                                            <p className='text-muted mb-0'>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
                                        </div>

                                        <div className='mt-3'>
                                            <h5>Disclaimer</h5>
                                            <p className='text-muted'>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
                                            <p className='text-muted mb-0'>limit or exclude our or your liability for death or personal injury;</p>
                                            <p className='text-muted mb-0'>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</p>
                                            <p className='text-muted mb-0'>limit any of our or your liabilities in any way that is not permitted under applicable law; or</p>
                                            <p className='text-muted'>exclude any of our or your liabilities that may not be excluded under applicable law.</p>
                                            <p className='text-muted'>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.</p>
                                            <p className='text-muted mb-0'>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                                        </div>

                                        <div className='mt-4'>
                                            <h5>Contact Information</h5>
                                            <p className='text-muted'>If you have any questions about these Terms and Conditions, you can contact us at:</p>
                                            <p className='text-muted mb-0'>Foogle Tech Software</p>
                                            <p className='text-muted mb-0'>710 & 402 western businees park ,</p>
                                            <p className='text-muted mb-0'>suart, India</p>
                                            <p className='text-muted mb-0'>adfoogletech@gmail.com</p>
                                        </div>
  
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    {/* <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Welcome to Foogle Tech Software!</Card.Title>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body>
                                    <div className='text-muted mb-0'>
                                            <p>These terms and conditions outline the rules and regulations for the use of Foogle Tech Software's Website and Software Applications.</p>
                                            <p>By accessing this website and using our software applications, we assume you accept these terms and conditions in full. Do not continue to use Foogle Tech Software's website or applications if you do not accept all of the terms and conditions stated on this page.</p>
                                            <p className='mb-0'>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration or any other means, for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of . Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</p>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body>
                                    <div className='mt-3'>
                                            <h5>License</h5>
                                            <p className='text-muted mb-0'>Unless otherwise stated, Foogle Tech Software and/or its licensors own the intellectual property rights for all material on Foogle Tech Software. All intellectual property rights are reserved. You may access this from Foogle Tech Software for your own personal use subjected to restrictions set in these terms and conditions.</p>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Body>
                                    <div className='mt-3'>
                                            <h5>You must not</h5>
                                            <p className='text-muted mb-0'>Republish material from Foogle Tech Software</p>
                                            <p className='text-muted mb-0'>Sell, rent or sub-license material from Foogle Tech Software</p>
                                            <p className='text-muted mb-0'>Reproduce, duplicate or copy material from Foogle Tech Software</p>
                                            <p className='text-muted mb-0'>Redistribute content from Foogle Tech Software</p>

                                        </div>
                                    </Card.Body>
                                </Card> 
                            </Col>
                        </Row>
                    </Container> */}
                </div >
                <Footer />
            </div >
        </>
    )
}
