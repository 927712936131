import React, { useState, useEffect } from "react";
import PageTitle from "../layout/PageTitle";
import Footer from "../layout/Footer";
import { Container, Form, Card, Row, Col, Table, Button, Modal } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Data-table
import JSZip from "jszip";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";

import TaskListTableData from "./task.json"

export default function Task() {

    const [projectData, setProjectData] = useState([]);
    const [taskListData] = useState(TaskListTableData);

    // const handleShow = () => setShow(true);
    const handleShowModal = () => setShowModal(true);

    const [inputData, setInputData] = useState({
        task_name: '',
        employee_name: '',
        project_select: '',
        start_date: '',
        end_date: '',
    });

    DataTable.Buttons.jszip(JSZip);
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable(".maindatatable")) {
            $(".maindatatable").DataTable({
                dom: "flrtip",
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
        }
    }, []);

    // Handle both Add and Update actions
    const handleBothActions = () => {
        if (
            inputData.task_name === '' ||
            inputData.project_select === '' ||
            inputData.start_date === '' ||
            inputData.end_date === ''
        )
            handleCloseModal();
    };

    const handleChange = (e) => {
        setInputData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    // State for showing or hiding modal
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className='page-wrapper'>
                <div className='page-content'>
                    <PageTitle breadcrumbLast='Task' />
                    <Container>
                        <Row>
                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100'>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <div className='avatar avatar-xl bg-soft-primary text-primary'>
                                                <i className='bi bi-list-task fs-32'></i>
                                            </div>
                                            <div className='ms-3'>
                                                <h3 className='text-primary fw-semibold mb-1'>100</h3>
                                                <h5 className='mb-0'>Total Task</h5>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100'>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <div className="avatar avatar-xl bg-soft-warning text-warning">
                                                <i className='bi bi-calendar fs-32'></i>
                                            </div>
                                            <div className='ms-3'>
                                                <h3 className='text-warning fw-semibold mb-1'>50</h3>
                                                <h5 className='mb-0'>Working</h5>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100'>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <div className="avatar avatar-xl bg-soft-info text-info">
                                                <i className='bi bi-check2-circle fs-32'></i>
                                            </div>
                                            <div className='ms-3'>
                                                <h3 className='text-info fw-semibold mb-1'>50</h3>
                                                <h5 className='mb-0'>Complete</h5>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col className='mb-4' md={6} xl={3}>
                                <Card className='mb-0 h-100'>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <div className="avatar avatar-xl bg-soft-success text-success">
                                                <i className='bi bi-calendar-date fs-32'></i>
                                            </div>
                                            <div className='ms-3'>
                                                <h3 className='text-success fw-semibold mb-1'>20</h3>
                                                <h5 className='mb-0'>Panding</h5>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container >

                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center mb-3">
                                            <Card.Title>Task List</Card.Title>
                                            <Button variant="soft-success ms-lg-3" className="btn-icon" onClick={handleShowModal}>
                                                <i className="bi bi-plus"></i>
                                            </Button>
                                        </div>
                                        <Table
                                            responsive
                                            bordered
                                            className='mb-0 table-nowrap w-100 maindatatable'
                                        >
                                            <thead className='table-light'>
                                                <tr>
                                                    <th>Task Name</th>
                                                    <th>Project Name</th>
                                                    <th>Employee Name</th>
                                                    <th>Worktimeline</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {taskListData.map((data, i) => (
                                                    <tr key={i}>
                                                        <td>{data.task_name}</td>
                                                        <td>{data.projectname}</td>
                                                        <td>{data.employeename}</td>
                                                        <td>{data.worktimeline}</td>
                                                        {/* <td>{data.start_date + ' To ' + data.end_date}</td> */}

                                                        <td>
                                                            <div className='d-flex'>
                                                                <Button variant='soft-primary table-btn me-2'>
                                                                    <i className='bi bi-pencil-square'></i>
                                                                </Button>
                                                                <Button variant='soft-danger table-btn'>
                                                                    <i className='bi bi-trash3'></i>
                                                                </Button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            <Modal centered show={showModal} onHide={handleCloseModal} dialogClassName="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col>
                                <Form>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Task Name</Form.Label>
                                            <Form.Control type="task"
                                                placeholder="Enter task name... "
                                                onChange={handleChange}
                                                required
                                                name="task_name"
                                                id="task_name" />
                                        </Form.Group>
                                    </Col>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className="custom-fr-group custom-form-input">
                                                <Form.Label>Project Select</Form.Label>
                                                <Form.Select name="projectname" id="projectname" onChange={handleChange}>
                                                    <option value="">Select Project</option>
                                                    {projectData.map((item) => (
                                                        <option key={item.projectname}>{item.projectname}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Employee Select </Form.Label>
                                                <Form.Select name="employee_name" id="employee_name" onChange={handleChange}>
                                                    <option value="">Select Employee Name</option>
                                                    {projectData.map((item) => (
                                                        <option key={item.employeename}>{item.employeename}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <Form.Group className="custom-fr-group">
                                                <Form.Label>Start Date</Form.Label>
                                                <Form.Control name="start_date" id="start_date" onChange={handleChange} type="date" />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <Form.Group>
                                                <Form.Label>End Date</Form.Label>
                                                <Form.Control type="date" name="end_date" id="end_date" onChange={handleChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleBothActions}>
                        Ok
                    </Button>
                    <ToastContainer />
                    <Button variant="primary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
