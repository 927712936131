import React, { useContext,useState,useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import MenuContext from '../../context/MenuContext'
import Layout from '../layout/Layout';
import Login from '../Login';
import AdminProject from '../adminProject/AdminProject';
import DepartmentList from '../Department/DepartmentList';
import Projects from '../projects/Projects';
import EmployeeList from '../Employee/EmployeeList';
import EmployeeProfile from '../Employee/EmployeeProfile';
import ProjectStatus from '../projectStatus/ProjectStatus';
import Team from '../team/Team';
import Milestone from '../milestone/Milestone';
import MilestoneStatus from '../milestoneStatus/MilestoneStatus'
import RoleList from '../role/RoleList';
import Dashboard from '../dashboard/Dashboard';
import Performance from '../performance/Performance';
import Locker from '../locker/Locker';
import Task from '../task/Task';
import Leave from '../leave/Leave';
import Setting from '../setting/Setting'
import Calendar from '../calendar/Calendar';
import LeaveApproval from '../leaveApproval/LeaveApproval';
import Notification from '../Notifications/Notifications';
import HelpCenter from '../helpcenter/HelpCenter';
import Guide from '../guide/Guide';
import TermsCondition from '../terms and condition/TermsCondition';
import { getFromAPI } from "../Utils/utils";


export default function Index() {
  const context = useContext(MenuContext);
  const User_Id = localStorage.getItem('employee_id');
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    async function fetchData() {
        try {
            // Fetch employee data
            const employeeData = await getFromAPI("employee/");
            // Check if the logged-in user is admin
            const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
            const loggedInUser = employeeData.find(
                (employee) => employee.employee_id === loggedInUserId
            );
           if (loggedInUser && loggedInUser.position.toLowerCase() === "admin") {
                setIsAdmin(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    fetchData();
}, []);

  const { iconMenu } = context;
  return (
    <>
    <div data-sidebar-size={iconMenu ? "lg" : "sm"}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login/>}></Route>
          <Route path="/dashboard" element={ <><Layout/><Dashboard /></>} />
          <Route path="/projects" element={ <><Layout /><Projects /></>} />
          <Route path="/admin_project" element={<><Layout /><AdminProject /></>} />
          <Route path="/department" element={ <><Layout /><DepartmentList /></>} />
          <Route path="/projectStatus" element={ <><Layout /><ProjectStatus /></>} />
          <Route path="/employee" element={<><Layout /><EmployeeList /></>} />
          <Route path="/employee_profile" element={ <><Layout /><EmployeeProfile /></>} />
          <Route path="/team" element={ <><Layout /><Team /></>} />
          <Route path="/milestone" element={ <><Layout /><Milestone /></>} />
          <Route path="/role" element={ <><Layout /><RoleList /></>} />   
          <Route path="/performance" element={ <><Layout /><Performance /></>} />
          <Route path="/milestoneStatus" element={ <><Layout /><MilestoneStatus /></>} />
          <Route path="/locker" element={ <><Layout /><Locker /></>} />
          <Route path="/task" element={ <><Layout /><Task /></>} />
          <Route path="/leave" element={ <><Layout /><Leave /></>} />
          <Route path="/setting" element={ <><Layout /><Setting /></> } />
          <Route path="/calendar" element={ <><Layout /><Calendar /></>} />
          <Route path="/leaveApproval" element={ <><Layout /><LeaveApproval /></>} />
          <Route path="/notification" element={ <><Layout /><Notification /></>} />
          <Route path="/guide" element={ <><Layout /><Guide /></>} />
          <Route path="/helpcenter" element={ <><Layout /><HelpCenter /></>} />
          <Route path="/termscondition" element={ <><Layout /><TermsCondition /></>} />
        </Routes>
      </BrowserRouter>
    </div>
    </>
  )
}