import React, { useState, useEffect } from "react";
import { Dropdown, Badge } from "react-bootstrap/";
import { getFromAPI } from "../Utils/utils";
import SimpleBar from "simplebar-react";

export default function Notification() {
  const [fetchedNotifications, setFetchedNotifications] = useState([]);

  // Define a function to get the title and icon based on notification type
  function getTitleAndIcon(notificationType) {
    let title = "";
    let icon = "";

    switch (notificationType) {
      case "project":
        title = "Project Alert";
        icon = "bi bi-files-alt";
        break;
      case "milestone":
        title = "Milestone Alert";
        icon = "bi bi-flag";
        break;
      case "team":
        title = "Team Alert";
        icon = "bi bi-people";
        break;
      case "role":
        title = "Role Alert";
        icon = "bi bi-person-lines-fill";
        break;
      case "department":
        title = "Department Alert";
        icon = "bi bi-diagram-3";
        break;
      case "employee":
        title = "Employee Alert";
        icon = "bi bi-person-circle";
        break;
      case "performance":
        title = "Performance Alert";
        icon = "bi bi-box-arrow-up-right";
        break;
      case "calendar":
        title = "Calendar Alert";
        icon = "bi bi-calendar4-event";
        break;
      case "leave":
        title = "Leave Alert";
        icon = "bi bi-calendar2-check";
        break;
      default:
        title = "Notification";
        icon = "bi bi-bell"; // Default icon
        break;
    }

    return { title, icon };
  }

  // Function to fetch notifications
  async function fetchNotifications() {
    try {
      const data = await getFromAPI("notification/");
      const loggedInUserId = parseInt(localStorage.getItem("employee_id"));
      const filteredData = data.filter(
        (notification) => notification.user === loggedInUserId
      );
      filteredData.sort(
        (a, b) => new Date(b.creation_time) - new Date(a.creation_time)
      );
      setFetchedNotifications(filteredData);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }

  // useEffect to fetch notifications initially
  useEffect(() => {
    fetchNotifications();
    // Poll for new notifications every 5 min 
    const interval = setInterval(fetchNotifications, 300000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);
  // Render notifications here
  return (
    <SimpleBar className="custom-select">
      {/* Render fetched notifications */}
      {fetchedNotifications.map((notification, index) => {
        const { title, icon } = getTitleAndIcon(notification.type);
        return (
          <React.Fragment key={notification.id}>
            <Dropdown.Item>
              <div className="d-flex noti-item">
                <div className="flex-shrink-0">
                  <i className={icon}></i>
                </div>
                <div className="flex-grow-1 ms-3">
                  <div className="d-flex justify-content-between c-mb-2">
                    <h6 className="noti-title mb-0">{title}</h6>
                    <p className="noti-time mb-0 fs-12">
                      {notification.creation_time
                        ? (() => {
                            const timeDifference =
                              new Date() - new Date(notification.creation_time);
                            const minutes = Math.floor(
                              timeDifference / (1000 * 60)
                            );
                            if (minutes < 60) {
                              return `${minutes} minutes ago`;
                            } else if (minutes < 60 * 24) {
                              const hours = Math.floor(minutes / 60);
                              return `${hours} ${
                                hours > 1 ? "hours" : "hour"
                              } ago`;
                            } else {
                              const days = Math.floor(minutes / (60 * 24));
                              return `${days} ${days > 1 ? "days" : "day"} ago`;
                            }
                          })()
                        : null}
                    </p>
                  </div>
                  <p className="noti-subtitle fs-13 mb-0">
                    {notification.message}
                  </p>
                </div>
              </div>
            </Dropdown.Item>
            <hr className="my-0" />
          </React.Fragment>
        );
      })}
    </SimpleBar>
  );
}
